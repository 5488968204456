import React from 'react';
import styled from 'styled-components';
import TabButton from '../TabButton'
import * as ROUTES from '../../values/Routes';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

const usersIcon = require("../../res/images/ic_users.png")
const submissionsIcon = require("../../res/images/ic_list.png")
const purchasesIcon = require("../../res/images/ic_purchases.png")


const Container = styled.div` 
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    max-width: 320px;
    margin-top: 72px;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    background-color: white;
    transform: ${(props) => (props.show ? 'translateX(0%)' : 'translateX(-100%)')};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    z-index: 1;
`;

const MyPageMenu = (props) => {
    let { pathname } = useLocation();
    const show = pathname.includes('mybrainway') || pathname.includes('admin')
    const isAdmin = true;
    if (isAdmin) {
        return (
            <Container show={show}>
                <TabButton
                    to={ROUTES.USERS}
                    isSelected={pathname === ROUTES.USERS}
                    src={require(`../../res/images/ic_users_filled.png`)}
                    title="회원 리스트"
                />
                <TabButton
                    to={ROUTES.SUBMISSION_BOARD}
                    isSelected={pathname === ROUTES.SUBMISSION_BOARD}
                    src={require(`../../res/images/ic_report_filled.png`)}
                    title="리포트 내역"
                />
               {/*  <TabButton
                    to={ROUTES.NOTICE_BOARD}
                    src={require("../../res/images/ic_users.png")}
                    title="공지사항"
                /> */}
                {/* <TabButton
                    to={ROUTES.ADMIN_TOOLS}
                    isSelected={pathname === ROUTES.ADMIN_TOOLS}
                    src={require(`../../res/images/ic_settings_${pathname === ROUTES.ADMIN_TOOLS ? 'filled' : 'normal'}.png`)}
                    title="관리자 기능"
                /> */}
                
            </Container>
        )
    } else {
        return (
            <Container show={show}>
                <TabButton
                    to={ROUTES.USERS}
                    isSelected={pathname === ROUTES.USERS}
                    src={require(`../../res/images/ic_user_filled.png`)}
                    title="회원 정보"
                />
                <TabButton
                    to={ROUTES.SUBMISSION_BOARD}
                    isSelected={pathname === ROUTES.SUBMISSION_BOARD}
                    src={require(`../../res/images/ic_report_filled.png`)}
                    title="수행 내역"
                />
                {/* <TabButton
                    to={ROUTES.SUBMISSION_BOARD}
                    isSelected={pathname === ROUTES.SUBMISSION_BOARD}
                    src={require(`../../res/images/ic_report_${pathname === ROUTES.USERS ? 'filled' : 'normal'}.png`)}
                    title="결제내역"
                /> */}
            </Container>
        )
    }
}

const maptStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    }
}

export default connect(maptStateToProps, null)(MyPageMenu)
