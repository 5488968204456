import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid white;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
`;

const Logo = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const SnsButton = (props) => {
  return (
    <Container {...props}>
      <Logo {...props} />
    </Container>
  );
};

export default SnsButton;
