import React, { Component } from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import CheckBox from './CheckBox';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px 10px 10px;
  border-radius: 10px;

  &:hover{
    background-color: ${props => props.isSelected ? "transparent" : "rgba(0,0,0,0.05)"};
  }
`;

const Text = styled.span`
  display: block;
  font-size: 18px;
  text-align: center;
  color: black;
  margin: 0px 0px 0px 10px;
  cursor: pointer;
  font-family: 'Nanum Gothic', sans-serif;
`;

class ChoiceInput extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.isSelected !== this.props.isSelected;
    }

    handleChange = (e) => {
        const { onSelected, text } = this.props;
        onSelected({
            checked: true,
            text: `${text}: ${e.target.value}`
        })
    }

    render() {
        const { isSelected, text, radius, value, onSelected } = this.props;
        return (
            <Container isSelected={isSelected}>
                <CheckBox
                    radius={radius}
                    isSelected={isSelected}
                    onClick={() => onSelected({
                        checked: !isSelected,
                        text: text
                    })}
                />
                {isSelected ?
                    <TextInput
                        autoFocus
                        id='etc'
                        type='text'
                        defaultValue={value}
                        required={true}
                        onChange={this.handleChange}
                    />
                    :
                    <Text
                        onClick={() => onSelected({
                            checked: !isSelected,
                            text: text
                        })}
                    >{text}</Text>
                }
            </Container>
        )
    }
}

export default ChoiceInput