const FAQ = {
  section1: {
    title: "공지사항",
    subtitle: "",
  },
  section2: {
    faqs: [
      {
        index: 1,
        question: 'Brainway 평가 결과는 어떻게 볼 수 있나요?',
        answer: '마이페이지 → Brainway 평가 결과에서 진행 상황을 확인하고 다운 받으실 수 있습니다.'
      },
      {
        index: 2,
        question: '검사예약과 센터방문은 어떻게 하나요?',
        answer:
          '센터 오픈은 아직 준비 중에 있습니다. 추후 오픈 일정에 따라 시기와 장소가 공지될 예정이며, 상담 및 방문은 (BARINWAY 메일) 로 연락을 주시면 됩니다. 센터 방문 시 가능한 Brainway 종합검사 set  유형에 대한 정보는 성장의길 브레인 뉴로 사이언스 랩에 업데이트될 예정으로 이후  확인 가능합니다.'
      },
      {
        index: 3,
        question: '결제는 어떻게 해야 하나요?',
        answer: ''
      },
      {
        index: 4,
        question: '성장의 숲에서 다양한 종류별 길들의 체험하기와 걸어보기의 차이는 무엇인가요?',
        answer:
          '“성장의 숲”에서는, 좀더 세부적인 영역에 대한 심도있는 평가를 진행하며, 그 결과에 기반한 브레인웨이 임상전문가의 전문적이고 임상적인 해석을 포함한 결과지가 제공됩니다.'
      },
      {
        index: 5,
        question: '성장의 숲은 어떻게 진행 할 수 있나요?',
        answer:
          '회원가입을 하시고, 성장의 숲 검사 링크를 선택하시면 결제 단계를 진행 하실 수 있습니다.\n결제단계 이후 제시되는 안내에 따라 평가를 끝까지 진행하신 후 저장 & 제출 하시면 됩니다.'
      }
    ]
  }
};

export default FAQ;
