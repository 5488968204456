export const saveTrial = (input) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('trial_submissions')
      .add({
        ...input,
        submittedAt: new Date()
      })
      .then(() => {
        dispatch({ type: 'SAVE_TRIAL_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'SAVE_TRIAL_ERROR', err });
      });
  };
};

export const initialize = (doc) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const authId = getState().firebase.auth.uid;
    firestore.collection('submissions').add({
      ...doc,
      createdAt: new Date(),
      status: "신규",
      userId: authId,
    }).then(res => {
      dispatch({type: 'INIT_SUCCESS'})
    }).catch(err => {
      dispatch({type: 'INIT_FAILED', err})
    })
  } 
}

export const save = (id, doc) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection('submissions').doc(id).set({
      ...doc,
      modifiedAt: new Date(),
      status: "진행중",
    }).then(res => {
      dispatch({type: 'SAVE_SUCCESS'})
    }).catch(err => {
      dispatch({type: 'SAVE_FAILED', err})
    })
  }
}

export const submit = (doc) => {
  return (dispatch, getState, { getFirestore}) => {
    const firestore = getFirestore();
    const authId = getState().firebase.auth.uid;
    firestore
    .collection('submissions')
    .add({
      ...doc,
      modifiedAt: new Date(),
      status: "제출됨",
      userId: authId,
    }).then(res => {
      dispatch({type: 'SUBMISSION_SUCCESS'})
    }).catch(err => {
      dispatch({type: 'SUBMISSION_FAILED', err})
    })


  }
}
