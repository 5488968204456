import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5px;
  border-radius: 30px;
  border: 3px solid white;
  background: transparent;
  padding: 46px 30px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 28px;
  text-align: left;
  font-family: 'GodoB';
  cursor: pointer;
`;

const Description = styled.p`
  color: white;
  font-size: 17px;
  text-align: left;
  font-family: 'Nanum Gothic', sans-serif;
  cursor: pointer;
`;

const NavButton = ({ title, description, onClick, destination }) => (
  <Container to={destination} onClick={onClick}>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

export default NavButton;
