import React from "react";
import styled from "styled-components";
import MultipleChoices from "../options/MultipleChoices";
import MultipleSelections from "../options/MultipleSelections";
import FillBlankInput from "../options/FillBlankInput";
import TextInput from '../../TextInput';
import FillBlankWithReason from "../options/FillBlankWithReason";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  &:hover{
  /* box-shadow: 1px 0px 8px rgba(0,0,0,0.18); */
  }
`;

const Title = styled.span`
  color: black;
  margin: 10px;
  text-align: left;
  color: black;
  font-family: "GodoB";
  font-size: 20px;
  line-height: 30px;
`;

const Instruction = styled.span`
    color: black;
    margin: 0px 10px 10px 10px;
    font-size: 14px;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    color: red;
`;

const renderOptions = (props) => {
    const { question, input, num } = props;
    const { options } = question;

    const views = [];
    switch (options.type) {
        case "multiple_selection":
            return <MultipleSelections
                options={options}
                value={input && input.answer}
                onInput={props.onInput}
            />
        case "multiple_choice":
            return <MultipleChoices
                options={options}
                value={input && input.answer}
                onInput={props.onInput} />
        case "short_answer":
            return <TextInput
                id={props.question.questionId}
                type="text"
                defaultValue={input && input.answer}
                onChange={(e) => {
                    props.onInput({
                        type: options.type,
                        answer: e.target.value
                    })
                }}
                required={true}
            />
        case "fill_in_blank":
            return <FillBlankInput
                num={num}
                prefix={options.prefix}
                suffix={options.suffix}
                defaultValue={input && input.answer}
                onChange={(value) => props.onInput({
                    type: options.type,
                    prefix: value.prefix,
                    answer: value.answer,
                    suffix: value.suffix
                })}
            />
        case "fill_in_blank_with_reason":
            return <FillBlankWithReason
                num={num}
                prefix={options.prefix}
                suffix={options.suffix}
                answer={input && input.answer}
                reason={input && input.reason}
                onChange={(value) => props.onInput({
                    type: options.type,
                    prefix: value.prefix,
                    answer: value.answer,
                    suffix: value.suffix,
                    reason: value.reason,
                })}
            />
        default:
            break;
    }
    return views;
};

const SurveyQuestion = (props) => {
    const { num, question } = props;
    return (
        <Container>
            {question.question && <Title>{`${num}. ${question.question}`}</Title>}
            {question.instruction && <Instruction>{question.instruction}</Instruction>}
            {renderOptions(props)}
        </Container>
    );
};

export default SurveyQuestion;
