import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from '../../values/Colors'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 415px){
    background-color: ${COLORS.primary}
  }
`;

const FixedImage = styled.img`
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
`;

const Overlay = styled.div`
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 144px;
  background: white;
  margin-top: 200px;
  padding: 100px 0px;
  border-radius: 16px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
    width: 90%;
    margin-top: 140px;
    border-radius: 10px;
    justify-content: center;
    
  }

  @media (max-width: 415px){
    width: 100%;
    margin-top: 72px;
    margin-bottom: 0px;
    border-radius: 0px;

  }
`;

const PartnerContainer = styled.div`
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
`;

const Title = styled.text`
  font-size: 40px;
  text-align: center;
  color: black;
  padding: 10px 10px 10px 10px;
  font-family: 'GodoB';

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
      font-size: 30px;
      line-height: 40px;
  }
  
  @media (max-width: 415px){
    width: 100%;
    max-width: 80%;
  }

`;

const SubTitle = styled.text`
  max-width: 600px;
  font-size: 18px;
  text-align: center;
  color: black;
  line-height: 26px;
  text-align: left;
  padding: 10px;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
      font-size: 15px;
      line-height: 28px;
      width: 80%;
  }

  @media (max-width: 415px){

  }

`;

const Logo = styled.img`
  height: auto;
  display: flex;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
      height: 140px;
  }

  @media (max-width: 415px){

  }
`;

const SectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0px; 

    @media (max-width: 768px){
      padding: 30px 0px; 

    }   
`;

const SectionRowContainer = styled(SectionContainer)`
  padding: 60px 20px; 
  flex-direction: row;

  @media (max-width: 768px){
      padding: 30px 20px; 
      flex-direction: column;
    }   
`;

const SectionRowNormalContainer = styled(SectionRowContainer)`
  padding: 60px 20px; 
  flex-direction: row;

  @media (max-width: 768px){
      display: none;
    }  
`;

const SectionRowMobileContainer = styled(SectionRowContainer)`
  display: none;
  
  @media (max-width: 768px){
      display: flex;
      flex-direction: column;
      padding: 30px 20px; 

    } 
`;

const Image = styled.img`
  height: auto;
  display: flex;
  align-self: center;
  margin: 30px 0px;

  @media (max-width: 415px){
    width: 90%;
    margin: 20px 0px;
  }
`;

const Picture = styled(Image)`
  flex: 1;
  padding: 20px;
`;

const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 20px;

  @media (max-width: 415px){
    padding: 0px 10px;

  }
`;

const BoxTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${props => props.color ? props.color : COLORS.primary};
  border-radius: 3px;
  padding: 14px 20px;
  position: absolute;
  top: 0px;
  left: 40px;

  @media (max-width: 415px){
    left: 10px;
    right: 10px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;
`;

const BoxBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  align-items: center;
  border-radius: 3px;
  border: ${props => `2px solid ${props.color ? props.color : COLORS.primary}`};
  margin-top: 40px;
  padding: 60px 20px 20px 20px;
`;

const BoxTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
`;

const BoxTitle = styled(Title)`
    font-size: 26px;
    line-height: 40px;
    padding: 0px 0px 0px 0px;

    @media (max-width: 768px){
      font-size: 22px;
      line-height: 30px;
    }

    @media (max-width: 415px){
      width: auto;
      max-width: none;
    }
`;

const BoxSubTitle = styled(SubTitle)`
    font-size: 14px;
    width: 100%;
    max-width: none;
    padding: 0px;
    
    @media (max-width: 768px){
      width: 100%;
      max-width: none;
      font-size: 12px;
      line-height: 20px;
    }
`;

const BoxText = styled(SubTitle)`
    width: 100%;
    max-width: none;
    padding: 0px;

    @media (max-width: 768px){
      width: 100%;
      max-width: none;
    }
`;

const Em = styled.em`
  font-size: 20px; 
  font-family: 'GodoB';
`;

class NeuroscienceIntro extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (

      <Container>
        <FixedImage src={require('../../res/images/bg_neuroscience.png')} />
        <Overlay />
        <MainContainer>
          <PartnerContainer style={{ marginBottom: '40px' }}>
            <Logo
              style={{ marginBottom: '50px' }}
              src={require('../../res/images/img_neuroscience.png')}
              srcSet={`${require('../../res/images/img_neuroscience@2x.png')} 2x, ${require('../../res/images/img_neuroscience@3x.png')} 3x`}
            />
            <Title>Brain Neuro Science Lab</Title>
            <SubTitle style={{ maxWidth: '620px' }}>
              "브레인 뉴로 사이언스 랩"에 방문 하시면
              좀더 심층적이고 다양한 임상 신경심리 검사와 뇌측정 평가를 경험하고,
              결과에 대한 브레인 코칭과 학업 및 진로 적성에 대한 컨설팅을 제공받으실 수 있습니다.
            </SubTitle>
          </PartnerContainer>

          <SectionContainer style={{ background: 'rgba(17, 87, 172, 0.05)' }}>
            <Title>Brainway Set</Title>
            <Image src={require('../../res/images/img_brainway_set.png')} />
            <SubTitle>
              Brainway Set는 브레인웨이의 임상신경심리 검사 세트입니다.
              개인의 특성과 관심을 반영하는 최적의 다양한 차원의 평가들도 구성되어 있습니다.
            </SubTitle>
          </SectionContainer>

          <SectionContainer>
            <BoxContainer>
              <BoxTitleContainer color={"#E9BA19"}>
                <BoxTitle style={{ color: 'white' }}>뇌 발달 Set</BoxTitle>
                <BoxSubTitle style={{ color: 'white' }}>
                  (대상:아동/청소년/성인, 검사비용: 약00만원, 실시시간 약 00분)
                  </BoxSubTitle>
              </BoxTitleContainer>
              <BoxBodyContainer color={"#E9BA19"}>
                <BoxTextContainer>
                  <BoxText>
                    <Em style={{color: '#E9BA19'}}>QEEG</Em>, <Em style={{color: '#E9BA19'}}>fNIRS</Em>, <Em style={{color: '#E9BA19'}}>Eye-tracker</Em> 등의 뇌측정 기기를 통해 뇌활성화 상태를 측정할 수 있습니다.
                    각 과제 영역에 따른 뇌활성화 정도와 실제 능력과의 관계 등을 파악하고,
                    현재 뇌 발달 상태에 대한 결과를 얻을 수 있습니다. 이와 같은 과학적, 객관적 평가 결과를 기반으로 하여,
                    자녀의 학습지도, 진로와 적성 등에 방향과 목표를 설정할 수 있습니다.
                  </BoxText>
                </BoxTextContainer>
                <Image src={require('../../res/images/img_neuroscience_set_1.png')} />
              </BoxBodyContainer>
            </BoxContainer>
          </SectionContainer>


          <SectionContainer style={{ background: 'rgba(17, 87, 172, 0.05)' }}>
            <BoxContainer>
              <BoxTitleContainer color={"#62C861"}>
                <BoxTitle style={{ color: 'white' }}>진로적성 Set</BoxTitle>
                <BoxSubTitle style={{ color: 'white' }}>
                  (대상:아동/청소년/성인, 검사비용: 약00만원, 실시시간 약 00분)
                </BoxSubTitle>
              </BoxTitleContainer>
              <BoxBodyContainer color={"#62C861"}>
                <BoxTextContainer>
                  <BoxText>
                    아동청소년 및 초기 성인의 <Em style={{color: '#56D754'}}>학습</Em>, <Em style={{color: '#56D754'}}>적응</Em>, <Em style={{color: '#56D754'}}>진로</Em> 등의 특성을 파악할 수 있습니다.
                    자신의 적성과 진로를 파악하여 앞으로 나아갈 방향성에 대해 깊이 있게 탐구하고 자신을 알아가는 전문적,
                    과학적 결과를 제공합니다.
                  </BoxText>
                </BoxTextContainer>
                <Image src={require('../../res/images/img_neuroscience_set_2.png')} />
              </BoxBodyContainer>
            </BoxContainer>
          </SectionContainer>

          <SectionContainer>
            <BoxContainer>
              <BoxTitleContainer color={"#6DC4DE"}>
                <BoxTitle style={{ color: 'white' }}>신경심리 Set</BoxTitle>
                <BoxSubTitle style={{ color: 'white' }}>
                  (대상:아동/청소년/성인, 검사비용: 약00만원, 실시시간 약 00분)
                </BoxSubTitle>
              </BoxTitleContainer>
              <BoxBodyContainer color={"#6DC4DE"}>
                <BoxTextContainer>
                  <BoxText>
                    신경심리 및 인지적 능력을 평가합니다.
                    <Em style={{color: '#6DC4DE'}}>인지능력</Em>, <Em style={{color: '#6DC4DE'}}>주의력</Em>, <Em style={{color: '#6DC4DE'}}>기억력</Em>, <Em style={{color: '#6DC4DE'}}>실행기능</Em> 등의 인지신경심리 영역 결과를 제공하며,
                    개개인의 인지능력에 대한 프로파일링을 통해 인지적 강점 및 약점을 파악하고,
                    이를 기반으로 학습계획, 진로 및 적성에 대한 브레인코칭이 가능합니다.
                  </BoxText>
                </BoxTextContainer>
                <Image src={require('../../res/images/img_neuroscience_set_3.png')} />
              </BoxBodyContainer>
            </BoxContainer>
          </SectionContainer>

          <SectionContainer style={{ background: 'rgba(17, 87, 172, 0.05)' }}>
            <BoxContainer>
              <BoxTitleContainer color={"#956ACB"}>
                <BoxTitle style={{ color: 'white' }}>정서종합 Set</BoxTitle>
                <BoxSubTitle style={{ color: 'white' }}>
                  (대상:아동/청소년/성인, 검사비용: 약00만원, 실시시간 약 00분)
                  </BoxSubTitle>
              </BoxTitleContainer>
              <BoxBodyContainer color={"#956ACB"}>
                <BoxTextContainer>
                  <BoxText>
                  <Em style={{color: '#956ACB'}}>정서상태</Em>, <Em style={{color: '#956ACB'}}>기질</Em> 및 <Em style={{color: '#956ACB'}}>성격</Em> 유형에 대한 평가를 통해, 스스로에 대한 이해를 높이고,
                    자신이 경험하는 어려움에 대한 솔루션을 탐색해 볼 수 있습니다.
                  </BoxText>
                </BoxTextContainer>
                <Image src={require('../../res/images/img_neuroscience_set_4.png')} />
              </BoxBodyContainer>
            </BoxContainer>
          </SectionContainer>

          <SectionContainer>
            <BoxContainer>
              <BoxTitleContainer color={"#D599AD"}>
                <BoxTitle style={{ color: 'white' }}>부모평가 Set</BoxTitle>
                <BoxSubTitle style={{ color: 'white' }}>(대상:자녀를 둔 부모, 검사비용: 약00만원, 실시시간 약 00분)</BoxSubTitle>
              </BoxTitleContainer>
              <BoxBodyContainer color={"#D599AD"}>
                <BoxTextContainer>
                  <BoxText>
                    부모로서의 <Em style={{color: '#D599AD'}}>스트레스</Em>, 개인의 <Em style={{color: '#D599AD'}}>정서적인 어려움</Em>에 대해 평가하고 돌아보며,
                    <Em style={{color: '#D599AD'}}>성격 특성</Em>에 대한 이해를 통해 좀더 행복하고 적응적인 삶,
                    효율적인 양육에 대한 솔루션을 찾아봅니다.
                  </BoxText>
                </BoxTextContainer>
                <Image src={require('../../res/images/img_neuroscience_set_5.png')} />
              </BoxBodyContainer>
            </BoxContainer>
          </SectionContainer>

          <SectionContainer style={{ background: 'rgba(17, 87, 172, 0.05)' }}>
            <SubTitle>모든 보고서의 예상 수령 기간은 검사 종료 후 영업일로부터 <Em style={{fontSize: '18px', color: 'red'}}>약 7일</Em>정도 소요됩니다.</SubTitle>
          </SectionContainer>

          <SectionRowContainer>
            <Image src={require('../../res/images/img_gears.png')} />
          </SectionRowContainer>

          <SectionRowContainer style={{ background: 'rgba(17, 87, 172, 0.05)' }}>
            <Picture src={require('../../res/images/img_qeeg.png')} />
            <TextContainer>
              <BoxTitle>QEEG (정량화 뇌파)</BoxTitle>
              <BoxSubTitle>소요시간:약 00분</BoxSubTitle>
              <BoxText style={{ marginTop: '16px' }}>
                QEEG는 특정 인지 과제를 학습 또는 수행하는 동안 전극을 통하여 뇌파를 수집하여 분석하는 기법입니다.
                임상분야에서는 뇌혈관 질환이나 뇌전증 진단에서 사용되고 있습니다.
              </BoxText>
            </TextContainer>
          </SectionRowContainer>

          <SectionRowNormalContainer>
            <TextContainer>
              <BoxTitle>fNIRS(기능적 근적외선 분광법)</BoxTitle>
              <BoxSubTitle>소요시간:약 00분</BoxSubTitle>
              <BoxText style={{ marginTop: '16px' }}>
                fNIRS는 특정 인지 과제를 학습 또는 수행하는 동안 근적외선을 이용하여
                뇌의 활성화를 상태를 탐색하는 방식입니다.
              </BoxText>
            </TextContainer>
            <Picture src={require('../../res/images/img_fmri.png')} />
          </SectionRowNormalContainer>

          <SectionRowMobileContainer>
            <Picture src={require('../../res/images/img_fmri.png')} />
            <TextContainer>
              <BoxTitle>fNIRS (기능적 근적외선 분광법)</BoxTitle>
              <BoxSubTitle>소요시간:약 00분</BoxSubTitle>
              <BoxText style={{ marginTop: '16px' }}>
                fNIRS는 특정 인지 과제를 학습 또는 수행하는 동안 근적외선을 이용하여
                뇌의 활성화를 상태를 탐색하는 방식입니다.
              </BoxText>
            </TextContainer>
          </SectionRowMobileContainer>

          <SectionRowContainer style={{ background: 'rgba(17, 87, 172, 0.05)' }}>
            <Picture src={require('../../res/images/img_eye_tracker.png')} />
            <TextContainer>
              <BoxTitle>Eye-Tracker (시선 추적)</BoxTitle>
              <BoxSubTitle>소요시간:약 00분</BoxSubTitle>
              <BoxText style={{ marginTop: '16px' }}>
                Eyetracker는 사람의 시선을 추적합니다.
                지금 어디에 집중을 하고 있는지 시선을 통하여 파악하고 그 패턴을 해석합니다
               </BoxText>
            </TextContainer>
          </SectionRowContainer>

          <SectionContainer />

          <PartnerContainer>
            <Title>브레인웨이 코칭과 학습진로적성 컨설팅</Title>
            <SubTitle style={{ maxWidth: '620px' }}>
              개개인에 대한 전문적인 파악을 통한 차별화된 전문적 해석상담 및 브레인코칭, 그리고 학업과 진로적성에 대한 컨설팅을 제공합니다
            </SubTitle>
          </PartnerContainer>
        </MainContainer>
      </Container>
    );
  }
}

export default NeuroscienceIntro;
