import React, { Component } from "react";
import styled from "styled-components";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import SurveyTask from "./SurveyTask";
import SurveySection from "./SurveySection";
import SurveySubSection from "./SurveySubSection";
import SurveyQuestion from "./SurveyQuestion";
import SimpleViewSlider from 'react-view-slider/simple'
import SurveyIntro from "./SurveyIntro";
import TextInput from '../../TextInput';
import ChoiceButton from '../../ChoiceButton';
import SurveyEnd from "./SurveyEnd";
import { initialize, save, submit } from '../../../redux/actions/SurveyActions'

const regexQuestion = /[A-Z]_[0-9]_[0-9]{3}/

const Container = styled.div`
  min-height: 1011px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8%;
  background: rgba(0,0,0,0.2);
`;

const GenderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px;
`;

class Survey extends Component {
    state = {
        curIndex: 0,
        userInfo: {},
        userInputs: {},
    };

    handleStats = (userInputs) => {
        let stats = {}
        Object.keys(userInputs).filter(task => task !== 'title').map((task) => {
            Object.keys(userInputs[task]).filter(section => section !== 'title').map((section) => {
                Object.keys(userInputs[task][section]).filter(key => key !== 'title').map((key) => {
                    if (!regexQuestion.test(key)) {
                        const subSection = key;
                        Object.keys(userInputs[task][section][subSection]).filter(key => key !== 'title').map((key) => {
                            const input = userInputs[task][section][subSection][key];
                            if (input.point) {
                                stats = {
                                    ...stats,
                                    [this.props.survey.surveyId]: !stats[this.props.survey.surveyId] ?
                                        {
                                            [task]: {
                                                point: input.point,
                                                total: input.total
                                            }
                                        }
                                        : {
                                            ...stats[this.props.survey.surveyId],
                                            [task]: !stats[this.props.survey.surveyId][task]
                                                ? {
                                                    point: input.point,
                                                    total: input.total
                                                } : {
                                                    ...stats[this.props.survey.surveyId][task],
                                                    point:
                                                        stats[this.props.survey.surveyId][task]
                                                            .point + input.point,
                                                    total:
                                                        stats[this.props.survey.surveyId][task]
                                                            .total + input.total
                                                }
                                        },
                                    [task]: !stats[task]
                                        ? {
                                            [subSection]: {
                                                point: input.point,
                                                total: input.total,
                                            }
                                        }
                                        : {
                                            ...stats[task],
                                            [subSection]: !stats[task][subSection]
                                                ? {
                                                    point: input.point,
                                                    total: input.total,
                                                }
                                                : {
                                                    ...stats[task][subSection],
                                                    point:
                                                        stats[task][subSection].point +
                                                        input.point,
                                                    total:
                                                        stats[task][subSection].total +
                                                        input.total
                                                }
                                        }
                                }
                            }
                        }
                        );
                    } else {
                        const input = userInputs[task][section][key];
                        if (input.point) {
                            stats = {
                                ...stats,
                                [this.props.survey.surveyId]: !stats[this.props.survey.surveyId] ?
                                    {
                                        [task]: {
                                            point: input.point,
                                            total: input.total
                                        }
                                    }
                                    : {
                                        ...stats[this.props.survey.surveyId],
                                        [task]: !stats[this.props.survey.surveyId][task]
                                            ? {
                                                point: input.point,
                                                total: input.total
                                            } : {
                                                ...stats[this.props.survey.surveyId][task],
                                                point:
                                                    stats[this.props.survey.surveyId][task]
                                                        .point + input.point,
                                                total:
                                                    stats[this.props.survey.surveyId][task]
                                                        .total + input.total
                                            }
                                    },
                                [task]: !stats[task]
                                    ? {
                                        [section]: {
                                            point: input.point,
                                            total: input.total,
                                        }
                                    }
                                    : {
                                        ...stats[task],
                                        [section]: !stats[task][section]
                                            ? {
                                                point: input.point,
                                                total: input.total,
                                            }
                                            : {
                                                ...stats[task][section],
                                                point:
                                                    stats[task][section].point +
                                                    input.point,
                                                total:
                                                    stats[task][section].total +
                                                    input.total
                                            }
                                    }
                            }
                        }
                    }
                }
                );
            });
        });
        return stats;
    };

    handleInputs = (userInputs) => {
        const { task, section, subSection, question, input } = userInputs;
        if (subSection) {
            this.setState({
                userInputs: {
                    ...this.state.userInputs,
                    [task.taskId]: {
                        ...this.state.userInputs[task.taskId],
                        title: task.title,
                        [section.sectionId]: !this.state.userInputs[task.taskId]
                            ? {
                                title: section.title,
                                [subSection.subSectionId]: !this.state.userInputs[task.taskId]
                                    ? {
                                        title: subSection.title,
                                        [question.questionId]: {
                                            ...input,
                                            question: question.question
                                        }
                                    }
                                    : {
                                        ...this.state.userInputs[task.taskId][
                                        section.sectionId
                                        ][subSection.subSectionId],
                                        title: subSection.title,
                                        [question.questionId]: {
                                            ...input,
                                            question: question.question
                                        }
                                    }
                            }
                            : {
                                ...this.state.userInputs[task.taskId][section.sectionId],
                                title: section.title,
                                [subSection.subSectionId]: !this.state.userInputs[
                                    task.taskId
                                ][section.sectionId]
                                    ? {
                                        title: subSection.title,
                                        [question.questionId]: {
                                            ...input,
                                            question: question.question
                                        }
                                    }
                                    : {
                                        ...this.state.userInputs[task.taskId][
                                        section.sectionId
                                        ][subSection.subSectionId],
                                        title: subSection.title,
                                        [question.questionId]: {
                                            ...input,
                                            question: question.question
                                        }
                                    }
                            }
                    }
                }
            });
        } else {
            this.setState({
                userInputs: {
                    ...this.state.userInputs,
                    [task.taskId]: {
                        ...this.state.userInputs[task.taskId],
                        title: task.title,
                        [section.sectionId]: !this.state.userInputs[task.taskId]
                            ? {
                                title: section.title,
                                [question.questionId]: {
                                    ...input,
                                    question: question.question
                                }
                            }
                            : {
                                ...this.state.userInputs[task.taskId][section.sectionId],
                                title: section.title,
                                [question.questionId]: {
                                    ...input,
                                    question: question.question
                                }
                            }
                    }
                }
            });
        }
    };

    handleUserInfo = (e) => {
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [e.target.id]: e.target.value
            }
        })
    }

    handleSubmit = () => {
        const stats = this.handleStats(this.state.userInputs);

        const doc = {
            userInfo: this.state.userInfo,
            userInputs: this.state.userInputs,
            surveyId: this.props.survey.surveyId,
            survey: this.props.survey.title,
            stats: stats,
        };
        
        this.props.submit(doc)
    };

    handleIndex = (nextValue) => {
        window.scrollTo({
            top: 0,
            left: 0,
        })
        this.setState({
            curIndex: this.state.curIndex + nextValue
        })
    }

    renderViews = (survey, userInputs) => {
        const views = []

        switch (survey.surveyId) {
            case 'A':
            case 'B':
                views.push(
                    <SurveyIntro
                        key={0}
                        title={survey.title}
                        instruction={survey.instruction}
                        onButtonPressed={value => this.handleIndex(value)}
                    >
                        <TextInput id="name" label="이름" type="text" required onChange={this.handleUserInfo} />
                        <TextInput id="age" label="나이(만)" type="number" required onChange={this.handleUserInfo} />
                        <GenderContainer>
                            <ChoiceButton
                                text="여자"
                                onSelected={({ value, text }) => this.handleUserInfo({ target: { id: 'gender', value: text } })}
                                isSelected={this.state.userInfo.gender === "여자"}
                            />
                            <div style={{ width: '10px' }} />
                            <ChoiceButton
                                text="남자"
                                onSelected={({ value, text }) => this.handleUserInfo({ target: { id: 'gender', value: text } })}
                                isSelected={this.state.userInfo.gender === "남자"}
                            />
                        </GenderContainer>
                        <TextInput id="occupation" label="직업" type="text" required onChange={this.handleUserInfo} />

                    </SurveyIntro>
                )
                break;
            case 'C':
            case 'D':
                views.push(
                    <SurveyIntro
                        key={0}
                        title={survey.title}
                        instruction={survey.instruction}
                        onButtonPressed={value => this.handleIndex(value)}
                    >
                        <TextInput id="childName" label="자녀 이름" type="text" required onChange={this.handleUserInfo} />
                        <TextInput id="age" label="나이(만)" type="number" required onChange={this.handleUserInfo} />
                        <GenderContainer>
                            <ChoiceButton
                                text="여자"
                                onSelected={({ value, text }) => this.handleUserInfo({ target: { id: 'gender', value: text } })}
                                isSelected={this.state.userInfo.gender === "여자"}
                            />
                            <div style={{ width: '10px' }} />
                            <ChoiceButton
                                text="남자"
                                onSelected={({ value, text }) => this.handleUserInfo({ target: { id: 'gender', value: text } })}
                                isSelected={this.state.userInfo.gender === "남자"}
                            />
                        </GenderContainer>
                        <div style={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center' }}>
                            <TextInput id="school" label="학교" type="text" required onChange={this.handleUserInfo} />
                            <TextInput id="grade" label="학년" type="text" required onChange={this.handleUserInfo} />
                        </div>
                    </SurveyIntro>
                )
                break;
            default:
                break;
        }


        survey.tasks.map((task, index) => {
            views.push(
                <SurveyTask
                    key={index + 1}
                    task={task}
                    onButtonPressed={value => this.handleIndex(value)}
                >
                    {task.sections.map((section, index) => {
                        return (
                            <SurveySection key={index} section={section}>
                                {section.subSections
                                    ? section.subSections.map((subSection, index) => {
                                        return (
                                            <SurveySubSection
                                                key={index}
                                                subSection={subSection}
                                            >
                                                {subSection.questions.map((question, index) => {
                                                    return (
                                                        <SurveyQuestion
                                                            key={index}
                                                            num={index + 1}
                                                            question={question}
                                                            input={
                                                                userInputs[task.taskId] &&
                                                                userInputs[task.taskId][section.sectionId] &&
                                                                userInputs[task.taskId][section.sectionId][subSection.subSectionId] &&
                                                                userInputs[task.taskId][section.sectionId][subSection.subSectionId][question.questionId]}
                                                            onInput={(input) => {
                                                                this.handleInputs({
                                                                    task,
                                                                    section,
                                                                    subSection,
                                                                    question,
                                                                    input
                                                                });
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </SurveySubSection>
                                        );
                                    })
                                    : section.questions.map((question, index) => {
                                        return (
                                            <SurveyQuestion
                                                key={index}
                                                num={index + 1}
                                                question={question}
                                                input={userInputs[task.taskId] && userInputs[task.taskId][section.sectionId] && userInputs[task.taskId][section.sectionId][question.questionId]}
                                                onInput={(input) => {
                                                    this.handleInputs({
                                                        task,
                                                        section,
                                                        question,
                                                        input
                                                    });
                                                }}
                                            />
                                        );
                                    })}
                            </SurveySection>
                        );
                    })}
                </SurveyTask>
            );
        })

        views.push(
            <SurveyEnd
                key={views.length}
                onButtonPressed={value => this.handleIndex(value)}
                onCompletePressed={this.handleSubmit}
            />
        )
        return views;
    }

    render() {
        const { survey } = this.props
        const { curIndex, userInputs } = this.state;

        if (!survey) {
            return <Container />
        }

        return (
            <Container>
                <SimpleViewSlider
                    spacing={1.2}
                    animateHeight={false}
                    style={{ padding: "10px" }}
                >
                    {this.renderViews(survey, userInputs)[curIndex]}

                </SimpleViewSlider>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const packages = state.firestore.data.packages;
    return {
        survey: packages && packages[ownProps.match.params.id],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (doc) => dispatch(submit(doc))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => {
        return [
        { collection: "packages" },
    ]})
)(Survey)