import React from 'react';
import styled from 'styled-components';

import COLORS from '../../values/Colors';

const Container = styled.div`
  width: 100%;
  height: 720px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 180px 40px 100px 40px;

  @media (max-width: 768px) {
    padding: 160px 40px 80px 40px;
  }
`;

const WhiteSpace = styled.div`
  width: 90%;
  max-width: 1000px;
  height: 300px;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
`;

const TitleContainerWithWhite = (props) => (
  <Container {...props}>
    <Overlay>
      <BodyContainer>{props.children}</BodyContainer>
      <WhiteSpace />
    </Overlay>
  </Container>
);

export default TitleContainerWithWhite;
