const PRIVACY_POLICY = {
  title: '개인정보처리방침',
  description: [
    `브레인웨이 (이하 “회사”라 함)는 이용자의 개인정보를 중요시 하며, ‘정보통신망 이용 촉진 및 정보보호 등에 관한 법률’, ‘개인정보보호법’을 준수하기 위하여 노력하고 있습니다. 회사는 개인정보처리방침을 통하여 회사가 이용자로부터 제공받은 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다. 본 방침은 2018년 01월 01일부터 시행되며, 이를 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지하겠습니다.`,
    `※ 비회원으로 서비스를 이용하는 경우 회원에게만 적용되는 사항을 제외한 나머지 부분에 대하여는 회원과 동일하게 회사 개인정보처리방침이 적용됩니다`
  ],
  contents: [
    {
      listTitle: '1. 처리하는 개인정보의 항목 및 수집방법',
      list: [
        {
          subject: [
            {
              title: 'a. 수집하는 개인정보의 항목',
              des:
                '회사는 최초 회원가입 및 서비스 이용시 이용자로부터 아래와 같은 개인정보를 수집하고 있습니다. 서비스 이용시 수집되는 개인정보 항목에는 이용자의 건강상태 및 민감정보 사항이 포함되어 있습니다.',
              contents: [
                '로그인 이메일, 비밀번호, 성명, 생년월일, 성별, 전화번호, 주소, 결제관련 정보, 설문검사관련 정보 및 결과, 접속 로그, 접속 IP 정보'
              ]
            }
          ]
        },
        {
          subject: [
            {
              title: 'b. 개인정보 수집방법',
              contents: [
                '이용자가 서비스 이용과정에서 직접 입력',
                '서비스 이용시 기기로부터 측정되거나 자동생성'
              ]
            }
          ]
        }
      ]
    },
    {
      listTitle: '2. 개인정보의 처리 목적',
      list: [
        {
          noti:
            '회사는 아래와 같은 목적으로 서비스 제공을 위한 최소한의 개인정보만을 수집하며, 수집한 정보를 목적 외로 사용하거나, 이용자의 동의 없이 외부에 공개하지 않습니다.',
          subject: [
            {
              title: 'a. 회원관리',
              des:
                '서비스 제공에 따른 개인식별, 가입의사 확인, 이용약관 등 위반 회원에 대한 이용제한 조치, 가입 및 가입횟수 제한, 서비스 부정 이용 제재, 비인가 사용 방지, 법정대리인 동의 없는 만 14세 미만 아동의 가입 방지(추후 법정 대리인 본인 확인), 고충 처리 및 분쟁 조정을 위한 기록 보존, 고지사항 전달, 회원탈퇴 의사의 확인 등'
            },
            {
              title: 'b. 서비스 제공',
              contents: [
                '설문 검사 서비스 제공',
                '검사 분석 및 결과보고 서비스 제공',
                '검사 결과에 따른 맞춤형 콘텐츠 및 이벤트 추천 서비스 제공'
              ]
            },
            {
              title:
                'c. 신규 서비스 개발 및 통계적 분석, 마케팅 이벤트에 활용: 신규 서비스 개발 및 맞춤 서비스 제공, 서비스 이용에 대한 분석 및 통계, 이벤트/광고성 정보 제공 및 참여기회 제공'
            },
            {
              title:
                'd. 고충처리:  민원인의 신원확인, 민원사항 확인, 사실조사를 위한 연락∙통지, 처리결과 통보 등'
            }
          ]
        }
      ]
    },
    {
      listTitle: '3. 개인정보의 처리 및 보유기간',
      list: [
        {
          noti:
            '회사는 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보 수집시에 동의 받은 개인정보 보유∙이용기간 내에서 개인정보를 보유∙이용합니다. 회사는 원칙적으로 이용자의 개인정보 수집/이용 목적이 달성되면 지체 없이 파기합니다.',
          subject: [
            {
              title:
                'a. 개인식별정보: 로그인 이메일, 비밀번호, 성명, 성별, 생년월일, 주소, 전화번호',
              des: '처리 및 보유기간: 회원 탈퇴시까지. 다만, ',
              contents: [
                '법령 위반에 따른 수사, 조사 등이 진행중인 경우에는 해당 수사, 조사 종료시까지',
                '서비스 이용에 따른 채권채무관계 잔존시에는 해당 채권, 채무관계 정산시까지'
              ]
            },
            {
              title: 'b. 설문검사 관련 개인정보: 설문검사정보 및 결과 ',
              des: '처리 및 보유기간: 이용목적 달성시까지'
            }
          ]
        },
        {
          noti:
            '단, 관련법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다. 이 경우 회사는 해당 개인정보를 별도의 데이터베이스로 옮기거나 보관장소를 달리하여 보관합니다.',
          subject: [
            {
              title:
                'a. 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시․광고, 계약내용 및 이행 등 거래에 관한 기록',
              contents: [
                '- 표시․광고에 관한 기록 : 6개월',
                '- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년',
                '- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년'
              ]
            },
            {
              title: 'b. 「통신비밀보호법」제41조에 따른 통신사실확인자료 보관',
              contents: [
                '- 컴퓨터통신, 인터넷 로그기록자료, 접속 추적자료 : 3개월',
                '- 그 외 통신사실 확인자료 : 1년 '
              ]
            }
          ]
        }
      ]
    },
    {
      listTitle: '4. 개인정보의 파기 절차 및 방법',
      list: [
        {
          noti:
            '회사는 원칙적으로 개인정보 처리목적이 달성된 경우 지체없이 해당 개인정보를 파기하며, 파기절차 및 파기방법은 다음과 같습니다.',
          subject: [
            {
              title: 'a. 파기절차',
              des:
                '이용자가 회원가입 등을 위해 입력한 정보는 수집∙이용 목적이 달성된 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, 별도의 DB로 옮겨진 개인정보는 법률에 의한 경우 외에는 다른 목적으로 이용되지 않습니다.'
            },
            {
              title: 'b 파기방법',
              des:
                '전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이에 출력된 정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.'
            }
          ]
        }
      ]
    },
    {
      listTitle: '5. 이용자 및 법정대리인의 권리',
      list: [
        {
          noti:
            '이용자 또는 법정대리인은 회사에 대해 언제든지 자신 혹은 만 14세 미만 아동의 개인정보 열람/정정/삭제/처리정지/동의 철회 등 개인정보보호 관련 권리를 행사할 수 있습니다. 이용자 또는 법정대리인은 회사의 개인정보 처리에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를 요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이 어려울 수 있습니다.',
          subject: [
            {
              title:
                'a. 개인정보 조회, 수정을 위해서는 서비스 내 "기본정보 수정"을, 회원탈퇴를 위해서는 회사의 전자우편 또는 전화를 통해 탈퇴 신청을 한 후 본인 확인 절차를 걸치고 직접 열람, 정정 또는 탈퇴가 가능합니다.'
            },
            {
              title:
                'b. 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락한 경우에도 지체 없이 조치합니다.'
            },
            {
              title:
                'c. 이용자가 개인정보의 오류에 대한 정정을 요청하는 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제 3자에게 이미 제공한 경우에는 정정 처리결과를 제 3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.'
            },
            {
              title:
                'd. 회사는 이용자 또는 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보를 "제5조 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.'
            },
            {
              title:
                'e. 본조에 따른 개인정보보호 관련 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수 있습니다. 이 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출해야 합니다.'
            }
          ]
        }
      ]
    },
    {
      listTitle: '6. 쿠키의 운영 및 수집거부 방법',
      list: [
        {
          subject: [
            {
              title:
                'a. 회사는 이용자에게 맞춤형 서비스를 제공하고, 보다 신속한 서비스 제공을 위해 이용자에정보대한 정보를 저장하고 수시로 불러오는 ‘쿠키(Cookie)’를 사용합니다. 쿠키란 웹사이트 방문 시, 서버가 이용자의 하드디스크에 저장하는 작은 기록 정보 파일을 말합니다. 이후 이용자가 웹사이트에 방문할 경우 웹사이트 서버는 이용자의 하드디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공합니다. 쿠키는 개인을 식별하는 정보를 자동적·능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다. 단, 쿠키 저장을 거부하는 경우에는 일부 서비스 이용이 어려울 수 있습니다.'
            },
            {
              title:
                'b. 쿠키의 설치 / 운용 및 거부 방법 안내: 이용자는 사용하는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용 혹은 거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 지정할 수 있습니다.',
              contents: [
                'Internet Explorer 의 경우 [도구] 메뉴에서 [인터넷 옵션]을 선택합니다. [개인정보 탭]에서 설정합니다. [개인정보취급 수준]을 설정합니다.',
                'Chrome의 경우 [설정] 하단 [고급설정 표시]를 클릭합니다. [개인정보] 컨텐츠 설정 -> [쿠키] 영역에서 원하시는 정책을 선택합니다.'
              ]
            }
          ]
        }
      ]
    },
    {
      listTitle: '7. 개인정보보호를 위한 안전성 확보조치',
      list: [
        {
          noti:
            '회사는 이용자들의 개인정보 보호를 위해 다음과 같은 기술적∙관리적∙물리적 노력을 하고 있습니다',
          subject: [
            {
              title: 'a. 기술적 조치',
              contents: [
                '개인정보 암호화',
                'i. 이용자의 비밀번호 등 중요정보는 암호화되어 저장, 관리되고 있으며, 개인정보의 확인 및 변경은 본인에 의해서만 가능합니다.',
                '해킹 등에 대비한 대책',
                'ii. 회사는 해킹이나 악성코드에 의하여 이용자들의 개인정보가 유출· 훼손되는 것을 방지하기 위하여 침입차단 시스템을 24시간 운영하여 외부로부터의 무단접근을 통제하고 있으며, 백신 프로그램을 설치하여 시스템이 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 암호화통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 더불어 회사는 더욱 안전한 개인정보 처리를 위해 최대한의 기술적 방법을 구비하기 위해 노력하고 있습니다.',
                '접속기록의 위조, 변조 방지를 위한 조치',
                'iii. 개인정보에 대한 접근 제한: 개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.'
              ]
            },
            {
              title: 'b. 관리적 조치',
              contents: [
                'i. 내부관리계획의 수립 및 시행',
                'ii. 취급직원의 최소화 및 교육',
                '개인정보 처리 직원의 최소화 및 교육 회사는 개인정보를 처리하는 직원을 최소한으로 제한하고 있으며, 관련 직원들에 대한 수시 교육을 실시하여 개인정보처리방침의 중요성을 인지 시키고 있습니다.'
              ]
            },
            {
              title: 'c. 물리적 조치',
              contents: [
                'i. 전산실 및 자료보관실에 대한 출입통제',
                '문서보안을 위한 잠금장치 사용: 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.'
              ]
            }
          ]
        }
      ]
    },
    {
      listTitle: '8. 개인정보 보호책임자 및 담당부서',
      list: [
        {
          noti:
            '이용자는 회사의 서비스를 이용하는 중 발생하는 모든 개인정보 관련 문의, 불만처리 등에 관한 사항을 개인정보 보호책임자 혹은 담당부서로 문의할 수 있습니다. 회사는 이용자의 문의에 대한 신속하고 성실한 답변 및 처리를 위해 노력하고 있습니다.',
          subject: [
            {
              title: '[개인정보 보호 책임자]',
              contents: ['개인정보 보호책임자:', ' 담당부서/직위: ', '전화번호: ', ' 이메일 주소: ']
            }
          ]
        },
        {
          noti:
            '기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.',
          subject: [
            {
              title: '▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)',
              contents: [
                '소관업무 : 개인정보 침해사실 신고, 상담 신청',
                '홈페이지 : privacy.kisa.or.kr',
                '전화 : (국번없이) 118',
                '주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터'
              ]
            },
            {
              title: '▶ 개인정보 분쟁조정위원회',
              contents: [
                '소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)',
                '홈페이지 : www.kopico.go.kr',
                '전화 : (국번없이) 1833-6972',
                '주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층'
              ]
            },
            {
              title: '▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)'
            },
            {
              title: '▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)'
            }
          ]
        }
      ]
    },
    {
      listTitle: '9. 링크 사이트에 대한 책임',
      list: [
        {
          noti:
            '회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 그러나 링크 웹사이트들은 회사의 개인정보처리방침이 적용되지 않을 수 있으므로 해당 링크를 통해 외부 웹사이트로 이동하는 경우, 해당 서비스의 정책을 검토하시기 바랍니다.'
        }
      ]
    },
    {
      listTitle: '10. 개인정보처리방침의 고지',
      list: [
        {
          noti:
            '회사는 개인정보처리방침에 대한 변경이 있을 경우에는 개정 개인정보처리방침의 시행일로부터 최소 7일 전에 홈페이지/모바일 앱의 공지사항 또는 이메일을 통해 고지합니다. 또한, 필요 시 이용자 동의를 다시 받을 수도 있습니다.'
        }
      ]
    },
    {
      listTitle: '부 칙',
      list: [
        {
          noti: '본 개인정보취급방침은 2018년 5월 1일 부터 적용됩니다.',
          subject: [
            {
              title: '- 개인정보 처리방침 제정일자: 2017. 9. 22'
            },
            {
              title: '- 개인정보 처리방침 최종 변경 개정일자: 2017. 9. 22'
            }
          ]
        }
      ]
    }
  ]
};

export default PRIVACY_POLICY;
