import React from 'react';
import styled from 'styled-components';
import MainButton from '../../buttons/MainButton'

const Container = styled.div`
    width: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px;
    margin: 5px;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background: white;
`;

const Title = styled.span`
  color: black;
  margin: 10px;
  text-align: left;
  color: black;
  font-family: "GodoB";
  font-size: 32px;
`;

const Instruction = styled.span`
    color: black;
    margin: 10px;
    font-size: 18px;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    line-height: 30px;
`;

const Line = styled.div`
  width: 100%;
  height: 4px;
  background-color: black;
  border-radius: 100px;
  margin: 10px;
  align-self: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const ChildContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SurveyIntro = (props) => {
    return <Container>
        <Title>{props.title}</Title>
        <ChildContainer>
            <Line />
            <Instruction>{props.instruction}</Instruction>
            <Line />
            {props.children}
            <MainButton
                onClick={() => props.onButtonPressed(1)}
                hideInMobile={true}
                hideArrow={true}
                style={{ marginLeft: '10px', marginTop: "30px" }}
                text="다음"
            />
        </ChildContainer>
    </Container>
}

export default SurveyIntro;