import React, { Component } from 'react';
import styled from 'styled-components';
import TitleContainer from '../components/layouts/TitleContainer';
import COLORS from '../values/Colors';
import ABOUT from '../values/pages/About';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding-top: 40px;
    justify-content: center;
  }

  @media (max-width: 415px){
    padding-top: 0px;
  }
`;

const SubContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px){
    align-items: center;
  }
`;

const PartnerContainer = styled.div`
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 20px;
  border-radius: 20px;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 900px) {
    margin:0px;
  }
`;

const BrainwayContainer = styled(PartnerContainer)`
  flex-direction: column;
  padding: 100px 40px 100px 40px;
  justify-content: center;
  align-items: center;
`;

const PartnerSubContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 25px;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 415px) {
    padding: 25px 40px;
  }
`;

const PartnerTextContainer = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 415px) {
    padding: 20px 40px ;
  }

`;

const BrainwayLogo = styled.img`
  height: auto;
  display: flex;
  @media (max-width: 415px){
    width: 70%; 
  }
`;

const Logo = styled.img`
  width: 200px;
  object-fit: contain;
  display: flex;
  
  @media (max-width: 415px){
    
    
  }
`;

const BannerContainer = styled.div`
  width: 100%;
  background-image: ${(props) => props.src && `url(${props.src})`};
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 40px;
  margin: 20px 0px 80px 0px;
`;

const Title = styled.span`
  text-align: left;
  font-family: 'GodoB';
  font-size: 38px;
  color: black;

  @media (max-width: 415px){
    font-size: 24px;
    line-height: 40px;
  }
`;

const SubTitle = styled.span`
  text-align: left;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 20px;
  line-height: 30px;
  padding: 10px 0px;
  color: white;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 15px;
    line-height: 28px;
    text-align: left;
  }

  @media (max-width: 415px){
    max-width: 80%;
    font-size: 14px;
    line-height: 28px;
    text-align: left;
  }
`;

const Header1 = styled.span`
  font-family: 'GodoB';
  font-size: 32px;
  line-height: 60px;
  @media (max-width: 768px) {
    padding: 0px 20px;
    line-height: 40px;
    text-align: center;
  }

  @media (max-width: 415px){
    font-size: 24px;
    line-height: 40px;
  }
`;

const Header2 = styled.span`
  font-family: 'GodoB';
  font-size: 26px;
  text-align: left;
`;

const Header3 = styled.span`
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: 25px;
`;

const Text = styled.span`
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: 30px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 15px;
    line-height: 28px;
    text-align: left;
  }

  @media (max-width: 415px){
    font-size: 14px;
    line-height: 28px;
  }
`;

const Gap = styled.div`
  width: 100%;
  height: 30px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.07);
  margin: 60px 0px;
`;

const TextButton = styled.button`
  background: transparent;
  color: ${COLORS.primary};
  font-family: 'GodoB';
  font-size: 16px;
  border: 0px;
  cursor: pointer;
  margin: 30px 0px 0px 0px;
`;

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container>
        <TitleContainer bgImage={require('../res/images/bg_about_1.png')}>
          <MainContainer>
            <Title style={{ color: 'white' }}>{ABOUT.title}</Title>
          </MainContainer>
          <SubContainer>
            {ABOUT.subtitles.map((subtitle) => (
              <SubTitle>{subtitle}</SubTitle>
            ))}
            <SubTitle
              style={{
                marginTop: '20px',
                color: 'rgba(255,255,255,0.7)'
              }}
            >
              {ABOUT.hashtags}
            </SubTitle>
          </SubContainer>
        </TitleContainer>

        <BrainwayContainer>
          <BrainwayLogo
            style={{ marginBottom: '50px' }}
            src={require('../res/images/brainway_logo_blue.png')}
            srcSet={`${require('../res/images/brainway_logo_blue@2x.png')} 2x, ${require('../res/images/brainway_logo_blue@3x.png')} 3x`}
          />
          <Text>{ABOUT.brainway.text}</Text>
        </BrainwayContainer>

        <BannerContainer src={require('../res/images/bg_partners.png')}>
          <Header1>{ABOUT.banner.title}</Header1>
          <Text style={{ marginTop: '20px' }}>{ABOUT.banner.subtitle}</Text>
        </BannerContainer>

        {ABOUT.partners.map((partner, index) => (
          <div>
            {index !== 0 && <Line />}
            <PartnerContainer key={index}>
              <PartnerSubContainer>
                {partner.institute.split('\n').map((string) => (
                  <Header2>{string}</Header2>
                ))}
                <Gap style={{ height: '5px' }} />
                {partner.affiliation.split('\n').map((string) => (
                  <Header3>{string}</Header3>
                ))}
                <Gap />
                <Logo src={require(`../res/images/${partner.logo}.png`)} />
              </PartnerSubContainer>
              <PartnerTextContainer>
                {partner.texts.map(text => {
                  return <Text>{text}</Text>
                })}
                {partner.link !== '' && (
                  <TextButton
                    onClick={() => {
                      window.open(partner.link);
                    }}
                  >
                    자세히 알아보기
                  </TextButton>
                )}
              </PartnerTextContainer>
            </PartnerContainer>
          </div>
        ))}
      </Container>
    );
  }
}

export default About;
