const RESULT = {
  buttons: [
    {
      trial: 'cognition',
      name: '인지의 길',
      desc:
        '신경 심리 분석을 바탕으로, 개인의 인지적 특성 파악, 강점 및 약점을 기반으로 한 적성을 탐색합니다.'
    },
    {
      trial: 'learning',
      name: '학습의 길',
      desc:
        '자녀의 학업 준비도와 성향를 탐색하고 개인에 맞는 올바른 학습 습관과 환경을 제안 드립니다.'
    },
    {
      trial: 'communication',
      name: '소통의 길',
      desc:
        '부모와 자녀의 성향, 그리고 관계적인 특성을 파악하여 건강한 부모-자녀 관계를 위한 효율적인 맞춤형 소통법을 제안 드립니다.'
    },
    {
      trial: 'healing',
      name: '힐링의 길',
      desc:
        '개인과 가족의 정신건강에 대한 종합적인 분석 및 제안을 통해 ‘나’와 ‘우리’를 더 알아가는 시간을 제공합니다.'
    },
    {
      trial: 'neuroscience',
      name: '뉴로사이언스 랩',
      desc:
        '뇌가 발달연령에 맞게 성장하고 있는지 살펴보고, 인지기능별 뇌활성의 특성을 알아보며, 다양한 뇌 과학적 방법을 통해 진단합니다.'
    }
  ]
};

export default RESULT;
