import React from 'react';
import styled from 'styled-components';
import iconX from '../../res/images/icon_x.png';
import iconX2x from '../../res/images/icon_x@2x.png';
import iconX3x from '../../res/images/icon_x@3x.png';

const Container = styled.div`
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const CloseButton = ({ onClick }) => (
  <Container onClick={onClick}>
    <Image src={iconX} srcSet={`${iconX2x} 2x, ${iconX3x} 3x`} />
  </Container>
);

export default CloseButton;
