import React from 'react';
import styled from 'styled-components';
import COLORS from '../../values/Colors';
import {useLocation} from 'react-router-dom'

const Button = styled.button`
  display: ${(props) => (props.show ? 'none' : 'flex')};
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0px;
  border-radius: 100px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
  color: ${COLORS.primary};
  font-size: 18px;
  font-family: 'GodoB';
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.primary};
    color: white;
  }

  @media (max-width: 415px) {
    width: 70px;
    height: 70px;
    font-size: 16px;
    bottom: 20px;
    right: 20px;
   
    &:hover {
    background-color: white;
    color: ${COLORS.primary};
    }
  }
`;

const TopButton = (props) => {
  const { show } = props;
  const { pathname } = useLocation()

  if(!pathname.startsWith('/trial/')){
    return (
      <Button
        show={show}
        onClick={() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        }
      >
        TOP
      </Button>
    ); 
  }else {
    return <div/>
  }
};

export default TopButton;
