import React, { useEffect } from 'react';
import styled from 'styled-components';
import TERMS_OF_SERVICE from '../values/pages/TermsOfService';
import COLORS from '../values/Colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 0px 0px 0px;

  @media (max-width: 768px) {
    padding: 160px 0px 0px 0px;
  }

  @media (max-width: 415px){
    padding: 100px 0px 0px 0px;

  }
`;

const BodyContainer = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px){
    width: 90%;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: center;
  line-height: 100px;

  @media (max-width: 415px){ 
  }
`;

const ListTitle = styled.ul`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
  line-height: 80px;
  
  @media (max-width: 768px){
    line-height: 50px;
  }
`;

const SubjectTitle = styled.ul`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
  line-height: 40px;

  @media (max-width: 768px){
    line-height: 30px;
  }
`;

const Contents = styled.ul`
  font-size: 16px;
  font-weight: 100;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
  padding-inline-start: 100px;

  @media (max-width: 768px){
    font-size: 14px;
    padding-inline-start: 60px;
    line-height: 20px;
  }
`;

const TermsOfService = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Container>
      <Title>{TERMS_OF_SERVICE.title}</Title>
      <BodyContainer>
      {TERMS_OF_SERVICE.contents.map((content, contentIndex) => (
        <div key={contentIndex}>
          <ListTitle>{content.listTitle}</ListTitle>
          {content.subject.map((sub, subIndex) => (
            <div key={subIndex}>
              <SubjectTitle>{sub.title}</SubjectTitle>
              {sub.contents?.map((con, conIndex) => (
                <Contents key={conIndex}>{con}</Contents>
              ))}
            </div>
          ))}
        </div>
      ))}
      </BodyContainer>
    </Container>
  );
};

export default TermsOfService;
