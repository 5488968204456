import React from 'react';
import styled from 'styled-components';
import ChoiceButton from '../../ChoiceButton';
import ChoiceInput from '../../ChoiceInput';

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px;

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-top: 10px;
  }
`;

const handleSelected = (options, selected, onInput) => {
  let input = {
    type: options.type,
    answer: selected.text
  }

  if (selected.value) {
    input = {
      ...input,
      point: selected.value,
      total: options.values && Math.max(...options.values)
    }
  }

  onInput(input)
}

const MultipleChoices = (props) => {
  const { options, onInput, value } = props;
  return <ChoicesContainer>
    {options.texts &&
      options.texts.map((optText, index) => {
        if (optText === '기타') {
          return <ChoiceInput
            key={index}
            isSelected={value && value === optText}
            text={optText}
            onSelected={selected => handleSelected(options, selected, onInput)}
          />
        }
        return (
          <ChoiceButton
            key={index}
            value={options.values && options.values[index]}
            text={optText}
            isSelected={value && value === optText}
            onSelected={selected => handleSelected(options, selected, onInput)}
          />
        );
      })}
  </ChoicesContainer>
}

export default MultipleChoices