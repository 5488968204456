import React from 'react';
import styled from 'styled-components';
import useComponentVisible from '../utils/UseComponentVisible';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100px;
    margin: 0px 5px;
    cursor: pointer;
    box-shadow: ${props => props.shadow ? props.shadow : "1px 0px 3px rgba(0, 0, 0, 0.3)"};
    
    &:hover{
        background-color: rgba(0,0,0,0.05)
    }
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`;

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background-color: red;
    position: absolute;
    border-radius: 100px;
    right: 10px;
    top: 10px;
`;

function hello(props){
    if (!props.package) {
        console.log("2")
        return [{ collection: 'packages', where:['surveyId', '==', props.match.param.id]}]
    } else if (!props.tasks) {
        console.log(Object.values(props.trialSets))
        return [
            { collection: 'tasks', where:["taskId", "in", props.package.tasks]}
        ]
    }
}

const IconButton = (props) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    return <Container
        shadow={props.shadow}
        ref={ref}
        to={props.to}
        onClick={() => {
            setIsComponentVisible(!isComponentVisible)
            props.onClick && props.onClick()
        }}
    >
        <Icon src={props.src} />
        {props.isNew && <Dot />}
        {isComponentVisible && props.children}
    </Container>
}

export default IconButton