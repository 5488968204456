export const COGNITION = 'cognition';
export const COGNITION_CODE = 'COG';
export const COGNITION_SECTION_1 = '적성프로파일링';
export const COGNITION_SECTION_1_CODE = 'COG1';
export const COGNITION_SECTION_2 = '인지/학습 준비도';
export const COGNITION_SECTION_2_CODE = 'COG2';

export const LEARNING = 'learning';
export const LEARNING_CODE = 'LRN';
export const LEARNING_SECTION_1 = '학습에 대한 환경적 특성';
export const LEARNING_SECTION_1_CODE = 'LRN1';
export const LEARNING_SECTION_2 = '학습에 대한 개인적 특성';
export const LEARNING_SECTION_2_CODE = 'LRN2';

export const COMMUNICATION = 'communication';
export const COMMUNICATION_CODE = 'COM';
export const COMMUNICATION_SECTION_1 = '사회성';
export const COMMUNICATION_SECTION_1_CODE = 'COM1';
export const COMMUNICATION_SECTION_2 = '부모 스트레스';
export const COMMUNICATION_SECTION_2_CODE = 'COM2';

export const HEALING = 'healing';
export const HEALING_CODE = 'HLG';
export const HEALING_SECTION_1 = '결혼 만족도';
export const HEALING_SECTION_1_CODE = 'HLG1';
export const HEALING_SECTION_2 = '자아존중감';
export const HEALING_SECTION_2_CODE = 'HLG2';
export const HEALING_SECTION_3 = '스트레스 지수';
export const HEALING_SECTION_3_CODE = 'HLG3';
