export const Cognition = {
    surveyId: "A",
    title: "인지의 길",
    instruction: "",
    tasks: [
        {
            taskId: "A_0",
            title: "기본면담지",
            instruction: '문항을 읽고 자녀(나)와 유사한 정도를 항목에 표시해 주세요.',
            sections: [
                {
                    sectionId: "A_0_sub01",
                    title: "주호소영역 1",
                    questions: [
                        {
                            questionId: "A_0_001",
                            question: "귀하는 자녀의 어떤 부분에 대해서 걱정되거나 염려되시나요?",
                            instruction: "해당하는 항목 옆 네모 같에 체크",
                            options: {
                                texts: [
                                    '진로/적성 (장래 희망)',
                                    '인지 및 학습 (지능, 공부, 성적)',
                                    '정서 (기분, 감정)',
                                    '대인관계 (친구, 가족관계)',
                                    '주의력 (집중력)',
                                    '성격',
                                    '스트레스',
                                    '신체증상 및 수면 (건강)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_a",
                    title: "주호소영역 1-1",
                    questions: [
                        {
                            questionId: "A_0_002",
                            question: "진로/적성 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 어떤 것에 관심이 있는지 모르겠음',
                                    '자녀가 준비할 수 있는 어떤 직업들이 있는지 잘 모르겠음',
                                    '딱히 하고 싶은 것이 없어보임',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_b",
                    title: "주호소영역 1-2",
                    questions: [
                        {
                            questionId: "A_0_003",
                            question: "인지 및 학습 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 기억하는 것을 어려워함(기억이 잘/자주 안남, 암기하는 것을 어려워함)',
                                    '새로 배우고 익히는 것을 어려워함(배우는 것이 느림, 처음하는 것을 하기 어려워함)',
                                    '지시를 잘 이해하지 못하는 것 같음',
                                    '수업을 잘 따라가지 못하는 것 같음',
                                    '학습 동기가 부족해 보임',
                                    '자녀의 학습전략을 어떻게 세워야 할 지 모르겠음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_c",
                    title: "주호소영역 1-3",
                    questions: [
                        {
                            questionId: "A_0_004",
                            question: "정서 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 기억하는 것을 어려워함(기억이 잘/자주 안남, 암기하는 것을 어려워함)',
                                    '새로 배우고 익히는 것을 어려워함(배우는 것이 느림, 처음하는 것을 하기 어려워함)',
                                    '지시를 잘 이해하지 못하는 것 같음',
                                    '수업을 잘 따라가지 못하는 것 같음',
                                    '학습 동기가 부족해 보임',
                                    '자녀의 학습전략을 어떻게 세워야 할 지 모르겠음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_d",
                    title: "주호소영역 1-4",
                    questions: [
                        {
                            questionId: "A_0_005",
                            question: "대인관계 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 새로운 사람이나 낯선 상황을 힘들어함(처음 겪는 상황에 많이 긴장하고 피곤함을 느낌)',
                                    '친구 사귀는 것에 관심이 없어 보임.',
                                    '또래 아이들과 어울리기를 어려워함(친구들과 친해지기 어려움, 관심 주제가 다름)',
                                    '친구관계에서 갈등이 있음(친구와 자주 싸우거나 괴롭힘을 당함)',
                                    '부모 혹은 형제와 사이가 안좋음(자주 싸움, 말을 안함)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_e",
                    title: "주호소영역 1-5",
                    questions: [
                        {
                            questionId: "A_0_006",
                            question: "주의력 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀는 한 가지 일에 오래 주의를 기울이지 못하고 주의산만함',
                                    '끊임없이 움직이거나 안절부절 못함',
                                    '성미가 급하고 충동적으로 행동함',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_f",
                    title: "주호소영역 1-6",
                    questions: [
                        {
                            questionId: "A_0_007",
                            question: "성격 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀의 타고난 기질이나 성격이 어떤지 궁금함',
                                    '자녀의 성향 때문에 학업이나 친구관계에 어려움이 있는 것 같음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_g",
                    title: "주호소영역 1-7",
                    questions: [
                        {
                            questionId: "A_0_008",
                            question: "스트레스 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀에게 학업 성적에 대한 스트레스가 있음',
                                    '또래관계에 대한 스트레스가 있음',
                                    '가족관계에서의 스트레스가 있음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub01_h",
                    title: "주호소영역 1-8",
                    questions: [
                        {
                            questionId: "A_0_009",
                            question: "신체증상 및 수면 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 (뚜렷한 의학적 원인 없이) 자주 두통을 호소',
                                    '(뚜렷한 의학적 원인 없이) 자주 복통을 호소',
                                    '잠이 들기까지 시간이 매우 오래 걸리고, 자는 동안 자주 깨거나 악몽을 꿈',
                                    '피부 발진이 있음 (ex. 두드러기, 가려움 등)',
                                    '어지럽다고 하거나 이명이 있음 (ex. 귀에서 특정 소리가 들림)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub02",
                    title: "주호소영역 2",
                    questions: [
                        {
                            questionId: "A_0_010",
                            question: "걱정되거나 염려되는 부분이 평균적으로 얼마나 지속되었나요?",
                            options: {
                                value: [0, 1, 2],
                                texts: [
                                    '1-3주 정도',
                                    '1-3개월 정도',
                                    '4개월 이상'
                                ],
                                type: "multiple_choice"
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub03",
                    title: "주호소영역 3",
                    questions: [
                        {
                            questionId: "A_0_011",
                            question: '걱정되거나 염려되는 부분이 자녀에게 미치는 부정적인 영향은 어느 정도인가요?',
                            options: {
                                images: [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                ],
                                texts: [
                                    "10%",
                                    "30%",
                                    "50%",
                                    "70%",
                                    "100%"
                                ],
                                additional: [
                                    "약간 신경이 쓰인다.",
                                    "",
                                    "대체로 신경 쓰인다.",
                                    "",
                                    "매우 많이 신경 쓰인다.\n(잠을 잘 못잔다, 친구관계가 안좋아 졌다, 가족관계가 안좋아졌다, 식욕이 줄었다, 학교생활을 힘들어 한다 등)"
                                ],
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub04",
                    title: "주호소영역 4",
                    questions: [
                        {
                            questionId: "A_0_012",
                            question: '검사를 신청하게 된 이유는 무엇인가요?',
                            options: {
                                type: 'short_answer'
                            }
                        },
                    ]
                },
                {
                    sectionId: "A_0_sub05",
                    title: "주호소영역 5",
                    subSections: [
                        {
                            subSectionId: "A_0_sub05_a",
                            title: "신체적 기능",
                            questions: [
                                {
                                    questionId: "A_0_013",
                                    question: "10분 이상 걷는 것을 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_014",
                                    question: "스포츠 활동(달리기, 구기 종목 등)을 하거나 운동하는 것을 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_015",
                                    question: "무거운 것을 들기 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_016",
                                    question: "쉽게 통증을 느끼는 것 같나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_017",
                                    question: "쉽게 피로를 느끼는 것 같나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_018",
                                    question: '평소 자녀의 건강 및 신체적 기능에 대해 더 이야기 해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_0_sub05_b",
                            title: "정서적 기능",
                            questions: [
                                {
                                    questionId: "A_0_019",
                                    question: "쉽게 불안해하거나 겁을 내는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_020",
                                    question: "표정이 어둡거나 울적해보이나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_021",
                                    question: "쉽게 화를 내거나 분노하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_022",
                                    question: "수면에 어려움이 있나요?(잠드는 것, 수면 유지의 어려움 등)",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_023",
                                    question: "자신에게 무슨 일이 일어날지 걱정을 많이 하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_024",
                                    question: '평소 자녀의 기분이나 감정 등에 대해 더 이야기해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_0_sub05_c",
                            title: "또래친구들과의 관계",
                            questions: [
                                {
                                    questionId: "A_0_025",
                                    question: "또래 친구들과 잘 지내는 것을 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_026",
                                    question: "또래 친구들이 자녀와 친구가 되는 것을 꺼리는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_027",
                                    question: "또래 친구들에게 놀림을 많이 받는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_028",
                                    question: "또래 친구들이 일반적으로 할 수 하는 일을 어려워하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_029",
                                    question: "또래 친구들과 놀이나 활동을 할 때 중간에 빠지지 않고 끝까지 함께 시간을 잘 보내는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_030",
                                    question: '평소 자녀의 또래 친구들이나 전반적인 사람들과의 관계에 대해 더 이야기해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_0_sub05_d",
                            title: "학교생활",
                            questions: [
                                {
                                    questionId: "A_0_031",
                                    question: "수업에 집중하는 것을 힘들어 하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_032",
                                    question: "자신의 할 일을 잘 잊어버리는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_033",
                                    question: "학교 공부를 따라 가기 힘들어 하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_034",
                                    question: "컨디션이 좋지 않다고 학교에 가지 않으려고 할 때가 있나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_035",
                                    question: "아파서 병원에 가야겠다고 학교에 가지 않으려고 할 때가 있나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_0_036",
                                    question: '평소 자녀의 학교 생활에 대해 더 이야기해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            taskId: "A_1",
            title: "인지적 준비도",
            instruction: '문항을 읽고 지난 6개월 동안 자신의 행동을 가장 잘 나타내고 있는 항목에 표시해주세요.',
            sections: [
                {
                    sectionId: "A_1_sub01",
                    title: "부주의",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_1_001",
                            question: "세부적인 면에 대해 꼼꼼하게 주의를 기울이지 못하거나, 학업에서 부주의한 실수를 한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_002",
                            question: "일을 하거나 놀이를 할 때 지속적으로 주의를 집중하는데 어려움이 있다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_003",
                            question: "다른 사람이 마주보고 이야기할 때 경청하지 않는 것처럼 보인다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_004",
                            question: "지시를 따르지 않고 일을 끝내지 못한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_005",
                            question: "과제와 일을 체계적으로 하지 못한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_006",
                            question: "지속적인 노력이 요구되는 과제(학교 공부나 숙제)를 하지 않으려 한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_007",
                            question: "과제나 일을 하는데 필요한 물건들을 잃어버린다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_008",
                            question: "쉽게 산만해진다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_009",
                            question: "일상적으로 하는 일을 잊어버린다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "A_1_sub02",
                    title: "과잉행동/충동성",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_1_010",
                            question: "손발을 가만히 두지 못하거나 의자에 앉아서도 몸을 꼼지락 거린다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_011",
                            question: "자리아 앉아 있어야 하는 교실이나 다른 상황에서 앉아있지 못한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_012",
                            question: "그렇게 하면 안되는 상황에서 지나치게 뛰어다니거나 기어오른다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_013",
                            question: "여가 활동이나 재미있는 일에 조용히 참여 하기가 어렵다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_014",
                            question: "끊임없이 무엇인가를 하거나 마치 모터가 돌아가 듯 움직인다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_015",
                            question: "지나치게 말을 많이 한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_016",
                            question: "질문이 채 끝나기도 전에 성급하게 대답한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_017",
                            question: "차례를 기다리는데 어려움이 있다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_1_018",
                            question: "다른 사람을 방해하거나 간섭한다.",
                            options: {
                                values: [3, 2, 1, 0],
                                texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
            ]
        },
        {
            taskId: "A_2",
            title: "자기관리능력",
            instruction: '문항을 읽고 여러분이 무언가를 배운다고 상상했을 때, 일반적으로 자신에게 적용되는 문장인지에 생각해보고 ‘아니다’ 혹은 ‘그렇다’에 표시해주세요.',
            sections: [
                {
                    sectionId: "A_2_sub01",
                    title: "인지 능력에 대한 정보",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "A_2_sub01_a",
                            title: "서술적 지식",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_010",
                                    question: "상황에 따라 각각 다른 학습전략을 사용한다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_2_017",
                                    question: "내가 어떤 것에 대해 얼마나 잘 이해하고 있는지, 스스로 잘 판단하고 있다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },                               
                            ]
                        },
                        {
                            subSectionId: "A_2_sub01_b",
                            title: "절차적 지식",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_003",
                                    question: "시험이 끝나면, 점수가 나오기 전에 내 성적을 어느정도 예상할 수 있다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_2_014",
                                    question: "이해가 안되는 것이 있으면 다른 사람들에게 물어본다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_2_sub01_c",
                            title: "조건적 지식",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_018",
                                    question: "지금 배우고 있는 내용을 내가 잘 이해하고 있는지 중간중간 체크해 본다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },                                
                            ]
                        },
                    ],
                },
                {
                    sectionId: "A_2_sub02",
                    title: "인지조절",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "A_2_sub02_a",
                            title: "이해점검",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_001",
                                    question: "나는 내가 지금 해야하는 일의 목표를 잘 이루어내고 있는지 중간중간 생각해보고 점검한다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_2_011",
                                    question: "과제를 시작하기 전에 필요한 자료들이 잘 준비되었는지 먼저 체크해 본다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },                               
                            ]
                        },
                        {
                            subSectionId: "A_2_sub02_b",
                            title: "계획",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_008",
                                    question: "내가 사용하는 학습방법이나 전략은 각각 그 나름대로의 구체적 이유가 있다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_2_022",
                                    question: "내가 흥미를 가지는 주제에 대해서는 더 많이 배우게 된다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_2_023",
                                    question: "공부할 때, 전체 목표과 세부목표를 나누어서 모두 세운다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_2_sub02_c",
                            title: "평가",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_007",
                                    question: "중요하다고 생각되는 내용에 훤씬더 주의집중한다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_2_024",
                                    question: "세부적인 내용보다는 전체적인 의미에 초점을 맞춘다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                }, 
                            ]
                        },
                        {
                            subSectionId: "A_2_sub02_d",
                            title: "교정 전략",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_025",
                                    question: "잘 모르겠는 부분이 있으면 멈추고 그 부분을 다시 읽어본다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_2_sub02_e",
                            title: "정보관리 전략",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_2_009",
                                    question: "나는 정보를 잘 기억하는 편이다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_2_013",
                                    question: "학습이 끝난 후에는, 내가 무엇을 배웠는지 요약해 본다.",
                                    options: {
                                        values: [0, 1],
                                        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                    ],
                },
            ]
        },
        {
            taskId: "A_3",
            title: "인지적 정보처리 및 문제해결능력",
            instruction: '문항을 읽고 평소 자신의 모습과 가까운 항목에 표시해주세요.',
            sections: [
                {
                    sectionId: "A_3_sub01",
                    title: "주의통제",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "A_3_sub01_a",
                            title: "주의집중",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_3_001",
                                    question: "나는 방이나 사물함이 너무 지저분해서 물건을 찾기 어렵다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_002",
                                    question: "나는 물건을 자주 잃어버린다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_003",
                                    question: "나는 가만히 앉아 있지 못하고 몸을 계속 움직인다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_004",
                                    question: "나는 '잠깐만요', '조금 있다가 할게요' 하고 미루는 경우가 많이 있다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_005",
                                    question: "나는 방금 들은 얘기도 잘 잊어버린다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_006",
                                    question: "나는 어떤 일을 시작하는 데에 시간이 오래 걸린다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_007",
                                    question: "나는 일을 마치는데 필요한 시간을 너무 적게 예상해서 시간이 부족하다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_3_sub01_b",
                            title: "주의전환",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_3_008",
                                    question: "나는 선생님이나 반이 바뀌면 정신이 없고 싫다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_009",
                                    question: "나는 평소에 하던 일과 장소 등이 바뀌는 것이 싫다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_010",
                                    question: "나는 갑자기 계획이 바뀌면 짜증이 난다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_011",
                                    question: "나는 혼이 나거나 안 좋은 일이 있으면 오랫동안 속상해한다.",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                    ],
                },
                {
                    sectionId: "A_3_sub02",
                    title: "목표지향",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "A_3_sub02_a",
                            title: "계획 및 조직화",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_3_012",
                                    question: "나는 숙제는 반드시 해간다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_013",
                                    question: "나는 할 일이 아무리 많아도 계획을 잘 세워서 실천할 수 있다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_014",
                                    question: "나는 숙제를 한 후 잘못된 부분이 없는지 확인한다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_015",
                                    question: "나는 끊임없이 노력하여 미리 세워둔 목표를 달성한다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_016",
                                    question: "나는 숙제나 해야 할 일들을 미리미리 해둔다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_017",
                                    question: "나는 누가 참견하지 않아도 스스로 잘 한다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_018",
                                    question: "나는 어떤 일을 하기 전에 미리 생각해보고 계획을 세운다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_019",
                                    question: "나는 계획한 일을 다음 날로 미루지 않는다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_020",
                                    question: "나는 미리 계획을 세울 수 있다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_021",
                                    question: "나는 공부를 잘한다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "A_3_sub02_b",
                            title: "행동 및 정서조절",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "A_3_022",
                                    question: "나는 먼저 대화를 잘 시작한다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_023",
                                    question: "나는 다른 사람의 기분이 어떤지 잘 알아차린다",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_024",
                                    question: "나는 새로운 일을 하는 것이 흥미롭고 재미있다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_025",
                                    question: "나는 심부름을 할 때, 해야 할 일이 무엇이었는지 잘 기억한다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_026",
                                    question: "나는 놀이를 할 때 친구가 새로운 규칙을 제안하면 잘 받아들인다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_027",
                                    question: "나는 어떤 놀이나 활동을 할 때 순서를 잘 지킨다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_028",
                                    question: "나는 내 감정표현을 잘한다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "A_3_029",
                                    question: "나는 내 행동 중 고쳐야 할 점이 무엇인지 알고 있다.",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['매우 아니다', '조금 아니다', '보통이다', '조금 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                    ],
                },
            ]
        },
        {
            taskId: "A_4",
            title: "학습효능감",
            instruction: '문항을 읽고 평소 자신의 모습과 가까운 항목에 표시해주세요.',
            sections: [
                {
                    sectionId: "A_4_sub01",
                    title: "자기조절효능감",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_4_001",
                            question: "나는 어려운 상황을 극복할 수 있는 능력이 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_003",
                            question: "나는 계획을 잘 짤 수 있다. ",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_004",
                            question: "나는 어떤 일의 원인과 결과를 잘 분석할 수 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_005",
                            question: "나는 어떤 일의 원인과 결과를 잘 분석할 수 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_007",
                            question: "나는 항상 큰 문제를 세우고 그것에 비추어서 일의 진행 상태를 확인한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_009",
                            question: "나는 주어진 일을 하기 위해 정보를 충분히 활용할 수 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_010",
                            question: "나는 내가 할 수 있는 일과 그렇지 않은 일을 판단할 수 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_014",
                            question: "어떤 일이 처음에 잘 안되더라도 나는 될 때까지 해본다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_018",
                            question: "어떤 문제에 대한 나의 판단은 대체로 정확하다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_021",
                            question: "나는 일이 잘못되고 있다고 생각되면 빨리 바로 잡는다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_023",
                            question: "나는 어려움이 있을 때도 계속해서 노력한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "A_4_sub02",
                    title: "자신감",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_4_002",
                            question: "나는 위험한 상황에서 잘 대처할 수 없을 것 같아서 불안하다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_006",
                            question: "어떤 일을 시작할 때 실패할 것 같은 느낌이 들곤 한다. ",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_008",
                            question: "나는 큰 문제가 생기면 불안해져서 아무것도 할 수가 없다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_012",
                            question: "주변 사람들이 나보다 모두 일에서 뛰어나다",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_015",
                            question: "나는 위협적인 상황에서는 스트레스를 필요이상으로 많이 받는다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_016",
                            question: "나는 부담스러운 상황에서는 우울함을 느낀다",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_019",
                            question: "나는 어려운 일이 생기면 불안해져서 아무것도 할 수가 없다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "A_4_sub03",
                    title: "과제난이도 선호",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_4_011",
                            question: "어렵거나 도전적인 일에 매달리는 것은 재미있는 일이다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_013",
                            question: "일은 쉬운 것 일수록 좋다",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_017",
                            question: "만일 일을 선택할 수 있다면 나는 어려운 것보다 쉬운것을 선택할 것이다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_020",
                            question: "아주 쉬운 일보다는 차라리 어려운 일을 좋아한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_4_022",
                            question: "좀 실수를 하더라도 어려운 일을 좋아한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
            ]
        },
        {
            taskId: "A_5",
            title: "정서적 준비도",
            instruction: '문항을 읽고 평소 자신의 모습과 가까운 항목에 표시해주세요.',
            sections: [
                {
                    sectionId: "A_5_sub01",
                    title: "외적동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_5_001",
                            question: "나는 수업내용을 그 시간에 이해하기 위해서 선생님의 설명을 열심히 들으려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_003",
                            question: "나는 좋은 성적을 얻기 위해서 열심히 공부하고자 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_004",
                            question: "나는 어떤 교과목이든 높은 점수를 위해 열심히 공부하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_006",
                            question: "나는 나쁜 점수를 받은 교과목이라도 포기하지 않고 좋은 성적을 얻기 위해 열심히 노력한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_007",
                            question: "나는 좋은 성적을 받아 주위 사람들에게 칭찬을 받기 위해 예습, 복습을 열심히 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_008",
                            question: "나는 좋은 성적을 위해 정신을 집중해서 교과내용을 읽고 이해하고자 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_021",
                            question: "나는 좋은 성적을 얻는 것이 장래에 도움이 되기에 모든 교과목을 열심히 공부하고자 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_022",
                            question: "나는 교과목의 내용을 보다 효과있게 공부하는 방법에 대해서 항상 관심을 가지고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "A_5_sub02",
                    title: "내적동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_5_002",
                            question: "나는 숙제가 부담이 되지만 수업내용을 잘 이해하기 위해서 충실하게 작성하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_005",
                            question: "나는 어떤 일이 있더라도 일정량의 복습은 반드시 끝내려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_009",
                            question: "나는 교과목마다 그 학습단원의 목표를 잘 이해하고 공부하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_010",
                            question: "나는 좋은 점수를 받기 위해 내 스스로가 계획한 시간만큼은 열심히 공부하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_011",
                            question: "나는 수업시간에 배운 내용 중 잘 모르는 내용은 누구에게 물어서라도 이해하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_014",
                            question: "나는 피곤하더라도 그날 배운 교과내용을 이해하기 위해서 복습을 반드시 하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_015",
                            question: "나는 수업내용이 그 시간에 이해되었더라도 집에 가서 다시 공부하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_016",
                            question: "나는 학교에서 배운 교과내용을 정신 차려 차분하게 읽고 이해하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "A_5_sub03",
                    title: "수업참여동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_5_012",
                            question: "나는 수업시간에 시험 볼 시간이 발표되면 보통 때보다 더 열심히 공부할 계획을 세운다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_013",
                            question: "나는 수업시간에 배운 내용을 복습할 때, 매쪽마다 이해한 후에 기억하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_018",
                            question: "나는 언제나 먼저 학습한 내용을 이해한 후, 다음 배울 교과내용을 예습하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_019",
                            question: "나는 수업한 내용을 완전히 이해하면 즐겁고 더욱 공부하고자 하는 의욕이 생긴다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "A_5_sub04",
                    title: "지속적동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "A_5_017",
                            question: "나는 학교 밖에서도 수업내용과 관련있는 사항들을 항상 관심을 두고 살피려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_020",
                            question: "나는 수업내용을 보다 잘 이해하기 위해서 학교 밖 여러 사람들과 계속해서 의논하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_023",
                            question: "나는 수업 내용과 관련있는 TV프로, 신문기사, 인터넷, 잡지 등의 내용을 살펴보고자 노력한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_024",
                            question: "나는 학교에서 배운 내용을 잘 이해하고 기억하기 위해서 예습, 복습을 꼭 하려고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "A_5_025",
                            question: "나는 어떤 수업시간이든 새로운 정보와 지식을 이해하기 위해서 관심을 가지고 참여한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
            ]
        },
    ]
}