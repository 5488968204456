import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import TabButton from '../TabButton';
import IconButton from '../IconButton';
import UserMenu from './UserMenu';
import NotificationBoard from './NotificationBoard';
import { logout } from '../../redux/actions/AuthActions';

const notificationIcon = require("../../res/images/ic_notification.png")
const leafIcon = require('../../res/images/ic_leaf.png')
const notificationIconWhite = require("../../res/images/ic_notification_white.png")
const logoutIcon = require('../../res/images/ic_logout.png')
const defaultLogo = require('../../res/images/logo_default.png')
const defaultLogoWhite = require('../../res/images/logo_default_white.png')

const Container = styled.div`
    display: flex;
    width: 200px;
    height : 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
`;

const SignInMenu = (props) => {
    return (
        <Container>
            <IconButton 
                src={props.isTop ? notificationIconWhite : notificationIcon}
                shadow={props.isTop && "1px 0px 3px rgba(255, 255, 255, 0.5)"}
            >
                <NotificationBoard />
            </IconButton>
            <IconButton 
                src={props.isTop ?  defaultLogoWhite : defaultLogo}
                shadow={props.isTop && "1px 0px 3px rgba(255, 255, 255, 0.5)"}
            >
                <UserMenu
                    name={props.auth.name}
                    email={props.auth.email}
                    photoUrl={defaultLogo}
                >
                    <TabButton
                        to="/admin/users"
                        src={leafIcon}
                        title="MY브레인웨이"
                    />
                    <TabButton
                        src={logoutIcon}
                        title="로그아웃"
                        to={"/"}
                        onClick={() => {
                            props.logout();
                        }}
                    />
                </UserMenu>
            </IconButton>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInMenu);
