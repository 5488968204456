const initState = {
  authError: null
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      console.log('login success');
      return {
        ...state,
        authError: null
      };

    case 'LOGIN_ERROR':
      console.log('login failed');
      return {
        ...state,
        authError: action.err.message
      };

    case 'LOGOUT_SUCCESS':
      return state;

    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        authError: null
      };

    case 'SIGNUP_ERROR':
      return {
        ...state,
        authError: action.err.message
      };

    default:
      return state;
  }
};

export default AuthReducer;
