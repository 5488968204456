import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    box-shadow: 1px 0px 5px rgba(0,0,0,0.3);
    padding: 20px 30px 20px 0px;
    margin: 5px 5px 5px 5px;
    border-radius: 8px;

  @media (max-width: 768px){
    margin: 5px 5px 5px 5px;
    padding: 20px 30px 20px 0px;
    min-height: 180px;
  }

  @media (max-width: 415px){
    min-height: 0px;
    padding: 14px 14px;
    margin: 0px 0px 10px 0px;
    flex-direction: row;
    border-radius: 4px;
  }

`;

const ImageContainer = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 768px){
      width: 200px;
  }
  
  @media (max-width: 415px){
      width: 80px;
  }
`;

const Image = styled.img`
    object-fit: contain;
    height: 80px;
    height: 80px;
  @media (max-width: 415px){
    width: 50px;
    height: 50px;
  }
`;
const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Title = styled.span`
  font-family: 'GodoB';
  font-size: 24px;
  color: black;
  text-align: left;
  margin: 14px 0px;

  @media (max-width: 768px){
    font-size: 22px;
    line-height: 36px;
    text-align: left;
    margin: 5px 0px;
  }

  @media (max-width: 415px){
    width: 100%;
    margin: 0px;
    font-size: 22px;
    line-height: 40px;
    text-align: left;
    padding: 0px 20px;
  }

`;

const Description = styled.span`
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 16px;
  color: black;
  text-align: left;
  line-height: 26px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 415px){
    display: none;
  }
`;

const PathwayButton = (props) => (
    <Container {...props}>
        {props.imageName && <ImageContainer>
            <Image
                height={props.imgHeight}
                src={require(`../../res/images/${props.imageName}.png`)}
                srcSet={`${require(`../../res/images/${props.imageName}@2x.png`)} 2x, ${require(`../../res/images/${props.imageName}@3x.png`)} 3x`}
            />
        </ImageContainer>
        }
        <ContentContainer>
            {props.name && <Title>{props.name}</Title>}
            {props.desc && <Description>{props.desc}</Description>}
        </ContentContainer>
    </Container>
);

export default PathwayButton;
