import React from 'react';
import styled from 'styled-components'
import COLORS from '../../values/Colors';

const ImageMap = require('../../res/images/img_map.jpg')
const ImageQRCode = require('../../res/images/img_qr_code.jpg')

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const SubContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 10px;

    @media (max-width: 415px){
        padding: 15px 10px
    }
`;

const Image = styled.img`
    object-fit: contain;
    margin: 10px;
    align-self: center;
`;

const Title = styled.text`
  max-width: 595px;
  font-size: 26px;
  text-align: center;
  font-family: 'GodoB';
  line-height: 50px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 22px;
    line-height: 36px;
  }

  @media (max-width: 415px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Text = styled.text`
  max-width: 595px;
  font-size: 21px;
  text-align: left;
  color: ${(props) => props.theme.text};
  line-height: 40px;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
  }
  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
  }
`;

const TextWithMarginBottom = styled(Text)`
    margin-bottom: 20px;
`;


const TextGreen = styled(Text)`
    color: #84D89D;
    font-weight: 800;
    margin-bottom: 20px;
`;

const TextBlue = styled(Text)`
    color: #84B2D8;
    font-weight: 800;
    margin-bottom: 20px;
`;

const Em = styled.em`
    font-weight: 800;
`;

const Link = styled.a`
    max-width: 595px;
  font-size: 21px;
  text-align: left;
  color: ${COLORS.primary};
  line-height: 40px;
  font-family: 'Nanum Gothic', sans-serif;
  font-weight: 800;
  text-decoration: underline;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
  }
  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
  }
`;

const Map = styled.img`
    width: 100%;
`;


const BrainwayLocation = (props) => {
    return <Container>
        <SubContainer>
            <Title>예약</Title>
            <TextWithMarginBottom>1) 네이버에서 '<Em>브레인웨이</Em>'를 검색하시면, 지도 하단 <Em>예약</Em>버튼을 통해 예약이 가능합니다.</TextWithMarginBottom>
            <TextWithMarginBottom>2) 이메일(<Em>admin@brainway.co.kr</Em>)이나 카카오톡 친구(<Em>브레인웨이</Em>) 추가를 통해서도 검사에 대한 문의 및 예약이 가능합니다.</TextWithMarginBottom>
            <TextWithMarginBottom>3) 자세한 예약 방법은 <Em>카카오 채널</Em>을 통해 확인해 주세요.</TextWithMarginBottom>
            <TextWithMarginBottom>4) 예약 안내 받으신 일시에 센터에 방문하시면 검사가 진행됩니다.</TextWithMarginBottom>
            <TextWithMarginBottom>5) 검사 종료 후 진행한 검사에 대한 결과보고서를 받아보실 수 있으며(이메일 혹은 우편발송), 예상 수령 기간은 <Em>검사일로부터 약 7-10일</Em> 정도 소요됩니다.</TextWithMarginBottom>
            <Text style={{ margin: '20px 0px 50px 0px' }}>* 카카오톡 채널은 ‘<Em>브레인웨이</Em>’로 검색하시거나, <Em>QR 코드</Em>를 통해 추가 하실 수 있습니다.</Text>
            <Image style={{ width: '120px' }} src={ImageQRCode} />
        </SubContainer>

        <SubContainer>
            <Title>방문</Title>
            <table
                cellpadding="0"
                cellspacing="0"
                width="100%"
            >
                <tr>
                    <td style={{ border: '1px solid #cecece' }}>
                        <a href={"https://v5.map.naver.com/?searchCoord=90a5938c94a834c1d15112239aa993a76f1c50d40e14f76d8e9f85466b1468c8&query=67iM66CI7J247Juo7J20&tab=1&lng=ddc8003219f01423938dc46526f4a87c&mapMode=0&mpx=2b24a3fc54ec918d51c6c614651a5651fc907b9b883c0adf8f4b690e0f83709e8c99602fa4ba0b874951f94ca27cf49825660a81fb8c856b4e4d7fa038dc69e6&lat=4c53405759ca1c3a86146c51e3d4b9e5&dlevel=12&enc=b64&menu=location"} target="_blank">
                            <Map src="http://prt.map.naver.com/mashupmap/print?key=p1601385864683_596110591" alt="지도 크게 보기" title="지도 크게 보기" border="0" style={{ verticalAlign: 'top' }} />
                        </a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" width="100%">
                            <tr>
                                <td height="30" bgcolor="#f9f9f9" align="left" style={{ paddingLeft: '9px', borderLeft: '1px solid #cecece', borderBottom: '1px solid #cecece' }}>
                                    <span style={{ fontFamily: 'tahoma', fontSize: '11px', color: '#666' }}>2020.9.29</span>&nbsp;<span style={{ fontSize: '11px', color: '#e5e5e5' }}>|</span>&nbsp;
                                    <a style={{ fontFamily: 'dotum,sans-serif', fontSize: '11px', color: '#666', textDecoration: 'none', letterSpacing: '-1px' }} href={"https://v5.map.naver.com/?searchCoord=90a5938c94a834c1d15112239aa993a76f1c50d40e14f76d8e9f85466b1468c8&query=67iM66CI7J247Juo7J20&tab=1&lng=ddc8003219f01423938dc46526f4a87c&mapMode=0&mpx=2b24a3fc54ec918d51c6c614651a5651fc907b9b883c0adf8f4b690e0f83709e8c99602fa4ba0b874951f94ca27cf49825660a81fb8c856b4e4d7fa038dc69e6&lat=4c53405759ca1c3a86146c51e3d4b9e5&dlevel=12&enc=b64&menu=location"} target="_blank">지도 크게 보기</a>
                                </td>
                                <td width="98" bgcolor="#f9f9f9" align="right" style={{ textAlign: 'right', paddingRight: '9px', borderRight: '1px solid #cecece', borderBottom: '1px solid #cecece' }}>
                                    <span style={{ float: "right" }}>
                                        <span style={{ fontSize: '9px', fontFamily: 'Verdana, sans-serif', color: '#444' }}>&copy;&nbsp;</span>&nbsp;
                                        <a style={{ fontFamily: 'tahoma', fontSize: '9px', fontWeight: 'bold', color: '#2db400', textDecoration: 'none' }} href={"https://www.navercorp.com"} target="_blank">NAVER Corp.</a>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

            <Text style={{marginTop: '10px'}}>제주시 승천로 57, 3층 브레인웨이</Text>
        </SubContainer>

        <SubContainer>
            <Title>전화</Title>
            <Link href="tel:+821020131701">010-2013-1701</Link>
        </SubContainer>

        <SubContainer>
            <Title>지선</Title>
            <Text>승천로, 영산홍아파트 방면</Text>
            <TextGreen>426(국제부두) 422(영평하동) 422(제주대) 440(한라수목원)</TextGreen>
            <Text>승천로, 탐라중학교 방면</Text>
            <TextGreen>421(영평하동) 421(제주대) 426(국제대) 440(봉개)</TextGreen>
            <Text>혜성무지개타운, 한마음근린공원 방면</Text>
            <TextGreen>415(한라수목원)(동광양-한라수목원) 415(국제부두)</TextGreen>
            <Text>혜성무지개타운, 제주제일중학교 방면</Text>
            <TextGreen>415(한라수목원)(국제여객선터미널-한라수목원)</TextGreen>
        </SubContainer>

        <SubContainer>
            <Title>간선</Title>
            <Text>혜성무지개타운, 한마음근린공원 방면</Text>
            <TextBlue>346(도련초)</TextBlue>
            <Text>혜성무지개타운, 제주제일중학교 방면</Text>
            <TextBlue>346(영주고)</TextBlue>
            <Text>탐라중학교, 제주영지학교 방면</Text>
            <TextBlue>320(수산리) 345(제주대) 346(영주고) 347(월평)</TextBlue>
            <Text>탐라중학교, 한일베라체 방면</Text>
            <TextBlue>320(삼화지구) 346(영주고) 347(월평)</TextBlue>
        </SubContainer>

        <SubContainer>
            <Title>정류장</Title>
            <Text>승천로, 혜성무지개타운, 탐라중학교</Text>
        </SubContainer>
    </Container>
}

export default BrainwayLocation