import React, { Component } from 'react';
import styled from 'styled-components';
import CheckBox from './CheckBox';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px 10px 10px;
  border-radius: 10px;

  &:hover{
    background-color: rgba(0,0,0,0.03)
  }

  @media (max-width: 768px) {
    width: 100%;
    border: 0px;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 10px 20px 10px;
  }

  @media (max-width: 415px){
    flex-direction: row;
    align-items: center;
    padding: 14px;
    background-color: ${props => props.isSelected ? "rgba(0,0,0,0.04)" : "transparent"}; 
  }
`;

const Text = styled.span`
  display: block;
  font-size: 18px;
  text-align: center;
  color: black;
  margin: 0px 0px 0px 10px;
  cursor: pointer;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 768px){
    font-size: 16px;
    line-height: 20px;
    margin: 5px 0px 0px 0px;

  }

  @media (max-width: 415px){
    font-size: 15px;
    padding-bottom: 3px;
    margin: 0px 0px 0px 10px;
  }
`;

class ChoiceButton extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.isSelected !== this.props.isSelected;
  }

  render() {
    const { value, text, onSelected, isSelected, radius } = this.props;
    return (
      <Container isSelected={isSelected} onClick={() => onSelected({ value, text })}>
        <CheckBox radius={radius} isSelected={isSelected}/>
        <Text {...this.props}>{text}</Text>
        {/* {text.split("\n").map((i, key) => (
                <Text key={key}>{i}</Text>
            ))} */}
      </Container>
    );
  }
}

export default ChoiceButton;
