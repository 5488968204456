import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { createNotice } from '../../../redux/actions/NoticeActions';

class NoticeForm extends Component {
    state = {
        notice: {}
    }

    componentDidMount() {
        console.log(this.props.notice);
    }

    handleCreate = (notice) => {
        this.props.createNotice(notice)
        // Go to notice detail
    }

    /**
     * 1. Title
     * 2. Description
     * 3. Image Upload
     */
    render() {
        return (
            <div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    if (id) {
        const notices = state.firestore.data.notices;
        const notice = notices ? notices[id] : null
        return {
            notice: notice
        }
    }
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createNotice: (notice) => dispatch(createNotice(notice))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{ collection: 'notices' }])
)(NoticeForm)