const initState = {};

const ReportReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPLOAD_REPORT_SUCCESS':
      console.log("UPLOAD_REPORT_SUCCESS")
      return {
        ...state,
        authError: null
      };

    case 'UPLOAD_REPORT_FAILED':
      console.log("UPLOAD_REPORT_FAILED")
      return {
        ...state,
        authError: action.err.message
      };
    default:
      return state;
  }
};

export default ReportReducer;