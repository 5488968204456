const FOREST = {
  section1: {
    title: '우리 ‘I’의 뇌인지성향과\n심리발달을 알아봅니다',
    subtitle:
      "우리 'I'를 좀더 잘 이해하기 위한 길입니다. 자녀의 뇌인지성향과 심리발달에 대한 파악을 위해 Brainway만의 과학적인 ‘개인 맞춤형 분석’ 성장의 숲을 방문해 보세요."
  },
  section2: {
    title: '밝고 사랑스런\n우리 ‘I’(아이)는 지금?',
    subtitle:
      `우리'I'의 자기탐색에 필요한 요소들을 파악하고 전문 연구진의 맞춤형 분석결과를 받아볼 수 있습니다. 자기 탐색 결과에 대해 전문적이고 통합적인 분석을 진행해보세요!`,
    points: [
      '인지적 특성 파악을 통한 적성 탐색',
      '인지적 강점/약점/잠재력 기반 개인별 맞춤 학업 및 진로 코칭',
      '자기탐색을 통한 자존감 향상',
      '학업 및 대인관계에서의 적응 능력 강화'
    ]
  },
  section3: {
    title: '우리 ‘I’ 발달, 어떤 측면에서\n살펴봐야 할까요?',
    subtitle:
      '성장의 숲에서는 아이의 뇌활성화 분석을 통한 인지적 특성, 정서적 성향, 또래관계 및 가족관계 등과 같이 유기적으로 연결 되어있는 다양한 환경적, 유전적 요인을 고려하여 종합적으로 해석합니다.'
  },
  section4: {
    title: "우리 'I' 발달의 핵심, 뇌!",
    subtitle:
      'Brainway에서는 뇌인지기능 특성에 대한 과학적 측정을 통해 개인에게 특화된 전문적 분석을 제공합니다.',
    paragraph:
      'Brainway 센터에서는 qEEG, fNIRS, Eyetracker 등 뇌과학, 의학, 인지신경심리학 분야에서 사용되어온 과학적 측정을 통해 다양한 데이터를 기반으로 뇌 발달 수준과 성향을 평가하고 진단합니다!'
  },
  section5: {
    title: '성장의 숲 소개',
    intro: [
      {
        title: '인지의 길',
        icon: 'bg_cognition_intro',
        desc:
          '신경심리 분석을 통한 개인의 인지적 특성, 강점 및 약점 파악을 기반으로 학습 성향과 적성을 탐색합니다.'
      },
      {
        title: '학습의 길',
        icon: 'bg_learning_intro',
        desc:
          '자녀의 학업 준비도와 성향을 탐색하고 개인에 가장 적합한 올바른 학습 습관과 환경에 대한 컨설팅을 제공해 드립니다. '
      },
      {
        title: '소통의 길',
        icon: 'bg_communication_intro',
        desc:
          '부모와 자녀의 기질 및 성격적인 성향, 그리고 관계적인 특성을 파악하여, 건강한 부모-자녀 관계를 위한 효율적인 맞춤형 소통에 대한 코칭을 제공해 드립니다. '
      },
      {
        title: '힐링의 길',
        icon: 'bg_healing_intro',
        desc:
          '개인과 가족의 정신건강에 대한 종합적인 분석 및 제안을 통해 ‘나’와 ‘우리’를 더 이해하는 시간을 제공합니다.'
      },
      {
        title: '브레인 뉴로 사이언스 랩',
        icon: 'bg_neuroscience_intro',
        desc:
          'qEEG, fNIRS, Eyetracker 등의 측정과 분석의 뇌과학적 방법을 통해, 우리 ‘I’의 뇌인지성향과 발달에 대해 파악해 봅니다.'
      }
    ]
  }
};

export default FOREST;
