import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { tada } from 'react-animations';

const PinImage = require('../../res/images/img_location_pin.png');
const PinShadow = require('../../res/images/img_location_pin_shadow.png');

const PathToTreeImage = require('../../res/images/bg_path_to_tree.png');
const CogImage = require('../../res/images/img_cognition.png');
const ComImage = require('../../res/images/img_communication.png');
const LrnImage = require('../../res/images/img_learning.png');
const HlgImage = require('../../res/images/img_healing.png');
const NeuImage = require('../../res/images/img_neuroscience.png');

const bounceAnim = keyframes`${tada}`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;

  @media (max-width: 768px){
    display: none;
  }
`;

const Path = styled.div`
  width: 100%;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const PathRow = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
`;

const PathColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Pin = styled(Link)`
  width: 500px;
  height: 400px;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    animation: 1s ${bounceAnim};
  }
`;

const Image = styled.img`
  height: 160px;
`;

const Shadow = styled.img`
  width: 160px;
  object-fit: contain;
  margin-top: 20px;
`;

const Title = styled.span`
  height: 65px;
  font-family: 'GodoB';
  font-size: 32px;
  color: white;
  padding: 0px 50px 0px 50px;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MidPin = styled(Pin)`
  width: 200px;
  height: 210px;
`;

const MidImage = styled(Image)`
  height: 80px;
`;

const MidShadow = styled(Shadow)`
  width: 80px;
  margin-top: 10px;
`;

const MidTitle = styled(Title)`
  height: 50px;
  font-size: 20px;
  padding: 0px 30px 0px 30px;
  margin-top: 10px;
`;

const TopPin = styled(Pin)`
  width: 100px;
  height: 150px;
`;

const TopImage = styled(Image)`
  height: 60px;
`;

const TopShadow = styled(Shadow)`
  width: 60px;
  height: 5px;
  margin-top: 0px;
`;

const TopTitle = styled(Title)`
  height: 30px;
  font-size: 11px;
  padding: 0px 10px 0px 10px;
  margin-top: 5px;
`;

class PathToTree extends Component {
  render() {
    return (
      <Container>
        <Path bgImage={PathToTreeImage}>
          <PathRow>
            <PathColumn style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <PinContainer style={{ marginTop: '100px' }}>
                <MidPin bgImage={PinImage} to="/forest/communication">
                  <MidImage src={ComImage} />
                  <MidTitle>소통의 길</MidTitle>
                </MidPin>
                <MidShadow src={PinShadow} />
              </PinContainer>
              <PinContainer style={{ alignSelf: 'flex-start', marginTop: '20px', marginBottom: '200px'}}>
                <Pin bgImage={PinImage} to="/forest/cognition">
                  <Image src={CogImage} />
                  <Title>인지의 길</Title>
                </Pin>
                <Shadow src={PinShadow} />
              </PinContainer>
            </PathColumn>
            <PathColumn style={{ background: 'transparent' }}>
              <PinContainer>
                <TopPin bgImage={PinImage} to="/forest/neuroscience">
                  <TopImage src={NeuImage} />
                  <TopTitle>뉴료사이언스 랩</TopTitle>
                </TopPin>
                <TopShadow src={PinShadow} />
              </PinContainer>
            </PathColumn>
            <PathColumn>
              <PinContainer style={{ marginTop: '40px' }}>
                <MidPin bgImage={PinImage} to="/forest/healing">
                  <MidImage src={HlgImage} />
                  <MidTitle>힐링의 길</MidTitle>
                </MidPin>
                <MidShadow src={PinShadow} />
              </PinContainer>
              <PinContainer>
                <Pin bgImage={PinImage} to="/forest/learning">
                  <Image src={LrnImage} />
                  <Title>학습의 길</Title>
                </Pin>
                <Shadow src={PinShadow} />
              </PinContainer>
            </PathColumn>
          </PathRow>
        </Path>
      </Container>
    );
  }
}

export default PathToTree;
