export const Communication = {
    surveyId: "C",
    title: "소통의 길",
    instruction: "",
    tasks: [
        {
            taskId: "C_0",
            title: "기본 면담지",
            instruction: '문항을 읽고 자녀(나)와 유사한 정도를 항목에 표시해 주세요.',
            sections: [
                {
                    sectionId: "C_0_sub01",
                    title: "주호소영역 1",
                    questions: [
                        {
                            questionId: "C_0_001",
                            question: "귀하께서 요즘 어떤 부분에 대해 스트레스를 느끼시나요?",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '부정적 정서(우울, 불안, 스트레스)',
                                    '자녀와의 관계',
                                    '부부 관계',
                                    '신체증상 및 수면(건강)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "C_0_sub01_a",
                    title: "주호소영역 1-1",
                    questions: [
                        {
                            questionId: "C_0_002",
                            question: "걱정되거나 염려되는 부분이 평균적으로 얼마나 지속되었나요?",
                            options: {
                                value: [0, 1, 2],
                                texts: [
                                    '1-3주 정도',
                                    '1-3개월 정도',
                                    '4개월 이상'
                                ],
                                type: "multiple_choice"
                            }
                        },
                    ]
                },
                {
                    sectionId: "C_0_sub01_b",
                    title: "주호소영역 1-2",
                    questions: [
                        {
                            questionId: "C_0_003",
                            question: '이러한 부분이 귀하에게 미치는 부정적인 영향은 어느 정도인가요?',
                            options: {
                                images: [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                ],
                                texts: [
                                    "10%",
                                    "30%",
                                    "50%",
                                    "70%",
                                    "100%"
                                ],
                                additional: [
                                    "약간 신경이 쓰인다.",
                                    "",
                                    "대체로 신경 쓰인다.",
                                    "",
                                    "매우 많이 신경 쓰인다.\n(잠을 잘 못잔다, 친구관계가 안좋아 졌다, 가족관계가 안좋아졌다, 식욕이 줄었다, 학교생활을 힘들어 한다 등)"
                                ],
                            }
                        },
                    ]
                },
                {
                    sectionId: "C_0_sub01_c",
                    title: "주호소영역 1-3",
                    questions: [
                        {
                            questionId: "C_0_004",
                            question: '검사를 신청하게 된 이유는 무엇인가요?',
                            options: {
                                type: 'short_answer'
                            }
                        },
                    ]
                },
                {
                    sectionId: "C_0_sub02",
                    title: "나에 대해 알아보기",
                    instruction: "아래에 적혀있는 문장을 보면 빈칸이 있습니다. 각 문장을 읽으면서 제일 먼저 떠오르는 생각을 빈칸에 적어주세요. 시간제한은 없으나 될 수 있는 대로 빨리 작성해 주세요. 만약 문장을 완성할 수 없으면 표시를 해두었다가 나중에 완성하도록 해주세요.",
                    questions: [
                        {
                            questionId: "C_0_005",
                            options: {
                                prefix: "나는",
                                suffix: "할 때 화가 난다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "C_0_006",
                            options: {
                                prefix: "나는",
                                suffix: "에 대해서 걱정을 한다.",
                                type: "fill_in_blank",
                            }

                        },
                        {
                            questionId: "C_0_007",
                            options: {
                                prefix: "나의 좋은 점은",
                                suffix: "이다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "C_0_008",
                            options: {
                                prefix: "내가 생각하기에는 나는",
                                suffix: "점들을 좀 고쳐야 할 것 같다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "C_0_009",
                            options: {
                                prefix: "결혼 생활에 대해서는 나는",
                                suffix: "하다고 생각한다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "C_0_010",
                            options: {
                                prefix: "내가 정말 행복하려면",
                                suffix: "해야 한다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "C_0_011",
                            options: {
                                prefix: "나를 괴롭히는 것은",
                                suffix: "이다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "C_0_012",
                            options: {
                                prefix: "내가 가장 좋아하는 사람은",
                                suffix: "이다.",
                                type: "fill_in_blank_with_reason",
                            }
                        },
                        {
                            questionId: "C_0_013",
                            options: {
                                prefix: "내가 가장 싫어하는 사람은",
                                suffix: "이다.",
                                type: "fill_in_blank_with_reason",
                            }
                        },
                        {
                            questionId: "C_0_014",
                            options: {
                                prefix: "나는",
                                suffix: "한 부모라고 생각한다.",
                                type: "fill_in_blank",
                            }
                        },
                    ]
                },
            ]
        },
        {
            taskId: "C_1",
            title: "부모 양육태도",
            instruction: '다음의 문항들은 부모로서 자신을 어떻게 생각하는지 알고자 하는 것입니다. 자녀를 염두에 두고 문항을 읽어가면서, 부모로서 자신의 생각이나 느낌과 가까운 곳에 표시해 주시기 바랍니다.',
            sections: [
                {
                    sectionId: "C_1_sub01",
                    title: "거부적 태도",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_1_001",
                            question: "학부모님께서는 자녀에 대해 별로 아는 것이 없다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_002",
                            question: "학부모님께서는 어린이의 요구를 무시하는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_011",
                            question: "학부모님께서는 자녀로 인해 속상하고 화나는 일이 많으십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_012",
                            question: "자녀가 속을 썩이는 행동을 할 때 학부모님께서는 참으려 하다가 와락 성을 내거나 큰소리를 치는 때가 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_013",
                            question: "학부모님께서는 자녀의 행동 및 태도에 고쳐야 할 점이 많다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_015",
                            question: "학부모님께서는 자녀 양육이 즐거울 때보다는 짐스러울 때가 많다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_038",
                            question: "학부모님께서는 자녀를 양육하려면 어쩔 수 없이 근심 걱정이 생긴다고 이야기 하신 적이 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_039",
                            question: "학부모님께서는 자녀가 클 때까지 다른 사람이 좀 키워주었으면 좋겠다는 생각을 하실 때가 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_040",
                            question: "학부모님께서는 직장 일을 하다가 권태롭거나 지겹다는 생각을 하실 때가 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_041",
                            question: "다른 사람이 학부모님을 대할 때 어려워하거나 까다로운 면이 있다고 하는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_042",
                            question: "학부모님께서는 자녀가 잘못을 했을 경우 일부러 쌀쌀맞게 대하고 따끔하게 이야기하는 편입니까/",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ],
                },
                {
                    sectionId: "C_1_sub02",
                    title: "애정적 태도",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_1_003",
                            question: "학부모님께서는 자녀들이 놀고 있을 때 허물없이 끼어들어 어린이들이 노는 방식대로 놀아주십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_004",
                            question: "학부모님께서는 자녀들이 행동이 자랑스럽게 느껴지고 또 칭찬도 하시는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_014",
                            question: "학부모님께서는 자녀와 함께 보내는 시간을 즐거워 하시는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_016",
                            question: "학부모님께서는 자녀들이 무언가 해낼 것이라는 기대감을 강하게 갖고 계십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_017",
                            question: "학부모님께서는 자녀를 존중하고 신뢰하며 허물없이 이야기를 나누는 편이십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_019",
                            question: "학부모님께서는 자녀와 보내는 시간을 많이 갖는 편이라고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_029",
                            question: "학부모님께서는 자녀에게 애정 표현을 겉으로 잘 하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_030",
                            question: "학부모님께서는 자녀의 행동이나 자녀가 성취해낸 일(그림, 만들기 등)에 늘 관심을 갖는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_031",
                            question: "학부모님께서는 자녀를 슬하에 둔 것이 퍽 기쁘다고 자녀에게 말해주십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_032",
                            question: "학부모님께서는 어린이가 보이는 흥미, 관심거리에 대해서 자녀와 이야기를 자주 하여 도움을 주십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_033",
                            question: "자녀에게 문제되는 행동이 있을 때 거리낌 없이 다른 사람에게 의논하고 도움을 받으십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_034",
                            question: "학부모님께서는 자녀의 요구를 다 들어주는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ],
                },
                {
                    sectionId: "C_1_sub03",
                    title: "통제적 태도",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_1_005",
                            question: "자녀가 버릇없이 굴 때 학부모님께서는 벌을 주겠다고 엄포 놓으신 적이 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_006",
                            question: "자녀들은 부모의 말에 절대적으로 순종해야 한다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_007",
                            question: "자녀의 행동이 바르게 자라려면 부모를 어렵게 알고 두려워 할 줄 알아야 한다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }, {
                            questionId: "C_1_023",
                            question: "학부모님께서는 자녀들에게 '장손', '맏딸' 혹은 집안의 귀중한 존재라는 것을 깨닫게 하려고 노력하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_024",
                            question: "학부모님께서는 자녀들이 생각하는 것이나 말하고자 하는 것은 무엇이든 부모님께서 알고 있어야 제대로 교육할 수 있다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_025",
                            question: "가정 내의 질서를 유지하기 위해 규칙과 규율을 많이 설정해야 한다고 학부모님께서는 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_026",
                            question: "학부모님께서는 자녀의 학교 성적에 관심을 갖고 자주 살펴보십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_027",
                            question: "학부모님께서는 자녀가 되도록이면 사회적으로 성공했으면 싶으십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_028",
                            question: "학부모님께서는 자녀에게 되도록이면 원대한 꿈과 포부를 갖도록 격려하여 보다 더 성공할 수 있게끔 격려해주신 적이 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_035",
                            question: "학부모님께서는 어린이가 늘 놀거나 공부할 때 되도록 집안에서 하도록 하시는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_036",
                            question: "학부모님께서는 어린이 마음대로 하게 되면 버릇 들이기가 힘들게 되기 때문에 표현이나 움직임을 엄격히 제한해야 할 필요가 있다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_037",
                            question: "학부모님께서는 자녀들이 사귀는 친구와 자녀들이 하는 말을 잘 보살펴서 나쁜 친구나 나쁜 일에 빠지지 않도록 적극 도와주시는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ],
                },
                {
                    sectionId: "C_1_sub04",
                    title: "자율적 태도",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_1_008",
                            question: "학부모님의 도움을 거절하고 자녀 혼자 무얼 하겠다고 할 때 그냥 내버려두시겠습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_009",
                            question: "학부모님께서는 자녀 스스로 혼자 행동하는 것을 관대하게 봐주시는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_010",
                            question: "자녀가 집이나 부모님을 떠나 혼자 행동하는 것을 관대하게 봐 주시는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_018",
                            question: "학부모님께서는 경제, 정치, 사회 문제 및 여러 방면에대해 폭 넓은 흥미를 갖고 계십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_020",
                            question: "학부모님께서는 자녀 양육에 대한 지식을 넓히기 위해 독서도 하고 강연회 등에 참석하여 사람들에게 묻기도 하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_021",
                            question: "학부모님께서는 자녀의 정서적 특성이나 성격이 바람직하다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_022",
                            question: "학부모님께서는 자녀가 다른 어린이 또는 어른과 잘 사귄다고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_043",
                            question: "자녀가 속을 썩이는 행동을 보일 때 학부모님께서는 자녀의 행동을 못본 체 할 수 없어 야단을 치거나 비평을 하시는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_044",
                            question: "학부모님께서는 일일이 돌봐주지 않고 자기 혼자서 늘 자리를 찾아서 놀게 하는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_045",
                            question: "학부모님께서는 어린이 마음대로 행동하도록 자유를 주실 용의가 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_046",
                            question: "학부모님께서는 자녀가 할 수 있다면 힘든 일도 안쓰럽지만 혼자서 하라고 하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_047",
                            question: "학부모님께서는 자녀의 잘못된 행동을 꼬집어내지 않는 편입니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_1_048",
                            question: "학부모님께서는 사물에 대해 판단을 잘 하고 이해도 빠른 편이라고 생각하십니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ],
                },
            ]
        },
        {
            taskId: "C_2",
            title: "부모 효능감",
            instruction: '다음의 문항들은 부모로서 자신을 어떻게 생각하는지 알고자 하는 것입니다. 자녀를 염두에 두고 문항을 읽어가면서, 부모로서 자신의 생각이나 느낌과 가까운 곳에 표시해 주시기 바랍니다.',
            sections: [
                {
                    sectionId: "C_2_sub01",
                    title: "부모로서의 효능감",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_2_001",
                            question: "나는 나의 행동이 아이에게 어떻게 영향을 미치는지 잘 알고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_002",
                            question: "나는 아이를 돌보는 데 있어 유능하다고 생각한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_003",
                            question: "나는 아이가 무엇을 힘들어 하는지 누구보다도 잘 알고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_007",
                            question: "나는 다른 사람들이 나로부터 좋은 부모 역할을 배울 수 있는 모델이라고 생각한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_008",
                            question: "나는 아이와의 관계에서 생기는 문제를 잘 다룬다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_009",
                            question: "나는 아이가 나를 좋은 부모라고 보는 지에 대해 자신이 없다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_010",
                            question: "나는 부모로서 아무것도 이룬 것이 없다고 느낀다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_011",
                            question: "나는 아이가 잘못했을 때, 아이 자신이 잘못한 점을 깨달을 수 있도록 잘 설명하고 지도한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_012",
                            question: "나는 부모로서 해야 할 일을 잘하고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_013",
                            question: "나의 흥미와 관심은 부모역할이 아닌 다른 분야에 있다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_014",
                            question: "내가 부모가 되는 것에 조금이라도 더 흥미가 있다면, 나는 지금보다도 좀 더 나은 부모가 될 수 있을 것이다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_015",
                            question: "나는 좋은 부모가 되는데 필요한 지식과 방법을 잘 알고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ],
                },
                {
                    sectionId: "C_2_sub02",
                    title: "부모로서의 좌절감과 불안감",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_2_004",
                            question: "나는 아이가 현재 보이고 있는 수준이 그 나이에는 그럴 수 밖에 없다는 것을 알면서도 짜증이 난다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_005",
                            question: "내가 아이를 가르치고 이끌어 주려고 해도, 아이가 내 뜻대로 잘 따라오지 않아 좌절감을 느낀다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_006",
                            question: "나는 좋은 부모가 될 수 없을 것 같아 걱정이다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_2_016",
                            question: "부모로서 나는 긴장하고 있으며 불안하다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
            ]
        },
        {
            taskId: "C_3",
            title: "부모 스트레스",
            instruction: '다음의 문항들은 부모로서 자신을 어떻게 생각하는지 알고자 하는 것입니다. 자녀를 염두에 두고 문항을 읽어가면서, 부모로서 자신의 생각이나 느낌과 가까운 곳에 표시해 주시기 바랍니다.',
            sections: [
                {
                    sectionId: "C_3_sub01",
                    title: "부모의 고통",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_3_001",
                            question: "나는 내가 일을 잘 처리한다고 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_002",
                            question: "나는 부모로서의 책임감에 얽매여 있지는 않다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_003",
                            question: "내 생활에 방해가 되는 일들이 꽤 많이 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_004",
                            question: "아이가 생기고 나서, 남편과의 관계에 예상 외로 많은 문제가 일어나고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_005",
                            question: "나는 외롭고 친구가 없다는 생각이 든다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_006",
                            question: "이 아이를 가진 뒤에도 나는 하고 싶은 일을 하고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_007",
                            question: "나는 세상일에 관심도 있고 재미도 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_008",
                            question: "나는 내 아이를 위해 내 삶의 많은 부분을 생각보다 많이 포기하고 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_009",
                            question: "나는 예전과는 달리 다른 사람에 대해 관심이 없다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_010",
                            question: "모임에 참석할 때마다 나는 재미없을 거라 생각을 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ]
                },
                {
                    sectionId: "C_3_sub02",
                    title: "자녀와의 역기능적인 상호작용",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_3_011",
                            question: "나는 우리 아이한테 좋은 부모라고 생각한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_012",
                            question: "아이느 나를 기쁘게 하는 일을 잘한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_013",
                            question: "아이는 나를 좋아하며, 나와 가까이 있고 싶어 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_014",
                            question: "아이는 내가 기대하는 것보다 나를 보고 잘 웃지 않는다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_015",
                            question: "아이는 때때로 나를 귀찮게 하려고 일을 저지른다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_016",
                            question: "아이는 내 기대보다 새로운 것을 배우는 속도가 느린 것 같다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_017",
                            question: "아이는 내 기대만큼 공부를 잘 하지 못한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_018",
                            question: "나는 아이에 대해 좀 더 친미라혹 따뜻한 감정을 갖고 싶지만, 실재로 그러지 못하기 때문에 괴롭다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_019",
                            question: "아이는 자기 일을 스스로 하는 편이 아니라서 결국은 내가 관여하게 만든다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_020",
                            question: "내가 아이를 위해 어떤 일을 해주면 아이는 나으 ㅣ수고에 대해 고마움을 아는 것 같다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ]
                },
                {
                    sectionId: "C_3_sub03",
                    title: "자녀의 까다로운 기질",
                    instruction: "",
                    questions: [
                        {
                            questionId: "C_3_021",
                            question: "아이에게 규칙적인 취침과 식습관을 가르치는 일은 그렇게 힘들지 않았다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_022",
                            question: "아이에게 어떤 일을 시키거나 중지시키기는 어렵지 않다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_023",
                            question: "아이는 아침에 잘 일어나며 기분도 괜찮아 보인다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_024",
                            question: "아이는 성가신 행동(부산하고 산만하다, 운다, 싸운다, 빈둥거린다, 등)을 하는 편이다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_025",
                            question: "아이는 자기가 하기 싫어하는 일이 생기면 심하게 반발한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_026",
                            question: "아이는 고집이 새서 친구와 원만하게 지내는데 문제가 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_027",
                            question: "아이는 변덕스럽고 기분이 쉽게 변한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_028",
                            question: "아이는 다른 아이보다 자주 울고 징징거린다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_029",
                            question: "아이는 다른 아이보다 엄마에게 요구하는 것이 많다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "C_3_030",
                            question: "아이는 아주 사소한 일에도 쉽게 기분이 상한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ],
                }
            ]
        },
    ]
}