import React from 'react';
import styled from 'styled-components';
import TextInput from '../../TextInput';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const Text = styled.span`
    font-size: 20px;
    font-family: 'GodoB';
`;

const FillBlankInput = (props) => (
    <Container>
        <Text>{`${props.num}. ${props.prefix}`}</Text>
        <TextInput
            width={'300px'}
            id={props.key}
            type='text'
            defaultValue={props.defaultValue}
            required={true}
            onChange={(e) => props.onChange({
                prefix: props.prefix,
                answer: e.target.value,
                suffix: props.suffix,
            })}
        />
        <Text>{props.suffix}</Text>
    </Container>
)

export default FillBlankInput;