const initState = {

}

const PurchaseReducer = (state = initState, action) => {
    switch (action.type) {
        case 'PURCHASE_SUCCESS':
            console.log('purchase succeeded', action.purchaseDetails);
            return state;
        case 'PURCHASE_ERROR':
            console.log('purchase_error', action.err);
            return state;
        default:
            return state;
    }
}

export default PurchaseReducer