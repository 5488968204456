const initState = {
    err: null
  };
  
  const AdminReducer = (state = initState, action) => {
    switch (action.type) {
      case 'UPLOAD_SUCCESS':
        return {
          ...state,
          err: null
        };
      case 'UPLOAD_FAILED':
        return {
          ...state,
          err: action.err.message
        };
      default:
        return state;
    }
  };
  
  export default AdminReducer;
  