import React, { Component } from 'react';
import styled from 'styled-components';
import TitleContainer from '../components/layouts/TitleContainer';
import FAQ from '../values/pages/Faq';
import BrainwayLocation from '../components/layouts/BrainwayLocation';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px){
    padding: 60px 40px;
  }

  @media (max-width: 415px){
    padding: 60px 0px;
  }
`;


const Title = styled.text`
  max-width: 595px;
  font-size: 36px;
  text-align: center;
  color: ${(props) => props.theme.text};
  font-family: 'GodoB';
  line-height: 55px;

  
  @media (max-width: 1000px){

  }
  
  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 415px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

const SubTitle = styled.text`
  max-width: 595px;
  font-size: 18px;
  text-align: center;
  color: ${(props) => props.theme.text};
  line-height: 32px;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 15px;
    line-height: 28px;
    text-align: left;
  }
  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
  }
`;

const BodyContainer = styled.div`
  padding: 80px 20px;
  max-width: 900px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
 
  @media (max-width: 415px) {
    padding: 80px 0px;
    width: 90%;
  }
`;

const QuestionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 30px;
`;

const Index = styled(Title)`
  font-size: 22px;
  font-family: 'GodoB';
  text-align: left;
  line-height: 50px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }

  @media (max-width: 415px) {
    font-size: 16px;
    line-height: 40px;
    padding: 0px 5px 0px 5px;
  } 
`;

const Question = styled(Title)`
  font-size: 28px;
  font-family: 'GodoB';
  padding: 10px 0px;
  text-align: left;
  line-height: 50px;

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 40px;
  }

  @media (max-width: 415px) {
    font-size: 20px;
    line-height: 30px;
    padding: 5px 5px 10px 5px;
  } 
`;

const Answer = styled(SubTitle)`
  font-size: 21px;
  font-family: 'Nanum Gothic', sans-serif;
  padding: 10px 0px;
  text-align: left;
  line-height: 40px;
  text-indent: 0px;
  margin: 0px 10px;
  
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 28px;
    text-indent: 0px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 100px;
  background-color: rgba(212, 212, 212, 0.5);
  margin-bottom: 10px;
`;

class Faq extends Component {

  componentDidMount(){
    window.scrollTo(0,0)
  }

  render() {
    return (
      <Container>
        <TitleContainer bgImage={require('../res/images/bg_faq.png')}>
          <MainContainer>
            {FAQ.section1.title.split('\n').map((title) => (
              <Title style={{ color: "white" }}>{title}</Title>
            ))}
            <SubTitle style={{ color: "white" }}>{FAQ.section1.subtitle}</SubTitle>
          </MainContainer>
        </TitleContainer>

        <BodyContainer>
          {FAQ.section2.faqs.map((question) => (
            <QuestionContainer>
              <Index>
                공지 {question.index}.
            </Index>
              <Question>
                {question.question}
              </Question>
              <Line />
              {question.index === 2 ?
                <BrainwayLocation />
                :
                <Answer>{question.answer}</Answer>}
            </QuestionContainer>
          ))}
        </BodyContainer>
      </Container>
    )
  }
};

export default Faq;
