const initState = {
  err: null
};

const SurveyReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SAVE_TRIAL_SUCCESS':
      return {
        ...state,
        err: null
      };
    case 'SAVE_TRIAL_ERROR':
      return {
        ...state,
        err: action.err.message
      };
    case 'INIT_SUCCESS':
      return {
        ...state,
        err: null
      };
    case 'INIT_FAILED':
      return {
        ...state,
        err: action.err.message
      };
    case 'SAVE_SUCCESS':
      return {
        ...state,
        err: null
      };
    case 'SAVE_FAILED':
      return {
        ...state,
        err: action.err.message
      };
    case 'SUBMISSION_SUCCESS':
      return {
        ...state,
        err: null
      };
    case 'SUBMISSION_FAILED':
      return {
        ...state,
        err: action.err.message
      };
    default:
      return state;
  }
};

export default SurveyReducer;
