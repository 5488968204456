import React from 'react';
import styled from 'styled-components';
import NavButton from '../buttons/NavButton';
import CloseButton from '../buttons/CloseButton';
import MainButton from '../buttons/MainButton';
import COLORS from '../../values/Colors';
import TabButton from '../TabButton'
import * as ROUTES from '../../values/Routes';

import logoBrainwayWhite from '../../res/images/brainway_logo_white.png';
import logoBrainwayWhite2x from '../../res/images/brainway_logo_white@2x.png';
import logoBrainwayWhite3x from '../../res/images/brainway_logo_white@3x.png';

const Nav = styled.nav`
  width: 80%;
  height: 100%;
  max-width: 1000px;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  z-index: 400;
  transform: ${(props) => (props.show ? 'translateX(0%)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-out;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  padding: 20px 20px 0px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${COLORS.primary};
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 0px;
  background: ${(props) => (props.background ? props.background : 'transparent')};
`;

const Logo = styled.img`
  height: 60px;
  object-fit: contain;
  margin: 10px 20px;

`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

const SideDrawer = ({ show, onClose, onClick }) => {
  return (
    <Nav show={show}>
      <HeaderContainer>
        <CloseButton onClick={onClose} />
      </HeaderContainer>
      <BodyContainer background={COLORS.primary}>
        <Logo src={logoBrainwayWhite}
          srcSet={`${logoBrainwayWhite2x} 2x, 
                      ${logoBrainwayWhite3x} 3x`}
        />
      </BodyContainer>

      <BodyContainer background="white">
       <TabButton
          to={ROUTES.MAIN}
          src={require(`../../res/images/ic_home.png`)}
          title="Home"
          onClick={onClick}
        />
        <TabButton
          to={ROUTES.ABOUT}
          src={require(`../../res/images/ic_about.png`)}
          title="Brainway"
          onClick={onClick}
        />
        <TabButton
          to={ROUTES.FOREST}
          src={require('../../res/images/ic_forest.png')}
          title="성장의 숲"
          onClick={onClick}
        />
         <TabButton
          to={ROUTES.FAQ}
          src={require(`../../res/images/ic_faq.png`)}
          title="공지사항"
          onClick={onClick}
        />
      </BodyContainer>
    </Nav>
  );
};

export default SideDrawer;
