import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from '../../values/Colors'
import * as PATHWAY from '../../values/Pathways';
import GaugeResult from '../../components/layouts/results/GaugeResult';
import RadarResult from '../../components/layouts/results/RadarResult';
import BarGraphResult from '../../components/layouts/results/BarGraphResult';
import LabelSeriesResult from '../../components/layouts/results/LabelSeriesResult';
import Clipboard from '../../components/layouts/Clipboard';
import PathwayContainer from '../../components/layouts/PathwayContainer';
import RESULT from '../../values/pages/Result';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { reset } from '../../redux/actions/TrialActions';
import PathwayButton from '../../components/buttons/PathwayButton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  @media (max-width: 415px){
    background-color: ${COLORS.primary} 
  }
`;

const FixedImage = styled.img`
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
`;

const Overlay = styled.div`
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
`;

const BlankContainer = styled.div`
  width: 100%;
  height: 1050px;
  background: transparent;
  @media (max-width: 415px){
    margin-top: 72px;
    background: white;
  }
`;

const ClipContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 100px;
  padding: 60px 50px 20px 50px;
  
  @media (max-width: 768px) {
    margin-top: 140px;
    padding: 0px 0px 0px 0px;
  }

  @media (max-width: 415px){
    margin-top: 72px;
  }
`;

const OtherPathContainer = styled.div`
  width: 90%;  
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 5px;

  @media (max-width: 768px){
    width: 100%;
    padding: 10px;
  }

  @media (max-width: 415px){
    width: 100%;
    padding: 20px;
  }
`;

const ResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Category = styled.span`
  width: 100%;
  text-align: left;
  font-size: 32px;
  margin-bottom: 20px;
  font-family: 'GodoB';

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    font-size: 26px;
    margin-bottom: 10px;
  }

  @media (max-width: 415px){
    font-size: 24px;
    text-align: center;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 4px;
  background-color: black;
  border-radius: 100px;
  align-self: center;

  @media (max-width: 768px) {
    height: 2px;
  }
`;

const Pathway = styled(PathwayContainer)`
  box-shadow: 1px 0px 7px rgba(0,0,0,0.3);
  padding: 20px;
  margin: 5px;
  border-radius: 8px;
`;


const Logo = styled.img`
  height: 140px;
  object-fit: contain;
  align-self: center;
  margin-top: 20px;
  @media (max-width: 415px){
    margin-top: 20px;
    height: 120px;
  }
`;

const Title = styled.span`
  font-size: 40px;
  text-align: center;
  color: black;
  font-family: 'GodoB';
  line-height: 50px;
  margin: 20px 0px 80px 0px;

  @media (max-width: 768px){
    font-size: 32px;
    margin: 30px 0px 30px 0px;
  }
`;

const OtherPathTitle = styled(Title)`
  @media (max-width: 415px){
    font-size: 22px
  }
`;

const Section = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 768px){
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 415px){
    flex-direction: column;
    align-items: center;
  }
`;

const SectionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 5px;

  @media (max-width: 768px){
    width: 80%;
    margin: 0px;
  }
  
  @media (max-width: 415px){
    width: 100%;
    flex-direction: column;
    margin: 0px;
  }
`;

class Result extends Component {
  state = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    this.props.resetId()
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  getCurrentDevice = () => {
    let device = ""
    if (this.state.width > 715) {
      device = "pc"
    } else if (this.state.width <= 715 && this.state.width > 415) {
      device = "tablet"
    } else if (this.state.width <= 415) {
      device = "phone"
    }
    return device
  }

  renderResult = (section, result) => {
    const device = this.getCurrentDevice()
    switch (section) {
      case PATHWAY.COGNITION_SECTION_1:
        return <RadarResult device={device} section={section} result={result} />;
      case PATHWAY.COGNITION_SECTION_2:
        return <GaugeResult device={device} section={section} result={result} />;
      case PATHWAY.LEARNING_SECTION_1:
        return <RadarResult device={device} section={section} result={result} />;
      case PATHWAY.LEARNING_SECTION_2:
        return <BarGraphResult device={device} section={section} result={result} />;
      case PATHWAY.COMMUNICATION_SECTION_1:
        return <BarGraphResult device={device} section={section} result={result} />;
      case PATHWAY.COMMUNICATION_SECTION_2:
        return <RadarResult device={device} section={section} result={result} />;
      case PATHWAY.HEALING_SECTION_1:
        return <LabelSeriesResult device={device} section={section} result={result} />;
      case PATHWAY.HEALING_SECTION_2:
        return <GaugeResult device={device} section={section} result={result} />;
      case PATHWAY.HEALING_SECTION_3:
        return <GaugeResult device={device} section={section} result={result} />;
      default:
        return <div />;
    }
  };

  renderPathways = (report, buttons) => {
    let sections = [];
    let views = [];
    buttons.map((button, index) => {
      if (button.trial !== report.trial) {
        views.push(
          <PathwayButton
            key={index}
            to={`/forest/${button.trial}`}
            imageName={`img_${button.trial}`}
            name={button.name}
            desc={button.desc}
          />
        )
      }

      console.log("views", views.length)

      if (views.length !== 0 && views.length % 2 === 0) {
        sections.push(
          <SectionRow
            key={sections.length}
          >
            {views}
          </SectionRow>
        )
        views = [];
      }
      console.log("sections", sections.length)
    })

    console.log(views.length, sections.length)

    return <Section>
      {sections}
    </Section>
  }

  render() {
    console.log(this.state)
    const { report } = this.props;
    return (
      <Container>
        <FixedImage src={require('../../res/images/bg_result.png')} />
        <Overlay />
        {report ? 
        <ClipContainer>
          {report && <Clipboard>
            <Logo src={require(`../../res/images/img_${report.trial}.png`)} />
            <Title>{report.title}</Title>
            {report.result &&
              Object.keys(report.result).map((section) => (
                <ResultContainer>
                  <Category>{section}</Category>
                  <Line />
                  {this.renderResult(section, report.result[section])}
                </ResultContainer>
              ))}
          </Clipboard>
          }
        </ClipContainer>
        : <BlankContainer/>  
        }
        {report && 
        <OtherPathContainer>
          <OtherPathTitle>다른 길들도 한번 알아볼까요?</OtherPathTitle>
            {this.renderPathways(report, RESULT.buttons)}
        </OtherPathContainer>
        } 
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const submissionId = ownProps.match.params.id
  const submissions = state.firestore.data.trial_submissions
  const submission = submissions ? submissions[submissionId] : null
  const report = submission ? submission.report : null

  return {
    report: report,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetId: () => dispatch(reset())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [{ collection: "trial_submissions", doc: props.match.params.id }])
)(Result)
