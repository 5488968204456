import React from 'react';
import styled from 'styled-components';
import UserInputItem from '../../buttons/temp/UserInputItem';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid black;
  flex-direction: row;
  background: lightgray;
`;

const HeaderText = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  size: 12px;
  color: black;
  text-align: center;
  align-items: center;
  margin: 10px;
  justify-content: center;
`;

const ListContainer = styled.div``;

const GeneralList = (props) => {
  console.log(props.list);
  return (
    <Container>
      <HeaderContainer>
        <HeaderText>ID</HeaderText>
        <HeaderText>Section</HeaderText>
        <HeaderText>Category</HeaderText>
        <HeaderText>Question</HeaderText>
        <HeaderText>Answer</HeaderText>
        <HeaderText>Choice</HeaderText>
      </HeaderContainer>
      <ListContainer>
        {props.list &&
          Object.keys(props.list)
            .sort()
            .map((uid) => {
              const input = props.list[uid];
              return <UserInputItem key={uid} index={uid} section={props.section} input={input} />;
            })}
      </ListContainer>
    </Container>
  );
};

export default GeneralList;
