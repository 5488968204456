import React from 'react';
import styled from 'styled-components';
import DrawerToggleButton from '../buttons/DrawerToggleButton';
import SignOutMenu from './SignOutMenu'
import SignInMenu from './SignInMenu'
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import * as ROUTES from '../../values/Routes';
import * as MENU from '../../values/Menu';

import logoBrainwayWhite from '../../res/images/brainway_logo_white.png';
import logoBrainwayWhite2x from '../../res/images/brainway_logo_white@2x.png';
import logoBrainwayWhite3x from '../../res/images/brainway_logo_white@3x.png';
import logoBrainwayBlue from '../../res/images/brainway_logo_blue.png';
import logoBrainwayBlue2x from '../../res/images/brainway_logo_blue@2x.png';
import logoBrainwayBlue3x from '../../res/images/brainway_logo_blue@3x.png';

const Header = styled.header`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 20px;
  background-color: ${(props) => props.background};
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 100;

  @media(max-width: 1000px){

  }

  @media (max-width: 768px) {
    height: 116px;
    padding: 0px;
  }

  @media (max-width: 415px) {
    height: 72px;
  }
`;

const Nav = styled.nav`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;

  @media(max-width: 768px){
    flex-direction: column;
    align-items: flex-start;
  } 
  @media (max-width: 415px) {
    flex-direction: row;
  }
`;

const LogoContainer = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px){
    width: 160px;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    padding: 0px 30px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media (max-width: 415px) {
    height: 100%;
    padding: 0px;
    margin: 0px;
  }

`;

const LogoImage = styled.img`
  height: 50px;
  object-fit: contain;

  @media (max-width: 1000px){
    height: 48px;
  }

  @media (max-width: 768px) {
    height: 44px;
  }

  @media (max-width: 415px) {
    
  }

`;

const ToggleContainer = styled.div`
  display: none;
  
  @media (max-width: 415px) {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    margin-top: 3px;
  }
    

`;

const MenuContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-self: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    align-self: flex-start;
  }

  @media (max-width: 415px) {
    display: none;
  }
`;

const MenuButton = styled(Link)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: ${(props) => (props.transparentBar ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)')};
  }
`;

const MenuText = styled.span`
  color: ${(props) => (props.transparentBar ? 'white' : 'black')};
  font-family: 'GodoM';
  font-size: 16px;
  padding: 0px 40px;
  letter-spacing: 1px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media(max-width: 1000px){
    font-size: 14px;
    padding: 0px 30px;
  }
  
  @media (max-width: 768px) {
    padding: 15px 30px;
  }

  @media (max-width: 415px) {
    
  }
`;

const MenuSelected = styled.div`
  height: 3px;
  width: 100%;
  background: ${(props) => (props.show ? (props.transparentBar ? 'white' : 'black') : 'transparent')};
`;

const Navbar = (props) => {
  const { pathname } = useLocation();
  const isMyBrainwayOrAdminPage = pathname.includes('mybrainway') || pathname.includes('admin')
  const isExceptional = pathname === ROUTES.SIGN_IN || pathname === ROUTES.SIGN_UP || pathname.includes('survey');
  const transparentBar = props.isTop && !isMyBrainwayOrAdminPage && !isExceptional;
  return (
    <Header background={transparentBar ? 'transparnet' : 'white'}>
      <Nav>
        <ToggleContainer onClick={props.onDrawerClicked}>
                    <DrawerToggleButton {...props} />
        </ToggleContainer>

        <LogoContainer>
          <Link to="/">
            <LogoImage
              src={transparentBar ? logoBrainwayWhite : logoBrainwayBlue}
              srcSet={`${transparentBar ? logoBrainwayWhite2x : logoBrainwayBlue2x} 2x, 
                                ${transparentBar ? logoBrainwayWhite3x : logoBrainwayBlue3x} 3x`}
            />
          </Link>
        </LogoContainer>


        {isMyBrainwayOrAdminPage ?
          <MenuContainer />
          :
          <MenuContainer>
            <MenuButton transparentBar={transparentBar} to={ROUTES.ABOUT}>
              <MenuText transparentBar={transparentBar}>{MENU.ABOUT}</MenuText>
              <MenuSelected transparentBar={transparentBar} show={pathname === ROUTES.ABOUT} />
            </MenuButton>

            <MenuButton transparentBar={transparentBar} to={ROUTES.FOREST}>
              <MenuText transparentBar={transparentBar}>{MENU.FOREST}</MenuText>
              <MenuSelected transparentBar={transparentBar} show={pathname === ROUTES.FOREST} />
            </MenuButton>

            <MenuButton transparentBar={transparentBar} to={ROUTES.FAQ}>
              <MenuText transparentBar={transparentBar}>{MENU.FAQ}</MenuText>
              <MenuSelected transparentBar={transparentBar} show={pathname === ROUTES.FAQ} />
            </MenuButton>
          </MenuContainer>}

        <LogoContainer />

        {/* Uncomment this in v2.0
           {props.auth.uid ?
            <SignInMenu isTop={transparentBar} />
            :
            <SignOutMenu isTop={transparentBar} />
          } 
          */}
      </Nav>
    </Header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(Navbar);
