import React from 'react';
import styled from 'styled-components';
import SurveyQuestion from './SurveyQuestion';

const Container = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 60px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: white;

  @media (max-width: 768px) {
    width: 98%;
    padding: 30px 15px;
    margin: 10px 0px 0px 0px;
    border-radius: 5px;
  }

  @media (max-width: 415px){
    width: 96%;
    border-radius: 0px;
    margin: 10px 0px 0px 0px;
  }
`;

const Section = styled.span`
  font-size: 32px;
  margin-bottom: 20px;
  font-family: 'GodoB';
  
  @media (max-width: 1000px){

  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    font-size: 30px;
    margin-bottom: 10px;
    text-align: left;
  }

  @media (max-width: 415px){
    font-size: 24px;
    line-height: 30px;
    padding: 10px 15px;
    text-align: left;
  }
`;

const InstructionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  
  @media (max-width: 768px) {
    padding: 0px 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 415px) {
    margin-bottom: 20px;
  }
`;

const Instruction = styled.span`
  font-size: 18px;
  font-family: 'Nanum Gothic', sans-serif;
  text-align: left;
  line-height: 30px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 28px;
  }

`;

const QuestionContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const Line = styled.div`
  width: 100%;
  height: 4px;
  background-color: black;
  border-radius: 100px;
  margin-bottom: 30px;
  align-self: center;

  @media (max-width: 768px) {
    width: 95%;
    margin-bottom: 0px;
  }

  @media (max-width: 415px){
    height: 1px;
    margin-bottom: 15px;
  }
`;

const SurveySection = (props) => {
  const { section, selected, onSelected } = props;
  return (
    <Container>
      <Section>{section.title}</Section>

      <InstructionContainer>
        <Instruction>{section.instruction}</Instruction>
      </InstructionContainer>

      <Line />

      <QuestionContainer>
        {section.questions &&
          section.questions.map((question, index) => {
            const maxScore = section.choices.values.length - 1;
            return (
              <SurveyQuestion
                key={index}
                number={index + 1}
                question={question}
                choices={section.choices}
                selected={selected && selected[question.uid]}
                onSelected={(selected) => {
                  onSelected(section.title, question.uid, {
                    question: question.text,
                    category: question.category,
                    choice: selected.value,
                    answer: selected.text,
                    maxScore: maxScore
                  });
                }}
              />
            );
          })}
      </QuestionContainer>
    </Container>
  );
};

export default SurveySection;
