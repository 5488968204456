import React, { Component } from 'react';
import styled from 'styled-components';
import Header from '../components/layouts/Header';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SubContainer = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: 'row';

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  display: flex;
  flex: 1;
  padding: 20px;
`;

const Description = styled.span`
  display: flex;
  flex: 1;
  font-size: 18px;
  text-align: left;
  justify-content: center;
  padding: 20px;
`;

class Center extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container>
        <Header title="브레인웨이 센터" />
        <SubContainer>
          <Logo />
        </SubContainer>
        <SubContainer style={{ direction: 'row' }}>
          <Description>
            브레인 웨이는 다년간의 경험을 바탕으로 심리학 분야 전문가들이 일상 생활에서 사람들에게
            좀 더 가까이 다가가기 위한 취지에서 설립되었습니다. 우리 가족, 우리 아이, 그리고 자기
            자신의 정신 건강과 발달에 공헌하고 있는 다양한 분야의 연구소, 회사와 연계하고 있으며, 더
            정확하고 실질적인 도움이 되는 프로그램들을 개발하기 위해 연구하고 있습니다.
          </Description>
        </SubContainer>
        <SubContainer style={{ direction: 'row' }}>
          <Logo />
          <Description>
            브레인 웨이는 다년간의 경험을 바탕으로 심리학 분야 전문가들이 일상 생활에서 사람들에게
            좀 더 가까이 다가가기 위한 취지에서 설립되었습니다. 우리 가족, 우리 아이, 그리고 자기
            자신의 정신 건강과 발달에 공헌하고 있는 다양한 분야의 연구소, 회사와 연계하고 있으며, 더
            정확하고 실질적인 도움이 되는 프로그램들을 개발하기 위해 연구하고 있습니다.
          </Description>
        </SubContainer>
        <SubContainer style={{ direction: 'row' }}>
          <Logo />
          <Description>
            브레인 웨이는 다년간의 경험을 바탕으로 심리학 분야 전문가들이 일상 생활에서 사람들에게
            좀 더 가까이 다가가기 위한 취지에서 설립되었습니다. 우리 가족, 우리 아이, 그리고 자기
            자신의 정신 건강과 발달에 공헌하고 있는 다양한 분야의 연구소, 회사와 연계하고 있으며, 더
            정확하고 실질적인 도움이 되는 프로그램들을 개발하기 위해 연구하고 있습니다.
          </Description>
        </SubContainer>
      </Container>
    );
  }
}

export default Center;
