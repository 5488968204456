import React, { useEffect } from 'react';
import styled from 'styled-components';
import PRIVACY_POLICY from '../values/pages/PrivacyPolicy';
import COLORS from '../values/Colors';

const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  background: ${COLORS.primary};
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  
  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: center;
  line-height: 100px;
`;

const Description = styled.h3`
  font-size: 20px;
  font-weight: 300;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
  line-height: 40px;
`;

const ListTitle = styled.ul`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
  line-height: 80px;
`;

const ListNoti = styled.ul`
  font-size: 18px;
  font-weight: 300;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
  line-height: 40px;
`;
const SubjectTitle = styled.ul`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
  line-height: 40px;
`;

const SubjectDesc = styled.ul`
  font-size: 16px;
  font-weight: 100;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
`;

const Contents = styled.ul`
  font-size: 16px;
  font-weight: 100;
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  text-align: left;
`;

const PrivacyPolicy = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <Container>
      <Title>{PRIVACY_POLICY.title}</Title>
      {PRIVACY_POLICY.description.map((desc, descIndex) => (
        <Description key={descIndex}>{desc}</Description>
      ))}
      {PRIVACY_POLICY.contents.map((content, contentIndex) => {
        return (
          <div key={contentIndex}>
            <ListTitle>{content.listTitle}</ListTitle>
            {content.list?.map((item, itemIndex) => (
              <div key={itemIndex}>
                {item.noti && <ListNoti>{item.noti}</ListNoti>}
                {item.subject &&
                  item.subject.map((sub, subIndex) => (
                    <div key={subIndex}>
                      <SubjectTitle type="a">{sub.title}</SubjectTitle>
                      {sub.des && <SubjectDesc>{sub.des}</SubjectDesc>}
                      {sub.contents &&
                        sub.contents.map((con, conIndex) => (
                          <Contents key={conIndex}>{con}</Contents>
                        ))}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        );
      })}
    </Container>
  );
};

export default PrivacyPolicy;
