import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 120px 30px;

  @media (max-width: 768px) {
  }
`;

const SectionContainer = (props) => (
  <Container {...props}>
    <Overlay>
      <BodyContainer>{props.children}</BodyContainer>
    </Overlay>
  </Container>
);

export default SectionContainer;
