import * as PATHWAY from '../values/Pathways';

const PrefEffStatements = {
  선호: {
    언어영역: '책을 읽거나 메모, 일기 등 글을 쓰는 언어적 활동을 좋아합니다.',
    논리수학영역: '수수께끼, 퍼즐 등 논리적 문제 해결 과정을 즐깁니다.',
    자아영역:
      '때때로 혼자서 사색을 하며 자신의 감정과 생각을 정리하는 시간을 가지길 선호합니다. 타인이 모르는 내적 즐거움을 찾습니다.',
    대인관계영역:
      '혼자서 해내는 것보다 타인과의 협업을 통해 결과를 만드는 것을 좋아합니다. 타인을 설득하거나 동조를 얻는 커뮤니케이션 과정을.즐깁니다',
    시공간영역: '물체의 구조, 미로 등을 파악하는 일을 좋아합니다',
    예술영역: '음악을 듣고 따라 부르거나 연주하는 것에 관심이 있습니다.',
    신체운동지능: '어려운 춤 동작(아크로바틱)이나 운동 기술을 익히는 것을 좋아합니다'
  },
  효능: {
    언어영역: '작문, 독해, 발표 등 언어 영역(국어, 외국어) 능력에 대한 자신감과 할 수 있다는 믿음이 높게 나타납니다.',
    논리수학영역:
      '수학, 철학과 같은 논리력이 필요한 일을 잘하고, 규칙이나 규율을 만들고 실천하는 것을 잘합니다.',
    자아영역:
      '자기 자신의 내면을 잘 알고 있다고 생각합니다. 자신만의 행복 혹은 가치가 무엇인지 알고 있습니다',
    대인관계영역: '많은 사람과 교류하며 협업을 잘 합니다.',
    시공간영역: '처음 보는 사물의 구조를 파악하여 분해하고 조립할 수 있습니다',
    예술영역:
      '처음 듣는 음악을 곧 잘 따라하거나, 악기 연주법을 배웁니다. 음악을 개사하거나 변주하는데 자신이 있습니다',
    신체운동지능: '연극이나 댄스를 통해 표현하는 것에 자신이 있고, 어려운 운동 기술을 곧잘 익힙니다.'
  }
};

const RangeStatements = {
  주의집중력: {
    boundaries: {
      normal: 0.3,
      moderate: 0.7
    },
    normal: {
      state: '보통',
      statement: '어떤 일이든 쉽게 몰입하여 오랜 시간 집중을 유지할 수 있습니다.'
    },
    moderate: {
      state: '주의',
      statement: ' 몰입하는 것이 힘들고 쉽게 산만해져서 부적응하기 쉽습니다.'
    },
    danger: {
      state: '위험',
      statement: '어떤 일에 몰입하고 주의를 기울이는 것에 어려움이 커서, 마쳐야 하는 과제에 실패하거나 과제의 완성도가 저하됩니다.'
    }
  },
  가치: {
    boundaries: {
      normal: 0.3,
      moderate: 0.7
    },
    normal: '결과 주의',
    moderate: '현실 주의',
    danger: '흥미 중시'
  },
  통제: {
    boundaries: {
      normal: 0.3,
      moderate: 0.7
    },
    normal: '약하게',
    moderate: '적절하게',
    danger: '강하게'
  },
  계획: {
    boundaries: {
      normal: 0.3,
      moderate: 0.7
    },
    normal: '낮은 편입니다',
    moderate: '평범합니다.',
    danger: '높습니다.'
  },
  자아존중감: {
    boundaries: {
      normal: 0.3,
      moderate: 0.7
    },
    normal: {
      state: '낮다',
      statement:
        '자아 존중감은 자신의 가치, 유능감에 대해서 긍정적인 믿음을 가지는 것을 의미합니다. 자아 존중감이 높은 사람은 삶에 있어서 더 주도적이고 도전적인  태도를 가지며, 스트레스에 대한 높은 저항력을 가지고 있습니다. 반대로, 자아 존중감이 낮은 사람은 불안과 스트레스에 취약한 경우가 많으며, 소극적인 태도를 가질 수 있습니다.'
    },
    moderate: {
      state: '보통',
      statement:
        '자아 존중감은 자신의 가치, 유능감에 대해서 긍정적인 믿음을 가지는 것을 의미합니다. 자아 존중감이 높은 사람은 삶에 있어서 더 주도적이고 도전적인  태도를 가지며, 스트레스에 대한 높은 저항력을 가지고 있습니다. 반대로, 자아 존중감이 낮은 사람은 불안과 스트레스에 취약한 경우가 많으며, 소극적인 태도를 가질 수 있습니다.'
    },
    danger: {
      state: '높다',
      statement:
        '자아 존중감은 자신의 가치, 유능감에 대해서 긍정적인 믿음을 가지는 것을 의미합니다. 자아 존중감이 높은 사람은 삶에 있어서 더 주도적이고 도전적인  태도를 가지며, 스트레스에 대한 높은 저항력을 가지고 있습니다. 반대로, 자아 존중감이 낮은 사람은 불안과 스트레스에 취약한 경우가 많으며, 소극적인 태도를 가질 수 있습니다.'
    }
  },
  스트레스: {
    boundaries: {
      normal: 0.3,
      moderate: 0.75
    },
    normal: {
      state: '저위험',
      statement:
        '스트레스 환경은 유동적이므로, 수시로 자신의 상황을 돌아보며 스트레스를 파악하는 것이 중요합니다. 스트레스 지수를 통해 자신의 스트레스 수준을 점검해 보고 스트레스를 주는 원인을 찾고 해결해봅시다.'
    },
    moderate: {
      state: '보통',
      statement:
        '스트레스 환경은 유동적이므로, 수시로 자신의 상황을 돌아보며 스트레스를 파악하는 것이 중요합니다. 스트레스 지수를 통해 자신의 스트레스 수준을 점검해 보고 스트레스를 주는 원인을 찾고 해결해봅시다.'
    },
    danger: {
      state: '고위험군',
      statement:
        '스트레스 환경은 유동적이므로, 수시로 자신의 상황을 돌아보며 스트레스를 파악하는 것이 중요합니다. 스트레스 지수를 통해 자신의 스트레스 수준을 점검해 보고 스트레스를 주는 원인을 찾고 해결해봅시다.'
    }
  }
};

export const getResult = (info, stats) => {
  let result = {};
  Object.keys(stats).forEach((section) => {
    const inputResult = stats[section];
    const average = getAverage(inputResult);
    switch (section) {
      case PATHWAY.COGNITION_SECTION_1:
        const bestTwoPref = getBestTwo(average, '선호');
        const bestTwoEff = getBestTwo(average, '효능');
        const data = organizeDataForCog1(inputResult);
        result = {
          ...result,
          [PATHWAY.COGNITION_SECTION_1]: {
            graph: 'radar',
            radars: ['선호', '효능'],
            data: data,
            statements: {
              one: `<em style="color:#84B2D8;">${bestTwoPref.A}</em>과 <em style="color:#84B2D8;">${bestTwoPref.B}</em>에 대한 <em style="textDecoration:underline double;">선호</em>가 가장 높게 나타나며, <em style="color:#84D89D;">${bestTwoEff.A}</em>, <em style="color:#84D89D;">${bestTwoEff.B}</em>와 관련된 일을 할 때 <em style="textDecoration:underline double;">효능감</em>이 가장 높습니다`,
              two: [
                `<em style="fontWeight:800">${bestTwoPref.A}</em>: ${PrefEffStatements['선호'][bestTwoPref.A]}`,
                `<em style="fontWeight:800">${bestTwoPref.B}</em>: ${PrefEffStatements['선호'][bestTwoPref.B]}`,
                `<em style="fontWeight:800">${bestTwoEff.A}</em>: ${PrefEffStatements['효능'][bestTwoEff.A]}`,
                `<em style="fontWeight:800">${bestTwoEff.B}</em>: ${PrefEffStatements['효능'][bestTwoPref.B]}`
              ]
            }
          }
        };
        break;
      case PATHWAY.COGNITION_SECTION_2:
        let cog2Statements = {};
        Object.values(getStatement(RangeStatements, average)).map((value) => {
          cog2Statements = value;
        });
        result = {
          ...result,
          [PATHWAY.COGNITION_SECTION_2]: {
            graph: 'gauge-1',
            data: { percent: average[0].average },
            statements: cog2Statements,
            boundaries: [0.3, 0.4, 0.3]
          }
        };
        break;
      case PATHWAY.LEARNING_SECTION_1:
        const bestSec1 = getBestOne(average);
        const worstSec1 = getWorstOne(average);
        const organizedLrn1 = [];
        average.map((sec) => organizedLrn1.push({ name: sec.name, 0: sec.average }));

        result = {
          ...result,
          [PATHWAY.LEARNING_SECTION_1]: {
            graph: 'radar',
            radars: ['학습'],
            data: organizedLrn1,
            statements: {
              one: `부모님께서는 자녀의 학습에 관하여 <em style="color:#84D89D;">${bestSec1}</em> 하는 것이 <em style="textDecoration:underline double;">강점</em>이겠습니다. 반대로, <em style="color:#D88484;">${worstSec1}</em> 하는 것이 <em style="textDecoration:underline double;">약점</em>으로 나타납니다. `,
              two: [
                `<em style="fontWeight:800">학습지원</em>: 학습 방법 및 진로 등 학습에 도움이 되는 정보를 찾고 공유합니다.`,
                `<em style="fontWeight:800">격려</em>: 학업 결과나 목표에 대해서 적절한 응원을 해줍니다.`,
                `<em style="fontWeight:800">존중</em>: 학습 방법과 목표를 정의롭고 논리적인 방법으로 제안합니다.`,
                `<em style="fontWeight:800">공감</em>: 학업의 어려움을 충분히 이해하고 배려해줍니다.`,
                `<em style="fontWeight:800">자율성</em>: 학업 규칙과 방법에서 자녀의 선택을 우선시 합니다.`
              ]
            }
          }
        };
        break;
      case PATHWAY.LEARNING_SECTION_2:
        const lrn2Statements = getStatement(RangeStatements, average);
        const organizedStatements = [];
        Object.keys(lrn2Statements).map((key) => {
          let statement = '';
          switch (key) {
            case '가치':
              statement = `자녀의 학습에 대한 성향은 ${lrn2Statements[key]}에 가깝습니다.`;
              break;
            case '계획':
              statement = `그리고 학습을 위해 체계적으로 관리하는 능력은 ${lrn2Statements[key]}`;
              break;
            case '통제':
              statement = `그리고 그런 계획을 지키기 위한 통제는 ${lrn2Statements[key]} 하고 있습니다.`;
              break;
            default:
          }
          statement !== '' && organizedStatements.push(statement);
        });
        organizedStatements.push(
          '자녀의 약점과 강점을 파악하여 학습을 계획하고 가이드라인을 제시해 주세요.'
        );

        result = {
          ...result,
          [PATHWAY.LEARNING_SECTION_2]: {
            graph: 'bar',
            data: average,
            statements: organizedStatements
          }
        };
        break;
      case PATHWAY.COMMUNICATION_SECTION_1:
        result = {
          ...result,
          [PATHWAY.COMMUNICATION_SECTION_1]: {
            graph: 'bar',
            data: average,
            statements:
              '사회성지수가 높으면, 대인관계 상황에서 일어나는 일들에 효율적으로 대처할 수 있습니다. 반면에 사회성지수가 낮으면 또래관계나 학교생활 적응에 어려움을 보이거나 문제 행동을 보일 수 있습니다. 또래관계나 학교생활에서의 어려움은 아이를 정서적으로 위축되게 하거나 우울감, 불안감 등의 어려움을 겪게 만들 수 있으므로, 아이의 상태나 특성에 대해 잘 파악하는 것이 중요합니다.'
          }
        };
        break;
      case PATHWAY.COMMUNICATION_SECTION_2:
        const organizedCom2 = [];
        average.map((sec) => organizedCom2.push({ name: sec.name, 0: sec.average }));

        result = {
          ...result,
          [PATHWAY.COMMUNICATION_SECTION_2]: {
            graph: 'radar',
            radars: ['소통'],
            data: organizedCom2,
            statements:
              '부모의 기분이나 감정 상태는 자녀에게 영향을 주게 됩니다. 부모님이 경험하는 스트레스 요인을 파악하고, 어려움을 겪고 있는 경우에는 적절한 대처와 개입을 통한 회복의 노력이 필요합니다.'
          }
        };
        break;
      case PATHWAY.HEALING_SECTION_1:
        const organizedHlg1 = [];
        average.map((sec, index) =>
          organizedHlg1.push({
            x: (index + 1) * 2,
            y: Math.round(sec.average * 100),
            label: sec.name.substring(0, sec.name.indexOf('척도') - 1),
            size: Math.round(sec.average * 100),
            color: index
          })
        );
        result = {
          ...result,
          [PATHWAY.HEALING_SECTION_1]: {
            graph: 'series',
            data: organizedHlg1,
            statements:
              '결혼 생활과 관련하여, 전반적인 생각과 감정, 그리고 양육에서 발생할 수 있는 갈등을 파악하고 배우자와 이야기하며 서로 이해해 봅시다.'
          }
        };
        break;
      case PATHWAY.HEALING_SECTION_2:
        let hlg2Statements = {};
        Object.values(getStatement(RangeStatements, average)).map((value) => {
          hlg2Statements = value;
        });
        result = {
          ...result,
          [PATHWAY.HEALING_SECTION_2]: {
            graph: 'gauge-2',
            data: {percent: average[0].average},
            statements: hlg2Statements,
            boundaries: [0.3, 0.4, 0.3]
          }
        };
        break;
      case PATHWAY.HEALING_SECTION_3:
        let hlg3Statements = {};
        Object.values(getStatement(RangeStatements, average)).map((value) => {
          hlg3Statements = value;
        });
        result = {
          ...result,
          [PATHWAY.HEALING_SECTION_3]: {
            graph: 'gauge-3',
            data: {percent: average[0].average},
            statements: hlg3Statements,
            boundaries: [0.3, 0.45, 0.25]
          }
        };
        break;
      default:
        result = { ...result };
    }
  });

  return {
    trial: info.trial,
    title: info.title,
    result: result
  };
};

const getStatement = (map, averages) => {
  let statement = {};
  averages.forEach((o) => {
    if (o.average < map[o.name].boundaries.normal) {
      statement = {
        ...statement,
        [o.name]: map[o.name].normal
      };
    } else if (
      o.average >= map[o.name].boundaries.normal &&
      o.average < map[o.name].boundaries.moderate
    ) {
      statement = {
        ...statement,
        [o.name]: map[o.name].moderate
      };
    } else {
      statement = {
        ...statement,
        [o.name]: map[o.name].danger
      };
    }
  });
  return statement;
};

const organizeDataForCog1 = (inputResult) => {
  let dataSheet = {};
  Object.keys(inputResult).forEach((category) => {
    const name =
      category.indexOf('영역') !== -1
        ? category.substring(0, category.indexOf('영역'))
        : category.substring(0, category.indexOf(' '));
    if (category.includes('선호')) {
      dataSheet = {
        ...dataSheet,
        [name]: {
          ...dataSheet[name],
          0: inputResult[category].totalScore / inputResult[category].maxScore
        }
      };
    } else if (category.includes('효능')) {
      dataSheet = {
        ...dataSheet,
        [name]: {
          ...dataSheet[name],
          1: inputResult[category].totalScore / inputResult[category].maxScore
        }
      };
    }
  });

  const data = [];

  Object.keys(dataSheet).map((key) => {
    data.push(Object.assign({ name: key }, dataSheet[key]));
  });

  return data;
};

const getAverage = (inputResult) => {
  const average = [];
  Object.keys(inputResult).forEach((category) => {
    average.push({
      name: category,
      average: inputResult[category].totalScore / inputResult[category].maxScore,
      percent: 100 * inputResult[category].totalScore / inputResult[category].maxScore,
    });
  });
  return average;
};

const getBestTwo = (listOfAverages, contains) => {
  const filtered = listOfAverages.filter((o) => o.name.includes(contains));
  const averages = filtered.sort((a, b) => b.average - a.average);
  return {
    A: averages[0].name.replace(` ${contains}`, ''),
    B: averages[1].name.replace(` ${contains}`, '')
  };
};

const getBestOne = (listOfAverages) => {
  let string = '';
  let highestScore = Math.max.apply(
    Math,
    listOfAverages.map((o) => o.average)
  );
  let highestOnes = listOfAverages.filter((o) => o.average === highestScore);
  highestOnes.forEach((one) => {
    if (string.length !== 0) {
      string += ', ';
    }
    string += one.name;
  });
  return string;
};

const getWorstOne = (listOfAverages) => {
  let string = '';
  let lowestScore = Math.min.apply(
    Math,
    listOfAverages.map((o) => o.average)
  );
  let lowestOnes = listOfAverages.filter((o) => o.average === lowestScore);
  lowestOnes.forEach((one) => {
    if (string.length !== 0) {
      string += ', ';
    }
    string += one.name;
  });
  return string;
};
