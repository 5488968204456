import React from 'react';
import styled from 'styled-components';
import DropdownFrame from '../DropdownFrame';

const AccountContainer = styled.div`
    width: 300px;
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
`;

const Logo = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
    margin: 30px;
`;

const Name = styled.span`
    font-size: 21px;
    font-family: "GodoB";
    color: black;
    margin: 10px 0px;
`;

const Email = styled.span`
    font-size: 16px;
    color: black;
    line-height: 20px;
    font-family: 'Nanum Gothic', sans-serif;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin: 10px 0px 20px 0px;
`;

const UserMenu = (props) => {
    return <DropdownFrame>
        <AccountContainer>
            <Logo src={props.photoUrl}/>
            <Name>{props.name}</Name>
            <Email>{props.email}</Email>
        </AccountContainer>
        <Line />
        {props.children}
    </DropdownFrame>
}

export default UserMenu;