import AuthReducer from './AuthReducer';
import SurveyReducer from './SurveyReducer';
import TrialReducer from './TrialReducer';
import AdminReducer from './AdminReducer';
import NoticeReducer from './NoticeReducer';
import ReportReducer from './ReportReducer';
import PurchaseReducer from './PurchaseReducer';

import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

const RootReducer = combineReducers({
  auth: AuthReducer,
  survey: SurveyReducer,
  trial: TrialReducer,
  admin: AdminReducer,
  purchase: PurchaseReducer,
  notice: NoticeReducer,
  report: ReportReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

export default RootReducer;
