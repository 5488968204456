import React from 'react';
import styled from 'styled-components';
import COLORS from '../../values/Colors';
import MainButton from '../buttons/MainButton';

const Container = styled.div`
    display: flex;
    width: 200px;
    height: 100%;
    padding: 12px 0px;
    justify-content: flex-end;
    align-items: center;
`;

const SignOutMenu = (props) => (
    <Container>
        <MainButton
            backgroundColor={props.isTop ? 'transparent' : COLORS.primary}
            border={"1px solid white"}
            to="/login"
            hideArrow={true}
            text="로그인"
        />
    </Container>
)

export default SignOutMenu;