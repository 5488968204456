import React from 'react';
import styled from 'styled-components';
import GaugeChart from 'react-gauge-chart';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 60px 0px;

  @media (max-width: 768px){
    flex-direction: column;
    padding: 60px 10px 60px 10px;
  }

  @media (max-width: 415px){
    flex-direction: column;
    padding: 40px 10px 60px 10px;
  }
`;

const SubContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubTextContainer = styled(SubContainer)`
  align-items: flex-start;
  @media (max-width: 768px){
    align-items: center;
  }
`;

const Result = styled.span`
  width: 100%;
  font-size: 30px;
  font-family: 'GodoB';
  line-height: 40px;
  text-align: left;
  margin: 0px 0px 20px 0px;

  @media (max-width: 768px){
    font-size: 28px;
    text-align: center;
    line-height: 30px;
    margin: 10px 0px 30px 0px;
  }

  @media (max-width: 415px){
    font-size: 26px;
    text-align: center;
    line-height: 30px;
    margin: 0px 0px 20px 0px;
  }
`;

const Summary = styled.span`
  max-width: 500px;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 18px;
  text-align: left;
  line-height: 30px;

  @media (max-width: 768px){
    line-height: 28px;
    font-size: 18px;
  }

  @media (max-width: 415px){
    line-height: 24px;
    font-size: 14px;
  }
`;

const GaugeResult = ({ result }) => (
  <Container>
    <SubContainer>
      <GaugeChart
        id={result.graph}
        style={{ height: 150 }}
        percent={result.data.percent}
        arcsLength={result.boundaries && result.boundaries}
        hideText={true}
        cornerRadius={0}
        colors={['#6DD400', '#FFF700', '#E02020']}
        animDelay={1000}
      />
    </SubContainer>
    <SubTextContainer>
      <Result>{result.statements.state}</Result>
      {result.statements.statement.split('\n').map((string) => (
        <Summary>{string}</Summary>
      ))}
    </SubTextContainer>
  </Container>
);

export default GaugeResult;
