const initState = {
  submissionId: null,
  err: null
};

const TrialReducer = (state = initState, action) => {
  switch (action.type) {
    /* case 'SUBMIT_TRIAL':
      console.log('SUBMIT_TRIAL', action.result);
      return Object.assign({}, state, { result: action.result }); */
    case 'SUBMIT_TRIAL_SUCCESS':
      return {
        ...state,
        submissionId: action.submissionId
      };
    case 'SUBMIT_TRIAL_ERROR':
      return {
        ...state,
        err: action.err.message
      };
      case 'RESET_TRIAL':
        return initState
    default:
      return state;
  }
};

export default TrialReducer;
