import React, { Component } from 'react';
import styled from 'styled-components';
import MainButton from '../components/buttons/MainButton';
import { connect } from 'react-redux';
import { signUp } from '../redux/actions/AuthActions';
import TextInput from '../components/TextInput';
import ChoiceButton from '../components/ChoiceButton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(239, 239, 239);
  padding: 140px 20px 90px 20px;
`;

const FormContainer = styled.div`
  width: 600px;
  padding: 30px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Title = styled.span`
  font-family: 'GodoB';
  font-size: 38px;
  text-align: left;
  margin: 30px 0px;
`;

const Description = styled.span`
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 20px;
  color: white;
  text-align: left;
  line-height: 30px;
`;

const GenderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;


class SignUp extends Component {
  state = {
    gender: ""
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handlePwdConfirm = (e) => {

  }

  handleSelected = ({ value, text }) => {
    this.setState({
      gender: text
    })
  }

  handleSignUp = () => {
    this.props.signUp(this.state);
    this.props.history.goBack();
  };

  render() {
    return (
      <Container>
        <FormContainer>
          <Title style={{ color: 'black', textAlign: 'center' }}>회원가입</Title>

          <TextInput id="email" label="이메일" type="text" required onChange={this.handleChange} />
          <TextInput id="password" label="비밀번호" type="password" required onChange={this.handleChange} />
          <TextInput id="passwordConfirm" label="비밀번호 확인" type="password" required onChange={this.handlePwdConfirm} />
          <TextInput id="name" label="이름" type="text" required onChange={this.handleChange} />
          <GenderContainer>
            <ChoiceButton
              text="여자"
              onSelected={this.handleSelected}
              isSelected={this.state.gender === "여자"}
            />
            <ChoiceButton
              text="남자"
              onSelected={this.handleSelected}
              isSelected={this.state.gender === "남자"}
            />
          </GenderContainer>
          <TextInput id="phone" label="전화번호 ('-' 없이)" type="number" required onChange={this.handleChange} />

          <MainButton
            theme={{ text: 'white', background: 'grey' }}
            onClick={this.handleSignUp}
            text="제출하기"
          />

        </FormContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (userInfo) => dispatch(signUp(userInfo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
