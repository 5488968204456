import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, Label } from 'recharts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0px;
  @media (max-width: 415px){
    padding: 20px 10px 60px 10px;
  }
`;

const ResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px){
    padding: 15px;
    margin-bottom: 10px;
  }

  @media (max-width: 415px){
    margin-bottom: 10px;
   
  }
`;

const ExplanationContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Result = styled.span`
  max-width: 500px;
  font-size: 24px;
  font-family: 'GodoB';
  color: black;
  text-align: center;
  line-height: 40px;

  @media (max-width: 415px){
    font-size: 18px;
    text-align: left;
    line-height: 30px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Point = styled.span`
  max-width: 500px;
  font-size: 18px;
  font-family: 'Nanum Gothic', sans-serif;
  color: black;
  text-align: left;
  line-height: 30px;

  @media (max-width: 415px){
    line-height: 24px;
    font-size: 14px;
  }
`;

const Tick = styled.text`
  font-family: 'GodoB';
  font-size: 18px;
  fill: black;
  @media (max-width: 768px){
    font-size: 16px;
  }

  @media (max-width: 415px){
    font-size: 14px;
  }
`;

const radarColors = ['#84B2D8', '#84D89D', '#D88484', '#D8C784', '#8499D8', '#AE84D8', '#D884B0'];

const getWidthHeight = (device) => {
  switch (device) {
    case "pc":
      return {
        width: 500,
        height: 500,
        outerRadius: 180,
      }
    case "tablet":
      return {
        width: 500,
        height: 400,
        outerRadius: 140,
      }
    case "phone":
      return {
        width: 375,
        height: 300,
        outerRadius: 90,
      }
    default:
      return {
        width: 0,
        height: 0
      }
  }
}

const getXY = ({ index, x, y, textAnchor }) => {
  switch (textAnchor) {
    case 'start':
      return {
        x: x + 3,
        y: y
      }
    case 'end':
      return {
        x: x - 3,
        y: y
      }
    case 'middle':
      return {
        x: x,
        y: index === 0 ? y : y + 10
      }
    default:
      return {

      }
  }
}

const customizedTick = (props) => {
  const xy = getXY(props)
  return (
    <Tick x={xy.x} y={xy.y} textAnchor={props.textAnchor} >
      {props.payload.value}
    </Tick>
  );
};

const customizedLegend = (value, entry) => {
  return <Point>{value}</Point>
}

const RadarResult = ({ result, device }) => {
  const dimension = getWidthHeight(device)
  return <Container>
    {result.statements.one && <ResultContainer>
      <Result>{parse(result.statements.one)}</Result>
    </ResultContainer>}
    <ColumnContainer>
      <RadarChart
        outerRadius={dimension.outerRadius}
        width={dimension.width}
        height={dimension.height}
        data={result.data}
      >
        <PolarGrid />
        <PolarAngleAxis tick={customizedTick} dataKey="name" />
        <PolarRadiusAxis tick={false} axisLine={false} domain={[0, 1]} />
        {result.radars.map((radar, index) => {
          const color = radarColors[index];
          return (
            <Radar
              key={index}
              name={radar}
              dataKey={index}
              stroke={color}
              fill={color}
              fillOpacity={0.6}
              animationBegin={500}
              animationDuration={500}
              animationEasing={'ease-out'}
              legendType={'circle'}
            />
          );
        })}
        {result.radars.length > 1 && <Legend formatter={customizedLegend}/>}
      </RadarChart>
    </ColumnContainer>
    <ColumnContainer>
      <ExplanationContainer>
        {result.statements.two ? (
          result.statements.two.map((statement) => (
            <RowContainer style={{ maxWidth: '600px', margin: '8px' }}>
              <Point>{parse(statement)}</Point>
            </RowContainer>
          ))
        ) : (
            <RowContainer style={{ margin: '8px' }}>
              <Point>{parse(result.statements)}</Point>
            </RowContainer>
          )}
      </ExplanationContainer>
    </ColumnContainer>
  </Container>
};

export default RadarResult;
