import React from 'react';
import styled from 'styled-components';
import DropdownFrame from '../DropdownFrame';

const Container = styled.div`
    width: 500px;
    height: 50px;
`;

const NotificationBoard = (props) => {
    return <DropdownFrame>
        <Container/>    
    </DropdownFrame>
}

export default NotificationBoard