import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    overflow: hidden;
    margin: 10px;
`;

const Input = styled.input`
    height: 50px;
    width: ${props => props.width ? props.width : "100%"};
    font-family: 'GodoB';
    font-size: 16px;
    padding: ${props => props.label ? '20px 15px 8px 15px' : '10px 15px 10px 15px'};
    display: flex;
    flex: 1;
    border: none;
    border-radius: 3px;
    background-color: #e8e8e8;
    border: 1.5px solid transparent;
    &:focus {
        outline: none;
        background-color: transparent;
        border: 1.5px solid black;
    }
    
`;

const Label = styled.label`
    font-family: 'Nanum Gothic', sans-serif;
    color: gray;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 15px;
    transition: 0.2s ease all;
    ${Input}:focus ~ & {
        top: 7px;
        font-size: 10px;
    }
    ${Input}:valid ~ &{
        top: 7px;
        font-size: 10px;
    }
`;

const TextInput = (props) => {
    return (
        <Container>
            <Input
                width={props.width}
                id={props.id}
                label={props.label}
                type={props.type}
                defaultValue={props.defaultValue}
                required={props.required}
                onChange={props.onChange}
            />
            {props.label && <Label>{props.label}</Label>}
        </Container>
    )
}

export default TextInput;