export const uploadReport = (file, submission) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let storageRef = firebase.storage().ref(`reports/${file.name}`)
    let uploadTask = storageRef.put(file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => { },
      (err) => {
        dispatch({ type: 'UPLOAD_REPORT_FAILED', err })
      },
      () => {
        firebase.storage().ref('reports').child(file.name).getDownloadURL()
          .then(url => {
            firestore.collection('submissions').doc(submission.id).set({
              ...submission,
              status: "완료",
              reportUrl: url,
              reportedAt: new Date()
            }).then(res => {
              dispatch({ type: 'UPLOAD_REPORT_SUCCESS' })
            }).catch(err => {
              dispatch({ type: 'UPLOAD_REPORT_FAILED', err })
            })
          })
      }
    )

  };
};
