export const reset = () => {
    return {type: 'RESET_TRIAL'}
}


export const submit = (form) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('trial_submissions')
      .add({
        ...form,
        submittedAt: new Date()
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: 'SUBMIT_TRIAL_SUCCESS', submissionId: res.id});
      })
      .catch((err) => {
        dispatch({ type: 'SUBMIT_TRIAL_ERROR', err });
      });
  };
};
