import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import ExcelDownloadButton from '../../../components/buttons/ExcelDownloadButton';
import Moment from 'moment';
import { uploadReport } from '../../../redux/actions/ReportActions'

const icoUpload = require('../../../res/images/ic_upload.png')
const icoDownload = require('../../../res/images/ic_download.png')

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 56px 0px 0px 320px;
`;

const BoardContainer = styled.div`
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
    background: white;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid black;
  flex-direction: row;
  background: lightgray;
`;

const HeaderText = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  size: 12px;
  color: black;
  text-align: center;
  align-items: center;
  margin: 10px;
  justify-content: center;
  font-family: "GodoB";
`;

const BodyContainer = styled.li`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  flex-direction: row;
`;

const Text = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  size: 12px;
  color: black;
  text-align: center;
  align-items: center;
  margin: 10px;
  justify-content: center;
  font-family: 'Nanum Gothic', sans-serif;
  margin: 1px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 1px;
`;

const Button = styled.a`
    width: 38px;
    height: 38px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0px 20px;
    cursor: pointer;

    &:hover{
        background-color: rgba(0,0,0,0.2)
    }
`;

const ButtonImg = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`;

const UploadButton = styled.input`
    display: none;
`;


class SubmissionBoard extends Component {
    handleChange = (e, submission) => {
        const file = e.target.files[0];
        if (file) {
            this.props.uploadReport(file, submission)
        }
    }

    render() {
        console.log(this.props.submissions)
        return (
            <Container>
                <BoardContainer>
                    <HeaderContainer>
                        <HeaderText>패키지명</HeaderText>
                        <HeaderText>날짜</HeaderText>
                        <HeaderText>상태</HeaderText>
                        <HeaderText></HeaderText>
                    </HeaderContainer>
                    {this.props.submissions && this.props.submissions.map((submission, index) => (
                        <BodyContainer key={index}>
                            <Text>{submission.survey} ({submission.surveyId})</Text>
                            <Text>{Moment(Date(submission.modifiedAt)).format("YYYY-MM-DD")}</Text>
                            <Text>{submission.status}</Text>
                            <ButtonContainer>
                                <ExcelDownloadButton submission={submission} />
                                {!submission.reportUrl 
                                ?
                                <Button>
                                    <label for="upload">
                                        <ButtonImg src={icoUpload}/>
                                    </label>
                                    <UploadButton id="upload" type="file" accept="application/pdf" onChange={e => this.handleChange(e, submission)} />
                                </Button>
                                :
                                <Button href={submission.reportUrl}>
                                    <ButtonImg src={icoDownload}/>
                                </Button>
                            } 

                            </ButtonContainer>
                        </BodyContainer>
                    ))}
                </BoardContainer>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        submissions: state.firestore.ordered.submissions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadReport: (file, submission) => dispatch(uploadReport(file, submission))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{ collection: 'submissions' }])
)(SubmissionBoard);