import React from 'react';
import styled from 'styled-components'
import MainButton from '../../buttons/MainButton'


const Container = styled.div`
    width: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px;
    margin: 5px;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background: white;
`;

const Title = styled.span`
  color: black;
  margin: 10px;
  text-align: left;
  color: black;
  font-family: "GodoB";
  font-size: 32px;
`;

const Instruction = styled.span`
    color: black;
    margin: 10px;
    font-size: 18px;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    line-height: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 10px 10px 10px;
`;

const SurveyEnd = (props) => (
    <Container>
        <Title>모두 완료되었습니다.</Title>
        <Instruction>결과보고서는 세팅에서 확인하실수 있습니다.</Instruction>
        <ButtonContainer>
            <MainButton
                onClick={() => props.onButtonPressed(-1)}
                hideInMobile={true}
                hideArrow={true}
                textColor={"#16569d"}
                backgroundColor={"transparent"}
                style={{ border: "2px solid #16569d", margin: "5px" }}
                text="이전"
            />
            <MainButton
                onClick={() => props.onCompletePressed()}
                hideInMobile={true}
                hideArrow={true}
                style={{ margin: "5px" }}
                text="제출하기"
            />
        </ButtonContainer>
    </Container>
)

export default SurveyEnd;