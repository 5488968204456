import MultipleChoices from "../../components/layouts/options/MultipleChoices";

export const Learning = {
    surveyId: "B",
    title: "학습의 길",
    instruction: "",
    tasks: [
        {
            taskId: "B_0",
            title: "기본면담지",
            instruction: '문항을 읽고 자녀(나)와 유사한 정도를 항목에 표시해 주세요.',
            sections: [
                {
                    sectionId: "B_0_sub01",
                    title: "주호소영역 1",
                    questions: [
                        {
                            questionId: "B_0_001",
                            question: "귀하는 자녀의 어떤 부분에 대해서 걱정되거나 염려되시나요?",
                            instruction: "해당하는 항목 옆 네모 같에 체크",
                            options: {
                                texts: [
                                    '진로/적성 (장래 희망)',
                                    '인지 및 학습 (지능, 공부, 성적)',
                                    '정서 (기분, 감정)',
                                    '대인관계 (친구, 가족관계)',
                                    '주의력 (집중력)',
                                    '성격',
                                    '스트레스',
                                    '신체증상 및 수면 (건강)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_a",
                    title: "주호소영역 1-1",
                    questions: [
                        {
                            questionId: "B_0_002",
                            question: "진로/적성 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 어떤 것에 관심이 있는지 모르겠음',
                                    '자녀가 준비할 수 있는 어떤 직업들이 있는지 잘 모르겠음',
                                    '딱히 하고 싶은 것이 없어보임',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_b",
                    title: "주호소영역 1-2",
                    questions: [
                        {
                            questionId: "B_0_003",
                            question: "인지 및 학습 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 기억하는 것을 어려워함(기억이 잘/자주 안남, 암기하는 것을 어려워함)',
                                    '새로 배우고 익히는 것을 어려워함(배우는 것이 느림, 처음하는 것을 하기 어려워함)',
                                    '지시를 잘 이해하지 못하는 것 같음',
                                    '수업을 잘 따라가지 못하는 것 같음',
                                    '학습 동기가 부족해 보임',
                                    '자녀의 학습전략을 어떻게 세워야 할 지 모르겠음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_c",
                    title: "주호소영역 1-3",
                    questions: [
                        {
                            questionId: "B_0_004",
                            question: "정서 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 기억하는 것을 어려워함(기억이 잘/자주 안남, 암기하는 것을 어려워함)',
                                    '새로 배우고 익히는 것을 어려워함(배우는 것이 느림, 처음하는 것을 하기 어려워함)',
                                    '지시를 잘 이해하지 못하는 것 같음',
                                    '수업을 잘 따라가지 못하는 것 같음',
                                    '학습 동기가 부족해 보임',
                                    '자녀의 학습전략을 어떻게 세워야 할 지 모르겠음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_d",
                    title: "주호소영역 1-4",
                    questions: [
                        {
                            questionId: "B_0_005",
                            question: "대인관계 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 새로운 사람이나 낯선 상황을 힘들어함(처음 겪는 상황에 많이 긴장하고 피곤함을 느낌)',
                                    '친구 사귀는 것에 관심이 없어 보임.',
                                    '또래 아이들과 어울리기를 어려워함(친구들과 친해지기 어려움, 관심 주제가 다름)',
                                    '친구관계에서 갈등이 있음(친구와 자주 싸우거나 괴롭힘을 당함)',
                                    '부모 혹은 형제와 사이가 안좋음(자주 싸움, 말을 안함)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_e",
                    title: "주호소영역 1-5",
                    questions: [
                        {
                            questionId: "B_0_006",
                            question: "주의력 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀는 한 가지 일에 오래 주의를 기울이지 못하고 주의산만함',
                                    '끊임없이 움직이거나 안절부절 못함',
                                    '성미가 급하고 충동적으로 행동함',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_f",
                    title: "주호소영역 1-6",
                    questions: [
                        {
                            questionId: "B_0_007",
                            question: "성격 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀의 타고난 기질이나 성격이 어떤지 궁금함',
                                    '자녀의 성향 때문에 학업이나 친구관계에 어려움이 있는 것 같음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_g",
                    title: "주호소영역 1-7",
                    questions: [
                        {
                            questionId: "B_0_008",
                            question: "스트레스 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀에게 학업 성적에 대한 스트레스가 있음',
                                    '또래관계에 대한 스트레스가 있음',
                                    '가족관계에서의 스트레스가 있음',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub01_h",
                    title: "주호소영역 1-8",
                    questions: [
                        {
                            questionId: "B_0_009",
                            question: "신체증상 및 수면 영역에서 자녀의 어떤 부분이 구체적으로 염려가 되시는지 표시해 주세요.",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '자녀가 (뚜렷한 의학적 원인 없이) 자주 두통을 호소',
                                    '(뚜렷한 의학적 원인 없이) 자주 복통을 호소',
                                    '잠이 들기까지 시간이 매우 오래 걸리고, 자는 동안 자주 깨거나 악몽을 꿈',
                                    '피부 발진이 있음 (ex. 두드러기, 가려움 등)',
                                    '어지럽다고 하거나 이명이 있음 (ex. 귀에서 특정 소리가 들림)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub02",
                    title: "주호소영역 2",
                    questions: [
                        {
                            questionId: "B_0_010",
                            question: "걱정되거나 염려되는 부분이 평균적으로 얼마나 지속되었나요?",
                            options: {
                                value: [0, 1, 2],
                                texts: [
                                    '1-3주 정도',
                                    '1-3개월 정도',
                                    '4개월 이상'
                                ],
                                type: "multiple_choice"
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub03",
                    title: "주호소영역 3",
                    questions: [
                        {
                            questionId: "B_0_011",
                            question: '걱정되거나 염려되는 부분이 자녀에게 미치는 부정적인 영향은 어느 정도인가요?',
                            options: {
                                images: [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                ],
                                texts: [
                                    "10%",
                                    "30%",
                                    "50%",
                                    "70%",
                                    "100%"
                                ],
                                additional: [
                                    "약간 신경이 쓰인다.",
                                    "",
                                    "대체로 신경 쓰인다.",
                                    "",
                                    "매우 많이 신경 쓰인다.\n(잠을 잘 못잔다, 친구관계가 안좋아 졌다, 가족관계가 안좋아졌다, 식욕이 줄었다, 학교생활을 힘들어 한다 등)"
                                ],
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub04",
                    title: "주호소영역 4",
                    questions: [
                        {
                            questionId: "B_0_012",
                            question: '검사를 신청하게 된 이유는 무엇인가요?',
                            options: {
                                type: 'short_answer'
                            }
                        },
                    ]
                },
                {
                    sectionId: "B_0_sub05",
                    title: "주호소영역 5",
                    subSections: [
                        {
                            subSectionId: "B_0_sub05_a",
                            title: "신체적 기능",
                            questions: [
                                {
                                    questionId: "B_0_013",
                                    question: "10분 이상 걷는 것을 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_014",
                                    question: "스포츠 활동(달리기, 구기 종목 등)을 하거나 운동하는 것을 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_015",
                                    question: "무거운 것을 들기 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_016",
                                    question: "쉽게 통증을 느끼는 것 같나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_017",
                                    question: "쉽게 피로를 느끼는 것 같나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_018",
                                    question: '평소 자녀의 건강 및 신체적 기능에 대해 더 이야기 해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "B_0_sub05_b",
                            title: "정서적 기능",
                            questions: [
                                {
                                    questionId: "B_0_019",
                                    question: "쉽게 불안해하거나 겁을 내는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_020",
                                    question: "표정이 어둡거나 울적해보이나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_021",
                                    question: "쉽게 화를 내거나 분노하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_022",
                                    question: "수면에 어려움이 있나요?(잠드는 것, 수면 유지의 어려움 등)",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_023",
                                    question: "자신에게 무슨 일이 일어날지 걱정을 많이 하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_024",
                                    question: '평소 자녀의 기분이나 감정 등에 대해 더 이야기해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "B_0_sub05_c",
                            title: "또래친구들과의 관계",
                            questions: [
                                {
                                    questionId: "B_0_025",
                                    question: "또래 친구들과 잘 지내는 것을 힘들어 하나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_026",
                                    question: "또래 친구들이 자녀와 친구가 되는 것을 꺼리는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_027",
                                    question: "또래 친구들에게 놀림을 많이 받는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_028",
                                    question: "또래 친구들이 일반적으로 할 수 하는 일을 어려워하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_029",
                                    question: "또래 친구들과 놀이나 활동을 할 때 중간에 빠지지 않고 끝까지 함께 시간을 잘 보내는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_030",
                                    question: '평소 자녀의 또래 친구들이나 전반적인 사람들과의 관계에 대해 더 이야기해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "B_0_sub05_d",
                            title: "학교생활",
                            questions: [
                                {
                                    questionId: "B_0_031",
                                    question: "수업에 집중하는 것을 힘들어 하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_032",
                                    question: "자신의 할 일을 잘 잊어버리는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_033",
                                    question: "학교 공부를 따라 가기 힘들어 하는 편인가요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_034",
                                    question: "컨디션이 좋지 않다고 학교에 가지 않으려고 할 때가 있나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_035",
                                    question: "아파서 병원에 가야겠다고 학교에 가지 않으려고 할 때가 있나요?",
                                    options: {
                                        values: [0, 1, 2, 3, 4],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '가끔 그렇다', '자주 그렇다', '매우 자주 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                                {
                                    questionId: "B_0_036",
                                    question: '평소 자녀의 학교 생활에 대해 더 이야기해 주실 부분이 있으면 적어주세요.',
                                    options: {
                                        type: 'short_answer'
                                    }
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            taskId: "B_1",
            title: "웰빙프로파일링",
            instruction: '문항을 읽고 귀하의 자녀가 평소 일상생활에서 보이는 전반적인 특성이나 행동에 가깝다고 생각되는 곳에 표시해 주시기 바랍니다.',
            sections: [
                {
                    sectionId: "B_1_sub01",
                    title: "자율성",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_1_001",
                            question: "나의 자녀는 다른 사람들에 의해 통제받고 억압을 받는다고 느낀다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_004",
                            question: "나의 자녀는 자기 일을 진행하는 방법을 스스로 결정할 기회가 많지 않다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_007",
                            question: "일상생활에서 나의 자녀는 자주 남이 시키는 대로 해야만 한다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_010",
                            question: "나의 자녀는 대체로 자기 생각과 의견을 자유롭게 표현할 수 있다고 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_013",
                            question: "나의 자녀는 자신의 인생을 어떻게 살아갈지 스스로 결정할 수 있다고 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_016",
                            question: "나의 자녀는 어떤 일을 할 때 자기 생각대로 일을 처리하기보다는 다른 사람의 생각이나 처리방식을 따를 때가 많다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_1_sub02",
                    title: "유능성",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_1_002",
                            question: "나의 자녀는 자기 자신이 매우 효율적이라고 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_005",
                            question: "나의 자녀는 대부분 자신이 하는 일들로부터 성취감을 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_008",
                            question: "나의 자녀를 아는 사람은 나의 자녀가 일을 잘한다고 말한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_011",
                            question: "나의 자녀는 자신에게 주어진 일을 잘 해결할 능력이 있다고 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_014",
                            question: "나의 자녀는 자신이 아는 것을 다른 사람에게 가르칠 수 있다고 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_017",
                            question: "나의 자녀는 다른 사람보다 잘하는 것이 많다고 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_1_sub03",
                    title: "관계성",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_1_003",
                            question: "나의 자녀는 주변 사람들로부터 사랑과 관심을 받는 것을 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_006",
                            question: "나의 자녀와 정기적으로 만나는 사람들은 나의 자녀를 별로 좋아하지 않는 것 같다.",
                            options: {
                                values: [5, 4, 3, 2, 1],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_009",
                            question: "나의 자녀는 자신과 만나는 사람들과 잘 지낸다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_012",
                            question: "나의 자녀는 주변 사람들을 정말 좋아한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_015",
                            question: "나의 자녀는 주변 사람들과 평소에 서로 도움을 주고받는다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_1_018",
                            question: "자녀의 주변 사람들은 평소에 자녀와 감정을 공유할 때가 많다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
            ]
        },
        {
            taskId: "B_2",
            title: "학업에 대한 개인적 특성",
            instruction: '문항을 읽고 귀하의 자녀가 평소 일상생활에서 보이는 전반적인 특성이나 행동에 가깝다고 생각되는 곳에 표시해 주시기 바랍니다.',
            sections: [
                {
                    sectionId: "B_2_sub01",
                    title: "무동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_2_003",
                            question: "나의 자녀는 공부를 왜 해야 하는지 모른다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_008",
                            question: "나는 나의 자녀가 학교에서 뭘 하고 있는지 모르겠다. ",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_015",
                            question: "나의 자녀는 왜 학교에 가는지 모르는 것 같고, 솔직히 전혀 신경을 쓰지 않는다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_017",
                            question: "나의 자녀는 솔직히 학교에서 시간을 낭비하는 것 같은 느낌이다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_020",
                            question: "공부는 나의 자녀의 관심사가 아니다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_028",
                            question: "나의 자녀는 인생에서 공부는 중요한 것이 아니라고 생각한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_2_sub02",
                    title: "외적 조절 동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_2_005",
                            question: "나의 자녀는 선생님께서 하라고 시키므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_011",
                            question: "나의 자녀는 부모가 하라고 시키므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_023",
                            question: "나의 자녀는 공부하지 않으면 부모가 화를 내므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_025",
                            question: "나의 자녀는 공부하지 않으면 선생님께서 벌(야단, 체벌)을 주시므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_027",
                            question: "나의 자녀는 공부를 하면 선생님께서 칭찬하시므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_029",
                            question: "나의 자녀는 공부를 하면 부모가 상(용돈, 선물, 칭찬 등)을 주므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_2_sub03",
                    title: "내사된 조절 동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_2_001",
                            question: "나의 자녀는 선생님께 인정받기를 원하기 때문에 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_006",
                            question: "나의 자녀는 부모가 실망하는 것을 원하지 않기 때문에 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_014",
                            question: "나의 자녀는 친구들이 자기를 똑똑한 학생으로 봐주기를 원하므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_018",
                            question: "나의 자녀는 선생님께서 자신을 무시하는 것을 원하지 않으므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_022",
                            question: "나의 자녀는 경쟁상대를 이기기 위해서 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_024",
                            question: "나의 자녀는 성적이 나쁘면 창피하므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_2_sub04",
                    title: "확인된 조절 동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_2_004",
                            question: "나의 자녀는 공부하면서 모르는 것들을 알아가기 위해서 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_009",
                            question: "나의 자녀는 공부하면 실생활에 유용하게 쓰일 수 있으므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_010",
                            question: "나의 자녀는 지식을 쌓아 가는 것은 가치 있는 일이라고 믿기 때문에 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_012",
                            question: "나의 자녀는 수업내용을 이해하는 데 도움이 되므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_016",
                            question: "나의 자녀는 수업시간에 배운 내용을 확인하려고 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_026",
                            question: "나의 자녀는 나중에 공부할 때 좀 더 어려운 내용을 이해하는 데 도움이 되므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_2_sub05",
                    title: "내재적 동기",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_2_002",
                            question: "나의 자녀는 공부하는 것을 즐기므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_007",
                            question: "나의 자녀는 공부하는 것이 재미있으므로 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_013",
                            question: "나의 자녀는 지식을 키우는 것이 재미있어서 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_019",
                            question: "나의 자녀는 모르는 것에 대한 해답을 알고 싶어서 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_021",
                            question: "나의 자녀는 어려운 도전들로부터 기쁜을 얻기 때문에 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_2_030",
                            question: "나의 자녀는 생각하기를 좋아하기 때문에 공부한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['전혀 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
            ]
        },
        {
            taskId: "B_3",
            title: "학업에 대한 환경적 특성 1",
            instruction: '문항을 읽고 평소 자녀 교육에 대한 귀히의 전반적인 특성이나 행동에 가깝다고 생각되는 곳에 표시해주시기 바랍니다.',
            sections: [
                {
                    sectionId: "B_3_sub01",
                    title: "자녀교육 숙달지향성",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_3_001",
                            question: "나는 내 아이가 실수를 하면 그것도 공부의 한 부분이라고 여긴다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_002",
                            question: "나의 내 아이 주변 친구들의 점수에 대해서 별로 신경 쓰지 않는다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_003",
                            question: "나는 문제를 푸는 방법을 내 아이 스스로 터득하기를 바란다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_004",
                            question: "나는 내 아이가 실수를 하더라도 끝까지 최선을 다하라고 격려한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_005",
                            question: "나는 아이에게 주어진 문제들을 아이 스스로 해결하기를 바란다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_006",
                            question: "나는 내 아이가 무엇을 배우고 싶어 하는 지에 관심이 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_007",
                            question: "나는 내 아이가 새로운 것을 배우기를 바란다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_008",
                            question: "나는 내 아이가 실수한 것을 아이 스스로 고칠 기회를 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_3_sub02",
                    title: "자녀교육 결과지향성",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_3_009",
                            question: "나는 내 아이가 높은 등수에 들기 위해서는 친구들과 경쟁해야 한다고 강조한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_010",
                            question: "나는 내 아이에게 소수의 학생들만이 최고 성적을 받을 수 있다는 점을 강조한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_011",
                            question: "나는 내 아이에게 높은 등수에 들기 위해서는 열심히 공부해야 한다고 강조한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_012",
                            question: "나는 내 아이가 주변 친구들만큼 잘하지 못하면 실망한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_013",
                            question: "나는 누가 전교 일등인지 궁금하다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_014",
                            question: "나는 내 아이가 주변 친구들보다 더 높은 성적을 받으면 기분이 좋다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_015",
                            question: "나는 내 아이가 성적을 주변 친구들의 성적과 늘 비교한다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_3_016",
                            question: "나는 내 아이 주변 친구들의 시험성적에 대해 관심이 많다.",
                            options: {
                                values: [1, 2, 3, 4, 5, 6],
                                texts: ['절대로 아니다', '아니다', '약간 아니다', '약간 그렇다', '그렇다', '정말 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ],
                },
            ]
        },
        {
            taskId: "B_4",
            title: "학업에 대한 환경적 특성 2",
            instruction: '문항을 읽고 자녀(나)와 유사한 정도를 항목에 표시해 주세요.',
            sections: [
                {
                    sectionId: "B_4_sub01",
                    title: "학습 선택권 부여",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_4_001",
                            question: "나는 내 마음에 들지 않아도 일단은 자녀가 하고 싶은 자세로 공부하도록 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_006",
                            question: "나는 내 마음에  들지 않아도 일단은 자녀가 하고 싶은 방법으로 공부하도록 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_011",
                            question: "나는 내 마음에 들지 않아도 일단은 자녀가 하고 싶은 시간에 공부하게 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_016",
                            question: "나는 공부에 관한 자녀의 의견을 존중한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_021",
                            question: "나는 공부에 관하여 자녀가 어떤 결정을 내리면 나의 마음에 들지 않아도 가능한 한 받아들여 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_026",
                            question: "나는 내 마음에 들지 않아도 일단은 자녀가 하고 싶은 공부를 할 수 있게 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_031",
                            question: "자녀가 공부에 관한 자신의 의견을 말하면 나의 마음에 들지 않아도 일단은 충분히 검토한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_036",
                            question: "나는 자녀에게 내가 원하는 공부를 시킨다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_041",
                            question: "나는 내 마음에 들지 않아도 일단은 자녀가 하고 싶은 장소에서 공부하게 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_046",
                            question: "나의 과외나 학원을 자녀 스스로 선택하게 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "B_4_sub02",
                    title: "민주적인 규칙 제공",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_4_002",
                            question: "나는 자녀에게 공부하라고 할 때는 자녀가 공부하면서 얼마나 애쓰는지를 충분히 헤아려 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_007",
                            question: "나는 자녀의 성적이 잘못 나왔을 때 자녀 이야기를 들어보지도 않고 화부터 낸다.",
                            options: {
                                vvalues: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_012",
                            question: "나는 내가 기대하는 만큼 공부하는 게 어려운 일이라는 것을 이해하지 못한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_017",
                            question: "나는 공부할 때는 시행착오(목표에 도달할 때까지 실패를 되풀이하는 일)도 겪는다는 점을 충분히 인정한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_022",
                            question: "나는 자녀에게 공부하라고 할 때 명령조로 말한다. ",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_027",
                            question: "나는 왜 공부를 해야 하는지 이해가 가도록 설명해 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_032",
                            question: "나는 아무 설명도 없이, 자녀가 원하는 공부를 하지 못하게 한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_037",
                            question: "나는 내가 소개한 과외나 학원을 거부하면 야단을 친다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_042",
                            question: "나는 자녀가 내가 원하는 만큼 공부하지 않을 때에도 꾸중하지 않고 공부하고 싶지 않은 자녀의 마음을 충분히 헤아려 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_047",
                            question: "나는 자녀의 공부방법이 남에게 피해를 줘서는 안 되다고 말한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ]
                },
                {
                    sectionId: "B_4_sub03",
                    title: "적정성과 추구",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_4_003",
                            question: "나는 자녀가 할 수 있는 한도 내에서 온 힘을 다해 공부하라고 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_008",
                            question: "나는 자녀의 공부목표와 성과를 자녀와 함께 검토하며, 더 알맞은 목표를 세울 수 있도록 도와준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_013",
                            question: "나는 자녀의 성적이 나의 기대에 미치지 못했을 때에도 책임을 추궁하기보다 반복해서 틀리지 않도록 하는 것을 더 중요하게 생각한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_023",
                            question: "나는 자녀의 능력이나 상황을 충분히 고려하지 않고 높은 성과만을 기대한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_028",
                            question: "시럼성적이 나쁘면 공부를 하면서 자녀가 무엇을 어려워하는지를 알아내어 도와주려고 애쓴다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_033",
                            question: "나는 자녀가 감당할 수 없을 만큼 높은 성적을 기대한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_038",
                            question: "자녀가 공부하지 않으면, 나는 나무라기(지적하여 주의하라고 하며 알아듣게 말함)보다는 공부를 하지 않아서 생기는 결과를 함께 의논해 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_043",
                            question: "나는 자녀의 시험성적이 잘못 나오면 자녀가 어떤 부분을 몰라서 성적이 떨어졌는지를 알고자 애쓴다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_048",
                            question: "나는 자녀가 스스로 세운 공부목표를 존중해 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ]
                },
                {
                    sectionId: "B_4_sub04",
                    title: "학업향상 격려",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_4_004",
                            question: "나는 자녀를 다른 아이들과 비교한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_009",
                            question: "나는 자녀가 친구들과 경쟁하여 이길 것을 강조한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_014",
                            question: "나는 공부에 대해 말할 때 “누구보다 잘해라.”라고 말한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_019",
                            question: "나는 자녀를 다른 가정의 자녀와 비교하지 않는다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_024",
                            question: "나는 자녀를 형이나 동생과 비교한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_029",
                            question: "나는 자녀가 아무리 최선을 다해도 성적이 오르지 않으면 실망한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_034",
                            question: "나는 친구들의 자녀보다 나의 자녀가 뒤질까 봐 노심초사(마음속으로 애를 쓰며 속을 태움)한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_039",
                            question: "나는 자기 자신을 이겨내는 것이 무엇보다도 중요하다고 말한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_044",
                            question: "나는 자녀의 성적을 친척들의 성적과 비교한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_049",
                            question: "나는 자녀가 남보다 높은 성적을 받지 못해도 이전보다 향상된 것이 있으면 칭찬을 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ]
                },
                {
                    sectionId: "B_4_sub05",
                    title: "학업정보 제공",
                    instruction: "",
                    questions: [
                        {
                            questionId: "B_4_005",
                            question: "나는 공부 방법에 대한 정보를 모았다가 전해 준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_010",
                            question: "나는 공부와 관련된 정보를 열심히 모은다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_015",
                            question: "나는 공부에 관한 정보를 모았다가 자녀에게 전해준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_020",
                            question: "나는 공부에 도움이 되는 정보를 자녀에게 적극적으로 알려준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_025",
                            question: "나는 자녀의 공부에 도움이 되는 정보를 적극적으로 수집한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_030",
                            question: "나는 진학과 진로에 관한 정보를 모았다가 전해준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_035",
                            question: "나는 공부하는 데 도움이 되는 정보를 잘 알지 못한다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_040",
                            question: "나는 공부에 필요한 각종 정보지를 열심히 읽는다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_045",
                            question: "나는 자녀에게 공부에 대한 정보를 제공해 준 적이 없다.",
                            options: {
                                values: [5, 3, 3, 2, 1],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "B_4_050",
                            question: "나는 자녀가 필요로 하는 정보를 함께 찾아준다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 아니다', '아닌 편이다', '보통이다', '그런 편이다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        }
                    ]
                },
            ]
        },
    ]
}