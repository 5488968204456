const TERMS_OF_SERVICE = {
  title: '서비스 이용약관',
  contents: [
    {
      listTitle: '제1조(목적)',
      subject: [
        {
          title:
            '이 약관은 브레인웨이(이하 “회사”라 함)가 운영하는 “인터넷 웹사이트 (이하 통칭하여 ‘사이트’라 함)”에서 제공하는 인터넷 서비스(이하 “서비스“라고 합니다) 등을 이용함에 있어 “회사”과 “이용자”의 권리•의무 및 책임사항 및 기타 필요한 사항을 규정함을 목적으로 합니다.'
        }
      ]
    },
    {
      listTitle: '제2조(정의)',
      subject: [
        {
          title:
            '1. “사이트”란 회사가 재화 또는 용역(이하 “재화 등”이라고 함)을 이용자에게 제공하기 위하여 컴퓨터, 휴대전화 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이트를 운영하는 사업자의 의미로도 사용합니다.'
        },
        {
          title:
            '2. “이용자”란 “사이트”에 접속하여 이 약관에 따라 “사이트”가 제공하는 “서비스”를 받는 “회원”과 “비회원”을 말합니다.'
        },
        {
          title:
            '3. “회원”이라 함은 “사이트”에 접속하여 이 약관에 따라 “회사”와 “이용계약”을 체결하고 “사이트”가 제공하는 “서비스”를 이용하는 고객을 말합니다.'
        },
        {
          title:
            '4. “비회원”이라 함은 회원에 가입하지 않고 “사이트”가 제공하는 “서비스”를 이용하는 자를 말합니다.'
        },
        {
          title:
            '5. “인터넷 웹사이트”란 개인정보 제공 및 결제를 완료한 이용자가 다양한 정보와 서비스를 제공 받을 수 있도록 회사가 제작하여 운영하는 사이트를 의미합니다.'
        },
        {
          title:
            '6. “휴대폰결제”란 이용자 명의의 휴대폰 인증을 통해서 이동통신사별로 과금되는 소액결제 제도를 말합니다. 회사에서는 모바일 서비스를 이용하는 이용자에 한해서 휴대폰 결제시스템을 제공하고 있습니다.'
        },
        {
          title:
            '이 약관에서 사용하는 용어 중 제 2조에서 정하지 아니한 것은 관계 법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.'
        }
      ]
    },
    {
      listTitle: '제3조(약관의 명시와 개정)',
      subject: [
        {
          title:
            '1. “사이트”는 이 약관의 내용과 상호 및 대표자 성명, 주소, 전화번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호 등을 이용자가 쉽게 알 수 있도록 “사이트”의 초기 서비스화면(전면)에 게시합니다. 다만 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.'
        },
        {
          title:
            '2. “회사”는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회•배송책임•환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.'
        },
        {
          title:
            '3. “사이트”는 전자상거래 등에서의 소비자 보호에 관한법률, 약관의 규제에 관한법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호등에 관한법률, 방문판매등에 관한법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.'
        },
        {
          title:
            '4. “사이트”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 “사이트”의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이전의 유예기간을 두고 공지합니다. 이 경우 “사이트”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.'
        },
        {
          title:
            '5. 회사가 제4항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의사를 표명하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.'
        },
        {
          title:
            '6. “사이트”가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제4항에 의한 개정 약관의 공지기간내에 “사이트”에 송신하여 “사이트”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.'
        },
        {
          title:
            '7. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에 관한법률, 약관의 규제등에 관한법률, 공정거래위원회가 정하는 전자상거래등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.'
        }
      ]
    },
    {
      listTitle: '제4조(서비스의 제공 및 변경)',
      subject: [
        {
          title: '1. “사이트”는 다음과 같은 업무를 수행합니다.',
          contents: [
            '1. 재화 등에 대한 정보 제공 및 구매계약의 체결',
            '2. 구매계약이 체결된 서비스 제공',
            '3. 기타 “사이트”가 정하는 업무'
          ]
        },
        {
          title:
            '2. “사이트”는 재화 등의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 등의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 등의 내용 및 제공일자를 명시하여 현재의 재화 등의 내용을 게시한 곳에 즉시 공지합니다.'
        },
        {
          title:
            '3. “사이트”가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.'
        },
        {
          title:
            '4. “사이트”가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화의 품절 또는 교재 내용의 변경 등의 사유로 변경할 경우에는 “사이트”는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, “사이트”에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.'
        }
      ]
    },
    {
      listTitle: '제5조(서비스의 중단)',
      subject: [
        {
          title:
            '1. “회사”는 통신, 전력 등의 공급이 중단되는 불가피한 경우는 물론 정보통신설비의 보수점검, 증설, 교체, 이전 등의 유지 관리 업무를 수행하기 위해 필요한 경우 “서비스”의 제공을 일시적으로 중단할 경우 7일 이전에 “서비스”의 중단을 공지합니다. 다만, 불가피하게 사전 공지를 할 수 없는 경우 회사는 사후 공지할 수 있습니다.'
        },
        {
          title:
            '2. “회사”는 천재지변, 전쟁, 폭동, 테러, 해킹, DDOS 등 불가항력적 사유로 “서비스”가 중단된 경우 즉시 이러한 사실을 공지하되, 만일 정보통신설비의 작동불능 등의 불가피한 사유로 인해 사전공지가 불가능한 경우에는 이러한 사정이 해소된 이후 즉시 이러한 사실을 공지합니다.'
        },
        {
          title:
            '3.  제1항, 제2항에 의한 “서비스” 중단의 경우에는 “사이트”는 제8조에 정한 방법으로 이용자에게 통지합니다.'
        },
        {
          title:
            '4. “사이트”는 제1항, 제2항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “사이트”에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.'
        },
        {
          title: '5. 기타 부득이 한 사유가 있는 경우'
        }
      ]
    },
    {
      listTitle: '제6조(회원가입)',
      subject: [
        {
          title:
            '1. “이용자”는 “사이트”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.'
        },
        {
          title:
            '2. “사이트”는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.',
          contents: [
            '1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.',
            '2. 등록 내용에 허위, 기재누락, 오기가 있는 경우',
            '3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우'
          ]
        },
        {
          title:
            '3. 회원가입계약의 성립 시기는 “사이트”의 승낙이 “회원”에게 도달한 시점으로 합니다.'
        },
        {
          title:
            '4. “회원”은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “사이트”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.'
        }
      ]
    },
    {
      listTitle: '제7조(회원 탈퇴 및 자격 상실 등)',
      subject: [
        {
          title:
            '1. “회원”은 “사이트”에 언제든지 탈퇴를 요청할 수 있으며 “사이트”는 즉시 회원탈퇴를 처리합니다.'
        },
        {
          title:
            '2. “회원”이 다음 각 호의 사유에 해당하는 경우, “사이트”는 회원자격을 제한 및 정지시킬 수 있습니다.',
          contents: [
            '1. 가입 신청 시에 허위 내용을 등록한 경우',
            '2. “사이트”를 이용하여 구입한 재화 등의 대금, 기타 “사이트”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우',
            '3. 다른 사람의 “사이트” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우',
            '4. “사이트”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우'
          ]
        },
        {
          title:
            '3. “사이트”는 회원자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “사이트”은 회원자격을 상실시킬 수 있습니다.'
        },
        {
          title:
            '4. “사이트”는 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 “회원”에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.'
        }
      ]
    },
    {
      listTitle: '제8조(회원에 대한 통지)',
      subject: [
        {
          title:
            '1. “회원”에 대한 통지를 하는 경우, “회원”이 “사이트”와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.'
        },
        {
          title:
            '2. “사이트”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.'
        }
      ]
    },
    {
      listTitle: '제9조(구매신청 및 개인정보 제공 동의 등)',
      subject: [
        {
          title:
            '1. ”이용자”는 “사이트”에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “이용자”가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.',
          contents: [
            '1. 재화 등의 검색 및 선택',
            '2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력',
            '3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인',
            '4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)',
            '5. 재화등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의',
            '6. 결제방법의 선택'
          ]
        },
        {
          title:
            '2. “사이트”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)'
        },
        {
          title:
            '3. “사이트”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.'
        }
      ]
    },
    {
      listTitle: '제10조(계약의 성립)',
      subject: [
        {
          title:
            '1. “사이트”는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 1. 신청내용에 허위, 기재누락, 오기가 있는 경우 2. 기타 구매신청에 승낙하는 것이 “사이트” 기술상 현저히 지장이 있다고 판단하는 경우'
        },
        {
          title: '2. “사이트”의 구매 약관 동의와 결제가 완료되면 계약의 성립으로 판단합니다.'
        },
        {
          title:
            '3. 계약이 성립한 후 “사이트”가 제 1항 각 호의 사유를 발견한 경우 “사이트”는 즉시 계약을 취소 할 수 있으며 계약 취소 시 고객이 결제한 상품 대금은 즉시 환불처리 됩니다.'
        }
      ]
    },
    {
      listTitle: '제11조(대금지급방법)',
      subject: [
        {
          title:
            '“사이트”에서 구매한 재화 등에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.'
        },
        {
          title:
            '단, “사이트”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.',
          contents: [
            '1. 폰뱅킹, 인터넷뱅킹, 메일뱅킹 등의 각종 계좌이체',
            '2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제',
            '3. 온라인무통장입금',
            '4. 전자화폐에 의한 결제',
            '5. 기타 전자적 지급 방법에 의한 대금 지급 등'
          ]
        }
      ]
    },
    {
      listTitle: '제12조(재화 등의 공급)',
      subject: [
        {
          title:
            '1. “사이트”는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “사이트”가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 “사이트”는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.'
        },
        {
          title:
            '2. “사이트”는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “사이트”가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만, “사이트”가 고의•과실이 없음을 입증한 경우에는 그러하지 아니합니다.'
        },
        {
          title:
            '3. “사이트”와 고객간에 상품의 인도시기 및 용역의 제공시기에 관하여 별도의 약정이 있는 경우에는 당해 약정이 우선합니다.'
        }
      ]
    },
    {
      listTitle: '제13조(환급)',
      subject: [
        {
          title:
            '“사이트”는 이용자가 구매신청 한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.'
        }
      ]
    },
    {
      listTitle: '제14조(청약철회 등)',
      subject: [
        {
          title:
            '1. “사이트”와 재화 등의 구매에 관한 계약을 체결한 이용자는 기본적으로 청약철회가 불가능하지만 서비스 이용 장애등에 의한 경우에는 가능합니다.'
        },
        {
          title:
            '2. 이용자는 재화 등을 배송받은 경우 다음 각 호의 경우에는 청약철회 및 교환을 할 수 없습니다.',
          contents: [
            '1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회 등을 할 수 있습니다.)',
            '2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우',
            '3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우',
            '4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우',
            '5. “사이트”가 특정 재화 등에 대하여 청약철회 제한에 관해 사전에 고지한 경우.'
          ]
        },
        {
          title:
            '3. 제2항 제2호 내지 제4호의 경우에 “사이트”가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.'
        },
        {
          title:
            '4. 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시•광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급 받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.'
        }
      ]
    },
    {
      listTitle: '제15조(청약철회 등의 효과)',
      subject: [
        {
          title:
            '1. “사이트”는 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급 받은 재화 등의 대금을 환급합니다. 이 경우 “사이트”가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.'
        },
        {
          title:
            '2. “사이트”는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.'
        },
        {
          title:
            '3. 청약철회 등의 경우 공급 받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “사이트”는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시.광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “사이트”가 부담합니다.'
        },
        {
          title:
            '4. 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “사이트”는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.'
        }
      ]
    },
    {
      listTitle: '제16조(개인정보 취급방침)',
      subject: [{ title: '현 사이트 내에 있는 개인정보 취급방침 적용' }]
    },
    {
      listTitle: '제17조(“사이트”의 의무)',
      subject: [
        {
          title:
            '1. “사이트”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고 안정적으로 재화•용역을 제공하는 데 최선을 다하여야 합니다.'
        },
        {
          title:
            '2. “사이트”는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 합니다.'
        },
        {
          title:
            '3. “사이트”가 상품이나 용역에 대하여 「표시•광고의공정화에관한법률」제3조 소정의 부당한 표시•광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.'
        },
        {
          title:
            '4. “사이트”는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.'
        }
      ]
    },
    {
      listTitle: '제18조(“이용자”의 대한 의무)',
      subject: [
        {
          title:
            '1. “사이트” 내에서 제공하는 소개 자료나 샘플 이외에 설문조사 관련 자료는 유포가 불가능합니다.'
        }
      ]
    },
    {
      listTitle: '제19조(“이용자”의 의무)',
      subject: [
        {
          title: '이용자는 다음 행위를 하여서는 안됩니다.',
          contents: [
            '1. 신청 또는 변경 시 허위내용의 등록',
            '2. 타인의 정보 도용',
            '3. “사이트”에 게시된 정보의 변경',
            '4. “사이트”가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시',
            '5. “사이트” 및 기타 제3자의 저작권 등 지적재산권에 대한 침해',
            '6. “사이트” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위',
            '7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위'
          ]
        }
      ]
    },
    {
      listTitle: '제20조(연결 “회사” 의 사이트와 “타사”의 사이트간의 관계)',
      subject: [
        {
          title:
            '1. “회사”의 사이트와 “타사”의 사이트간의 연결이란 “회사”가 제공하는 사이트와 “타사”가 제공하는 사이트가 하이퍼 링크(예:하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨) 방식 등으로 연결된 경우, 전자를 연결 “사이트”(웹사이트)이라고 하고 후자를 피연결 “사이트”(웹사이트)이라고 합니다.'
        },
        {
          title:
            '2. “회사”는 “타사”의 사이트와 연결된 사이트가 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을 “회사”의 사이트 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.'
        }
      ]
    },
    {
      listTitle: '제21조(저작권의 귀속 및 이용제한)',
      subject: [
        {
          title: '1. ”회사”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “사이트”에 귀속합니다.'
        },
        {
          title:
            '2. 이용자는 “사이트” 또는 “서비스”를 이용함으로써 얻은 정보 중 “회사”에게 지적재산권이 귀속된 정보를 “사이트”의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.'
        },
        {
          title:
            '3. ”사이트”는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.'
        }
      ]
    },
    {
      listTitle: '제22조(분쟁해결)',
      subject: [
        {
          title:
            '1. “사이트”는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치•운영합니다.'
        },
        {
          title:
            '2. “사이트”는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 줍니다.'
        },
        {
          title:
            '3. “사이트”와 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시•도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.'
        }
      ]
    },
    {
      listTitle: '제23조(재판권 및 준거법)',
      subject: [
        {
          title:
            '1. “사이트”와 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.'
        },
        {
          title: '2. “사이트”와 이용자간에 제기된 전자상거래 소송에는 한국법을 적용합니다.'
        }
      ]
    },
    {
      listTitle: '부칙',
      subject: [
        { title: '부칙 (2018.01.01)' },
        { title: '이 약관은 2018년 1월 1일부터 시행합니다.' }
      ]
    }
  ]
};

export default TERMS_OF_SERVICE;
