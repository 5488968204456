import React, { Component } from 'react';
import styled from 'styled-components';
import * as ROUTES from '../../../values/Routes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import db from '../../../Firebase';
import TableHeader from '../../../components/boards/TableHeader';
import TableItem from '../../../components/boards/TableItem';
import ExcelDownloadButton from '../../../components/buttons/ExcelDownloadButton';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 56px 0px 0px 320px;
`;

const BoardContainer = styled.div`
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
    background: white;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid black;
  flex-direction: row;
  background: lightgray;
`;

const HeaderText = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  size: 12px;
  color: black;
  text-align: center;
  align-items: center;
  margin: 10px;
  justify-content: center;
  font-family: "GodoB";
`;

const BodyContainer = styled.li`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  flex-direction: row;
  cursor: pointer;

  &:hover{
      background-color: rgba(0,0,0,0.2)
  }
`;

const Text = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  size: 12px;
  color: black;
  text-align: center;
  align-items: center;
  margin: 10px;
  justify-content: center;
  font-family: 'Nanum Gothic', sans-serif;
  margin: 1px;
`;

class Users extends Component {

    renderUsers = (user, index) => {
        return (
            <tr>
                <td>{user.email}</td>
                <td>{user.name}</td>
                <td>{user.gender}</td>
                <td>{user.phone}</td>
            </tr>
        )
    }

    render() {
        console.log(this.props);
        const { auth, users } = this.props;

        if (!auth.uid) {
            //return <Redirect to={ROUTES.SIGN_IN}/>
        }

        return (
            <Container>
                <BoardContainer>
                    <HeaderContainer>
                        <HeaderText>이름</HeaderText>
                        <HeaderText>계정이메일</HeaderText>
                        <HeaderText>성별</HeaderText>
                        <HeaderText>연락처</HeaderText>
                    </HeaderContainer>
                    {users && users.map((user, index) => {
                        return <BodyContainer key={index} onClick={() => console.log(user.id)}>
                            <Text>{user.name}</Text>
                            <Text>{user.email}</Text>
                            <Text>{user.gender}</Text>
                            <Text>{user.phone}</Text>
                        </BodyContainer>
                    })}
                </BoardContainer>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        users: state.firestore.ordered.users,
    }
}

const mapDispatchToProps = (state) => {
    return {

    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{ collection: "users" }])
)(Users)