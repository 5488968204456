import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 720px;
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: ${(props) => props.src && `url(${props.src})`};
  background-position: center;
  background-size: cover;
`;

const Title = styled.span`
  font-size: 40px;
  text-align: center;
  color: white;
  font-family: 'GodoB';
  padding: 10px 10px 10px 10px;
`;

const SubTitle = styled.span`
  width: 500px;
  font-size: 18px;
  text-align: center;
  color: white;
  font-family: 'Nanum Gothic', sans-serif;
  line-height: 30px;
  margin: 20px 0px 0px 0px;
`;

const Header = (props) => {
  return (
    <Container {...props}>
      <Title>{props.title}</Title>
      {props.subtitle && <SubTitle>{props.subtitle}</SubTitle>}
    </Container>
  );
};

export default Header;
