import React, {Component} from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

class NoticeBoard extends Component {
    render(){
        const { notices } = this.props;
        return(
            <div>
                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const notices = state.firestore.ordered.notices;
    return {
        notices: notices
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([{collection: 'notices', orderBy: ['createdAt', 'desc']}])
)(NoticeBoard)