import React from 'react';
import styled from 'styled-components';

const ToggleButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
`;

const ToggleMiddle = styled.div`
  width: 24px;
  height: 2px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 100px;
`;

const DrawerToggleButton = (props) => {
  return (
    <ToggleButton onClick={props.onClick}>
      <ToggleMiddle backgroundColor={props.isTop ? 'white' : 'black'} />
      <ToggleMiddle backgroundColor={props.isTop ? 'white' : 'black'} />
      <ToggleMiddle backgroundColor={props.isTop ? 'white' : 'black'} />
    </ToggleButton>
  );
};

export default DrawerToggleButton;
