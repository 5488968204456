import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px;

  @media (max-width: 1000px){
    margin: 30px;

  }

  @media (max-width: 768px){
    margin: 30px 0px;
  }

  @media (max-width: 415px){
    margin: 10px 0px;
  }

`;

const ImageContainer = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: ${props => props.imgHeight ? props.imgHeight : '100px'};
`;

const Title = styled.span`
  font-family: 'GodoB';
  font-size: 28px;
  color: black;
  margin: 14px 0px;

  @media (max-width: 1000px){

}

  @media (max-width: 768px){
    
  }

  @media (max-width: 415px){
    margin: 0px;
    font-size: 22px;
    line-height: 40px;
  }

`;

const Description = styled.span`
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 18px;
  color: black;
  text-align: left;
  line-height: 30px;
   
  @media (max-width: 1000px){
    font-size: 16px;
    line-height: 28px;
  }
  
  @media (max-width: 768px) {
    max-width: 300px;
  }

  @media (max-width: 415px){
    padding: 10px 30px;
    font-size: 14px;
    line-height: 26px;
  }
`;

const PathwayContainer = (props) => (
  <Container {...props}>
    <ImageContainer>
      <Image
        height={props.imgHeight}
        src={require(`../../res/images/${props.imageName}.png`)}
        srcSet={`${require(`../../res/images/${props.imageName}@2x.png`)} 2x, ${require(`../../res/images/${props.imageName}@3x.png`)} 3x`}
      />
    </ImageContainer>
    <Title>{props.name}</Title>
    <Description>{props.desc}</Description>
  </Container>
);

export default PathwayContainer;
