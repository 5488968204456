import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import COLORS from '../../../values/Colors';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0px;
  @media (max-width: 415px){
    padding: 40px 10px 60px 10px;
  }
`;

const RowContainer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 5px;
`;

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BarContainer = styled(ColumnContainer)`
  padding-right: 60px;
  margin-bottom: 10px;
`;


const Number = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background-color: ${COLORS.primary};
  color: white;
  font-family: 'GodoB';
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px){
    margin-right: 16px;
  }

  @media (max-width: 415px){
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
`;

const Point = styled.span`
  max-width: 500px;
  display: flex;
  flex: 1;
  font-size: 18px;
  font-family: 'Nanum Gothic', sans-serif;
  text-align: left;
  line-height: 30px;

  @media (max-width: 415px){
    font-size: 14px;
    line-height: 24px;
  }
`;

const Tick = styled.text`
  font-family: 'GodoB';
  font-size: 18px;
  fill: black;
  @media (max-width: 768px){
    font-size: 16px;
  }

  @media (max-width: 415px){
    font-size: 14px;
  }
`;


const getWidthHeight = (device) => {
  switch (device) {
    case "pc":
      return {
        width: 500,
        height: 300,
        top: 20,
        bottom: 20,
        y: 25
      }
    case "tablet":
      return {
        width: 380,
        height: 300,
        top: 20,
        bottom: 20,
        y: 25
      }
    case "phone":
      return {
        width: 300,
        height: 200,
        top : 10,
        bottom: 20,
        y: 20
      }
    default:
      return {
        width: 0,
        height: 0,
        top : 0,
        bottom: 20,
        y: 0,
      }
  }
}

const customizedTick = (props, dimension) => {
  return (
    <Tick x={props.x} y={props.y + dimension} textAnchor={props.textAnchor} >
      {props.payload.value}
    </Tick>
  );
};

const BarGraphResult = ({ result, device }) => {
  const dimension = getWidthHeight(device)
  return <Container>
    <BarContainer>
      <BarChart
        width={dimension.width}
        height={dimension.height}
        data={result.data}
        margin={{
          top: dimension.top,
          bottom: dimension.bottom,
        }}
      >
        <XAxis dataKey="name" tick={ props => customizedTick(props, dimension.y)}/>
        <YAxis
          domain={[0, 100]}
          allowDecimals={false}
          tick={ props => customizedTick(props, 0)}
        />
        <Tooltip />
        <Bar dataKey="percent" fill="#8884d8">
          <Cell key={1} fill={'#84B2D8'} />
          <Cell key={2} fill={'#84D89D'} />
          <Cell key={3} fill={'#D884B0'} />
        </Bar>
      </BarChart>
    </BarContainer>
    <ColumnContainer>
      {Array.isArray(result.statements) ? (
        result.statements.map((statement, index) => (
          <RowContainer>
            <Number>{index + 1}</Number>
            <Point>{parse(statement)}</Point>
          </RowContainer>
        ))
      ) : (
          <RowContainer>
            <Point>{parse(result.statements)}</Point>
          </RowContainer>
        )}
    </ColumnContainer>
  </Container>
};

export default BarGraphResult;
