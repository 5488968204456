import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from '../values/Colors';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import MainButton from '../components/buttons/MainButton';
import VideoContainer from '../components/layouts/VideoContainer';
import MAIN from '../values/pages/Main';
import IntroVideo from '../res/videos/brainway_intro2.mp4';
import PathwayContainer from '../components/layouts/PathwayContainer';
import SectionContainer from '../components/layouts/SectionContainer';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Section = styled.div`
  width: 100%;
  min-height: 720px;
  padding: 120px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){

  }

  @media (max-width: 415px){
    padding: 80px 30px;
  }

`;

const Title = styled.text`
  max-width: 595px;
  font-size: 36px;
  text-align: center;
  color: ${(props) => props.theme.text};
  font-family: 'GodoB';
  line-height: 55px;

  
  @media (max-width: 1000px){

  }
  
  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 415px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

const Gap = styled.div`
  height: 20px;
  width: 100%;
`;

const SubTitle = styled.text`
  max-width: 595px;
  font-size: 18px;
  text-align: center;
  color: ${(props) => props.theme.text};
  line-height: 32px;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 15px;
    line-height: 28px;
    text-align: left;
  }
  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
  }
`;

const greyTheme = {
  text: 'white',
  background: 'grey'
};

const whiteTheme = {
  text: 'black',
  background: 'white'
};

const PathwaySection = styled.div`
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
    
  }

  @media (max-width: 415px){

  }

`;

const PathwaySectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  margin: 10px 0px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LogoSection = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

const LogoSectionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;
  margin: 30px;
  
  @media (max-width: 1000px){
    width: 180px;
    height: 80px;
  }
  
  
  @media (max-width: 768px) {
    width: 160px;
    height: 60px;
    margin: 20px;
  }
`;

const EmptyLogoSpace = styled.div`
  height: 30px;
`;

const EmptyPathwaySpace = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CarouselContainer = styled.div`
  width: 600px;
  margin-top: 80px;
  background-color: transparent;
  
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 415px){
    width: 100%;
  }
`;

const SampleImage = styled.img`
  padding: 20px;
  object-fit: contain;
  background-color: transparent;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const EmphasisContainer = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-self: center;
    margin-bottom: 20px;

    @media (max-width: 768px){
      margin-bottom: 15px;
    }

    @media (max-width: 415px){
      margin-bottom: 10px;
    }
`;

const EmphasisText = styled.span`
  max-width: 595px;
  font-size: 21px;
  text-align: center;
  color: black;
  line-height: 50px;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 768px){
    font-size: 18px;
  }

  @media (max-width: 415px){
    font-size: 18px;
  }
`;

class Main extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleButton = (direction) => {
    this.props.history.push(direction);
  };

  renderPathways = (pathways) => {
    let columns = [];
    let rows = [];

    pathways.map((path, index) => {
      columns.push(<PathwayContainer imageName={path.image} name={path.name} desc={path.desc} />);

      if (columns.length % 3 === 0) {
        console.log(index);
        rows.push(<PathwaySectionRow>{columns}</PathwaySectionRow>);
        columns = [];
      }
    });

    if (columns.length > 0) {
      for (let i = 0; i < 3 - columns.length; i++) {
        columns.push(<EmptyPathwaySpace />);
      }
      rows.push(<PathwaySectionRow>{columns}</PathwaySectionRow>);
    }

    return rows;
  };

  renderPartners = (logos) => {
    let columns = [];
    let rows = [];

    logos.map((logo, index) => {
      columns.push(<Logo src={require(`../res/images/${logo}.png`)} />);

      if (columns.length % 3 === 0) {
        rows.push(<LogoSectionRow>{columns}</LogoSectionRow>);
        columns = [];
      }
    });

    if (columns.length > 0) {
      for (let i = 0; i < 3 - columns.length; i++) {
        columns.push(<EmptyLogoSpace />);
      }
      rows.push(<LogoSectionRow>{columns}</LogoSectionRow>);
    }

    return rows;
  };

  render() {
    return (
      <Container>
        <VideoContainer src={IntroVideo}>
          {MAIN.section1.title.split('\n').map((title) => (
            <Title theme={greyTheme}>{title}</Title>
          ))}
          <Gap />
          {MAIN.section1.subtitle.split('\n').map((subtitle) => (
            <SubTitle theme={greyTheme}>{subtitle}</SubTitle>
          ))}
        </VideoContainer>

        <Section>
          <EmphasisContainer>
            <EmphasisText>브레인웨이만의</EmphasisText> 
            <EmphasisText style={{color: `${COLORS.primary}`, fontWeight: '900', marginLeft: '5px'}}>차별성</EmphasisText>
            <EmphasisText>!</EmphasisText> 
          </EmphasisContainer>

          {MAIN.section2.title.split('\n').map((title) => (
            <Title theme={whiteTheme}>{title}</Title>
          ))}
          <Gap />
          {MAIN.section2.subtitle.split('\n').map((subtitle) => (
            <SubTitle theme={whiteTheme}>{subtitle}</SubTitle>
          ))}

          <PathwaySection>
            {this.renderPathways(MAIN.section2.paths)}
          </PathwaySection>

          <ButtonContainer>
            <MainButton to="/forest" text="자세히 알아보기" />
          </ButtonContainer>
        </Section>

        <SectionContainer bgImage={require('../res/images/bg_report_sample.png')}>
          {MAIN.section3.title.split('\n').map((title) => (
            <Title theme={greyTheme}>{title}</Title>
          ))}
          <Gap />
          {MAIN.section3.subtitle.split('\n').map((subtitle) => (
            <SubTitle theme={greyTheme} >
              {subtitle}
            </SubTitle>
          ))}
          <CarouselContainer>
            <Carousel
              showThumbs
              showIndicators={false}
              showStatus={false}
              showArrows
              centerSlidePercentage={60}
              centerMode
              swipeable
              infiniteLoop
              autoPlay
              emulateTouch
              stopOnHover
              swipeScrollTolerance={3}
            >
              {MAIN.section3.samples.map((sample, index) => (
                <SampleImage key={index} src={require(`../res/images/${sample}.png`)} />
              ))}
            </Carousel>
          </CarouselContainer>
        </SectionContainer>

        <Section theme={whiteTheme}>
          <EmphasisContainer>
            <EmphasisText>브레인웨이의</EmphasisText> 
            <EmphasisText style={{color: `${COLORS.primary}`, fontWeight: '900', marginLeft: '5px'}}>전문성</EmphasisText>
            <EmphasisText>!</EmphasisText> 
          </EmphasisContainer>

          {/* <Stamp src={require("../res/images/img_best_partner_stamp.png")} /> */}
          {MAIN.section4.title.split('\n').map((title) => (
            <Title theme={whiteTheme}>{title}</Title>
          ))}
          <Gap />
          {MAIN.section4.subtitle.split('\n').map((subtitle) => (
            <SubTitle theme={whiteTheme}>{subtitle}</SubTitle>
          ))}
          <LogoSection>{this.renderPartners(MAIN.section4.logos)}</LogoSection>
        </Section>
      </Container>
    );
  }
}

export default Main;
