import React, { Component } from 'react';
import styled from 'styled-components';
import MainButton from '../components/buttons/MainButton';
import { connect } from 'react-redux';
import { login } from '../redux/actions/AuthActions';
import COLORS from '../values/Colors';
import TextInput from '../components/TextInput';

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 1020px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(239, 239, 239);
`;

const FormContainer = styled.div`
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
`;

const LoginContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 2;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 10px 0px 0px 10px;
  padding: 50px;
`;

const SignUpContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex: 1.5;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  border-radius: 0px 10px 10px 0px;
  background-color: rgba(0, 0, 0, 0.6);
`;

const SignUpBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 0px 10px 10px 0px;
  padding: 50px 40px;
`;

const Title = styled.span`
  font-family: 'GodoB';
  font-size: 38px;
  text-align: left;
  margin: 30px 0px;
`;

const Description = styled.span`
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 20px;
  color: white;
  text-align: left;
  line-height: 30px;
`;

const TextButton = styled.button`
  background: transparent;
  color: ${COLORS.primary};
  font-family: 'GodoB';
  font-size: 16px;
  border: 0px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 16px;
`;

class Login extends Component {
  state = {
    email: '',
    password: ''
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.authError !== this.props
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleLogin = () => {
    this.props.login(this.state);
  };

  render() {
    return (
      <Container>
        <FormContainer>
          <LoginContainer>
            <Title style={{ color: 'black', textAlign: 'center' }}>로그인</Title>
            <TextInput
              id="email"
              label="이메일"
              type="text"
              required
              onChange={this.handleChange}
            />
            <TextInput
              id="password"
              label="비밀번호"
              type="password"
              required
              onChange={this.handleChange}
            />
            <div style={{ flex: 1, display: 'flex' }} />
            <TextButton>비밀번호를 잃어버리셨나요?</TextButton>
            <div style={{ flex: 1, display: 'flex' }} />
            <MainButton
              style={{ padding: '18px 100px' }}
              theme={{ text: 'white', background: 'grey' }}
              hideArrow={true}
              onClick={this.handleLogin}
              text="로그인"
            />
            <div style={{ flex: 1, display: 'flex' }} />
            <div style={{ flex: 1, display: 'flex' }} >
              {this.props.authError && <ErrorMessage>{this.props.authError}</ErrorMessage>}
            </div>
          </LoginContainer>
          <SignUpContainer bgImage={require('../res/images/bg_report_sample.png')}>
            <Overlay>
              <SignUpBody>
                <Title style={{ color: 'white' }}>처음오셨나요?</Title>
                <Description style={{ color: 'white' }}>
                  브레인웨이에 회원가입 하시고 더 많은 해택을 누려보세요.
                </Description>
                <div style={{ flex: 10, display: 'flex' }} />
                <MainButton
                  border="2px solid white"
                  backgroundColor="transparent"
                  hideArrow={true}
                  to="/signup"
                  text="회원가입"
                />
                <div style={{ flex: 1, display: 'flex' }} />
              </SignUpBody>
            </Overlay>
          </SignUpContainer>
        </FormContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (loginInfo) => dispatch(login(loginInfo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
