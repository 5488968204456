const ABOUT = {
  title: '우리 ‘I’를 찾아주세요',
  subtitles: [
    "우리 “I” 는 '나' 자신에서부터 우리 “I(아이)”들인 '자녀'의 의미까지 포함합니다.",
    '아동, 청소년, 성인이라는 성장과정을 거치면서 우리의 모든 하루하루는 처음입니다. 그래서 대부분 자신의 성향과 적성을 찾기 위해 많은 시간을 투자하고 시행착오와 어려움도 겪게 됩니다. 이러한 과정에서 쏟게되는 시간과 에너지를 절약할 수 있다면 조금 더 효율적이고 여유가 있는 인생을 가꾸어나갈수 있지않을까요?',
    '브레인웨이는 임상경험과 과학적 결과를 기반으로, 우리 “I”의 뇌인지성향과 심리 발달에 대한 개개인 맞춤형 분석을 통하여, 자아형성 뿐만 아니라 학습과 진로적성에 대한 브레인 코칭과 컨설팅을 제공해 드립니다.'
  ],
  hashtags:
    '#소통 #발달 #성장 #코칭 #자기관리 #진로 #적성 #인지 #학습 #Communication #Development #Growth #Brain-Coaching #Self-Management',
  banner: {
    title: '이 분야 최고의 전문가들과 함께 합니다.',
    subtitle:
      '20년간 뇌과학 기반 개인 맞춤형 임상심리 평가와 분석을 전문으로 한 연구진들과 함께 합니다.'
  },
  brainway: {
    logo: '',
    text:
      '브레인 웨이는 풍부한 임상 및 연구 경험을 바탕으로 한 신경심리,임상심리, 뇌과학 분야의 전문가들로 구성되어 있으며, 자녀와 부모의 행복한 삶을 지향하고 있습니다. 부모 자신, 자녀들, 우리 가족들에 대한 깊이 있는 이해를 통해서, 좀더 유익하고 행복한 하루하루를 만들어 나가도록 함께 하고자 합니다. 이를 위해 뇌인지 및 심리 발달 성향에 대한 다양한 연구소, 회사들과 연계하고 있으며, 학습 및 진로적성 등에 대한 더욱 더 전문적이고 실질적인 도움이 되는 코칭 및 컨설팅 프로그램들을 개발하기 위해 연구하고 있습니다'
  },
  partners: [
    {
      institute: '임상신경심리랩',
      affiliation: '연세대학교 의과대학',
      logo: 'logo_yprid',
      texts:
        ['임상심리학 박사이자 임상심리전문가 엄소용 교수는, 현재 연세대학교의과대학에 재직중이며, 임상신경심리를 주제로 하는 연구랩(뇌전증연구소 산하)을 운영 중입니다. 신경심리학, 뇌신경과학, 인지신경발달에 관한 연구를 주로 진행하며, 특히 소아청소년 심리, 소아청소년 인지신경심리, 아동발달심리, 부모교육, 소아청소년의 뇌과학 기반 진로적성에 관련 연구 및 임상 주제에 대한 전문가입니다.'],
      link: ''
    },
    {
      institute: '소아청소년과\n소아신경클리닉',
      affiliation: '연세대학교 의과대학 소아과학교실',
      logo: 'logo_gangnam_severance',
      texts:
        ['임상의학자와 기초연구자, 의공학연구자로 이루어진 네트워크가 협업하여 다학제적 임상정보 교류를 통해 소아청소년과 영역에서의 신경 및 심리질환에 대한 연구를 진행하고 있습니다. 다양한 연구경험과 임상적 지식을 기반으로 최고 수준의 소아청소년의 신경, 심리 연구를 수행합니다.'],
      link: 'http://medicine.yonsei.ac.kr/class_subject/clinic_class/child/intro_class/'
    },
    {
      institute: 'SLAM LAB',
      affiliation: 'The Ohio State University,\nCognitive and Brain Science',
      logo: 'logo_slam_lab',
      texts:
        ['인지 신경 과학자 이윤상 교수는 다트머스 대학에서 박사 학위를 받고 유펜 의대, 오하이오 주립대를 거쳐 현재 텍사스 주립대 (달라스)에서 조교수로 재직 중입니다. 그간 음악과 언어 처리에 대한 뇌의 메카니즘에 대해 다수의 뉴로 이미징 논문들을 발표하였으며, 최근에는 미국 국립 보건원으로부터 펀딩을 받아 음악(리듬) 앱을 활용한 인지/언어 장애 재활 프로그램을 개발 하고 있습니다. 더욱 자세한 연구 정보는 SLAM (Speech, Language, & Music) Lab 홈페이지를 참조하시기 바랍니다'],
      link: 'https://osu-slam.github.io/'
    },
    {
      institute: 'CNL LAB',
      affiliation: '연세대학교 심리학과',
      logo: 'logo_cnl',
      texts:
        ['사람의 마음과 행동의 인지 구조와 신경 구조를 연구합니다. 구체적으로는 기억력이 어떻게 형성되고 업데이트 되는지, 자기에 대한 인식이 어떻게 나타나는지, 어떤 종류의 신경회로가 이러한 정신적 경험의 바탕에 깔려 있는지 등에 대해 연구합니다.'],
      link: 'http://cogneuro.or.kr'
    },
    {
      institute: '의료정보학교실',
      affiliation: '아주대학교 의과대학',
      logo: 'logo_aju',
      texts:
        ['의과대학에서 필요한 기본적인 교육뿐 아니라 정보학과의 융합을 통한 연구 활동을 하고 있습니다. 의학부 교육으로는 IT 기반 기술인 정보학분야의 다양한 강의를 주관하고 있습니다.'],
      link: 'https://ts.ajoumc.or.kr/Main/MainType04.aspx?cp=1&sid=&rc=10&ssc=0020&ssgc=UH&CCP=1'
    },
    {
      institute: '오비이랩',
      affiliation: '',
      logo: 'logo_obelab',
      texts:
        ['카이스트 연구실 창업기업 오비이랩에서 NIRS (근적외선분광분석) 라는 기술을 활용하여 초고해상도의 뇌영상을 사용편의성이 뛰어난 웨어러블과 태블릿을 통해 제공하는 장비를 개발하여 현재 한국, 미국, 유럽 등 전세계에서 연구 및 임상을 진행하고 있습니다.'],
      link: 'https://obelab.com'
    },
    {
      institute: '아이메디신',
      affiliation: '',
      logo: 'logo_imedisync',
      texts:
        ['AI뇌파플랫폼기업으로서, 치매를 위시한 주요 신경정신질환의 뇌파 바이오마커를 개발.보급하여 예방적.통합적 멘탈케어를 제공합니다. 1,300명의 엄선된 건강인 남녀노소 EEG Norm DB 를 가지고 있으며, 기억장애형 경도인지장애(aMCI) 진단용 보조시스템인 ‘아이싱크브레인(iSyncBrain-M)’은 중앙대병원, 분당서울대병원, 강원대병원과 함께 90% 수준의 선별 정확도로 2등급 의료기기로 허가를 받았습니다.'],
      link: 'https://www.imedisync.com/'
    },
    {
      institute: `Reader's Eye`,
      affiliation: '',
      logo: 'logo_readerseye',
      texts:[
        `Reader’s Eye®는 시선추적기법 (eye tracking)을 이용하여 읽기능력을 과학적으로 분석하는 서비스를 제공합니다. 글을 읽는 동안의 안구의 움직임, 눈동자 크기의 변화, 눈깜박임 빈도 등을 분석하여 체계적으로 읽기능력을 진단함으로써, 읽기 행태의 문제점을 파악하고 개선할 수 있습니다. 또한 시선에 드러나는 읽기 및 행동 고위험군을 조기에 파악할 수 있습니다.`,
        `* 본 솔루션은 서울시 교육청 초등교육과와 실증사업 진행중입니다.`,
      ],
      link: 'https://www.readerseye.com'
    },
    {
      institute: 'Brain & Research Innovation',
      affiliation: '',
      logo: 'logo_bnr',
      texts:
        ['브레인앤리서치 (Brain&Research)는 과학적이고 객관적인 방법으로 고객의 데이터를 측정하고 정량화하여 마케팅 및 제품 기획 전략 수립에 기여하고 있습니다. 이를 위하여 일반적 설문과 인터뷰를 포함하여 심리 측정, 행동 관찰 및 생체 신호 측정 등 과학적이고 객관적인 방법을 활용하여, 제품 / 서비스의 유용성 / 디자인 / 품질 / 이미지 평가의 분야에서 전문 지식과 경험을 가지고 있습니다. 다수의 대기업, 공공기관, 대학 및 방송사 등에 서비스를 제공하고 있습니다.'],
      link: 'https://bnr.co.kr/'
    },
  ]
};

export default ABOUT;
