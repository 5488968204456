import React, { Component } from 'react';
import styled from 'styled-components';
import * as ROUTES from '../../values/Routes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { uploadPackage, updatePackage } from '../../redux/actions/AdminActions';
import { Redirect } from 'react-router-dom';

import { Cognition } from '../../values/databases/Cognition'
import { Communication } from '../../values/databases/Communication'
import { Learning } from '../../values/databases/Learning'
import { Healing } from '../../values/databases/Healing'

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 56px 0px 0px 320px;
`;

const BoardContainer = styled.div`
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
    background: white;
`;

class AdminTools extends Component {

    renderButton = (doc, packageContent, buttonText) => {
        return <button onClick={() => this.props.updatePackage(doc, packageContent)}>{buttonText}</button>
    }

    render() {
        const { auth } = this.props;
        console.log(this.props);

        if (!auth.uid) {
            return <Redirect to={ROUTES.SIGN_IN} />
        }

        return (
            <Container>
                <BoardContainer>
                    {this.renderButton(this.props.cognition, Cognition, "인지의길")}
                    {this.renderButton(this.props.learning, Learning, "학습의길")}
                    {this.renderButton(this.props.communication, Communication, "소통의길")}
                    {this.renderButton(this.props.healing, Healing, "힐링의길")}
                </BoardContainer>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    const packages = state.firestore.ordered.packages;
    let props = {}

    if (packages) {
        packages.map(value => {
            switch (value.surveyId) {
                case 'A':
                    props = {
                        ...props,
                        cognition: value.id,
                    }
                    break;
                case 'B':
                    props = {
                        ...props,
                        learning: value.id,
                    }
                    break;
                case 'C':
                    props = {
                        ...props,
                        communication: value.id,
                    }
                    break;
                case 'D':
                    props = {
                        ...props,
                        healing: value.id,
                    }
                    break;
                default:
                    break;
            }
        })
    }

    return {
        ...props,
        auth: state.firebase.auth,
        packages: state.firestore.ordered.packages,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadPackage: (pack) => dispatch(uploadPackage(pack)),
        updatePackage: (doc, pack) => dispatch(updatePackage(doc, pack))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{ collection: 'packages' }])
)(AdminTools)