import React from 'react';
import styled from 'styled-components';
import PathwayContainer from './PathwayContainer';

const Container = styled.div`
    display: none;
    
    @media (max-width: 768px){
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 0px;
    }

    @media (max-width: 415px){
        padding: 60px 0px;
    }
`;

const MainContainer = styled.div`
    width: 90%;
    display : flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    @media (max-width: 415px){
        flex-direction: column;
    }
`;

const PathwayButton = styled(PathwayContainer)`
    justify-content: flex-start;
    align-items: center;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    margin: 10px 10px;

    @media (max-width: 415px){
        margin: 10px 0px;
    }
`;

const Title = styled.span`
  max-width: 500px;
  font-size: 30px;
  text-align: center;
  color: black;
  font-family: 'GodoB';
  line-height: 40px;
  margin: 20px 0px 40px 0px;

  @media (max-width: 415px) {
    font-size: 24px;
    line-height: 40px;
    margin: 20px 0px 30px 0px;

  }
`;

const PathToButton = (props) => {
    return <Container>
        <Title>성장의 숲 바로가기</Title>
        <MainContainer>
            <PathwayButton
                to={`/forest/cognition`}
                imageName={`img_cognition`}
                name='인지의 길'
            />
            <PathwayButton
                to={`/forest/learning`}
                imageName={`img_learning`}
                name='학습의 길'
            />
        </MainContainer>
        <MainContainer>
            <PathwayButton
                to={`/forest/communication`}
                imageName={`img_communication`}
                name='소통의 길'
            />
            <PathwayButton
                to={`/forest/healing`}
                imageName={`img_healing`}
                name='힐링의 길'
            />
        </MainContainer>
        <MainContainer>
            <PathwayButton
                to={`/forest/neuroscience`}
                imageName={`img_neuroscience`}
                name='브레인 뉴로사이언스 랩'
            />
        </MainContainer>
    </Container>
}

export default PathToButton;