import React from 'react';
import styled from 'styled-components';
import ChoiceButton from '../ChoiceButton';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 20px 0px;
    padding: 30px 20px;
    border-radius: 3px;
  }

  @media (max-width: 415px){
    margin: 0px 0px 10px 0px;
    padding: 5px;
  }
`;

const QuestionContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 10px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    padding: 10px 10px;
    margin-bottom: 10px;
  }

  @media (max-width: 415px){
    padding: 10px;
    margin-bottom: 5px;
  }
`;

const Question = styled.span`
  font-size: 22px;
  text-align: left;
  font-family: 'GodoB';
  line-height: 38px;

  @media (max-width: 768px){
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 415px){
    font-size: 16px;
    line-height: 24px;
  }
`;

const Number = styled(Question)`
  margin-right: 10px;

  @media (max-width: 415px){
    margin-right: 5px;
  }
`;

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px;

  @media (max-width: 768px) {
    justify-content: space-around;
    padding: 0px;
    margin-top: 0px;
  }

  @media (max-width: 415px){
    flex-direction: column;
    margin-top: 0px;
    padding: 0px 12px 10px 12px;
  }
`;

const SurveyQuestion = ({ number, question, choices, onSelected, selected }) => {
  return (
    <Container>
      <QuestionContainer>
        <Number>{number}.</Number>
        <Question>{question.text}</Question>
      </QuestionContainer>
      <ChoicesContainer>
        {choices.texts &&
          choices.texts.map((choiceText, index) => {
            let values = choices.values;
            if (question.reversed) {
              values = values.sort((a, b) => b - a);
            }
            return (
              <ChoiceButton
                key={index}
                value={values[index]}
                text={choiceText}
                isSelected={selected && values[index] === selected.choice}
                onSelected={(value) => onSelected(value)}
              />
            );
          })}
      </ChoicesContainer>
    </Container>
  );
};

export default SurveyQuestion;
