import React from 'react';
import styled from 'styled-components';
import COLORS from '../values/Colors';

const icoCheck = require('../res/images/check_white.png');
const icoCheck2x = require('../res/images/check_white@2x.png');
const icoCheck3x = require('../res/images/check_white@3x.png');


const Container = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.radius ? props.radius : '100px'};
  border: ${ props => props.isSelected ? 'none' : '1px solid #d4d4d4'};
  background-color: ${props => props.isSelected ? COLORS.primary : 'none'};

  &:hover {
    background-color: ${props => props.isSelected ? COLORS.primary : 'none'};
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    border: 4px solid ${props => props.isSelected ? COLORS.primary : 'none'};
    margin-bottom: 10px;
  }

  @media (max-width: 415px){
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
  }
`;

const Check = styled.img`
  width: 12px;
  height: 12px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 415px){
    width: 12px;
    height: 12px;
  }
`;

const CheckBox = (props) => (
    <Container {...props}>
        {props.isSelected && <Check src={icoCheck} srcSet={`${icoCheck2x} 2x, ${icoCheck3x} 3x`} />}
    </Container>
)

export default CheckBox