import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import COLORS from '../../values/Colors';

const imageArrow = require('../../res/images/arrow_white.png');
const imageArrow2x = require('../../res/images/arrow_white@2x.png');
const imageArrow3x = require('../../res/images/arrow_white@3x.png');

const imageArrowBlue = require('../../res/images/arrow_blue.png');
const imageArrowBlue2x = require('../../res/images/arrow_blue@2x.png');
const imageArrowBlue3x = require('../../res/images/arrow_blue@3x.png');

const ButtonContainer = styled(Link)`
  display: flex;
  padding: 18px 40px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.border ? props.border : '0px')};
  background: ${(props) => (props.background ? props.background : COLORS.primary)};
  cursor: pointer;
  z-index: 1;

  @media (max-width: 768px) {
    display: ${(props) => (props.hideInMobile ? 'none' : 'flex')};
  }

  @media (max-width: 415px){
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

const ButtonText = styled.span`
  display: flex;
  color: ${(props) => (props.textColor ? props.textColor : 'white')};
  font-size: 14px;
  font-family: 'Nanum Gothic', sans-serif;
  font-weight: 700;
  cursor: pointer;
`;

const Arrow = styled.img`
  width: 16px;
  height: 14px;
  object-fit: contain;
  margin-left: 20px;
`;

const MainButton = (props) => {
  const { to, text, hideInMobile, backgroundColor, textColor, onClick, hideArrow, disable} = props;
  return (
    <ButtonContainer
      {...props}
      to={to}
      hideInMobile={hideInMobile}
      onClick={!disable && onClick}
      background={disable ? 'lightgrey' : backgroundColor}
    >
      <ButtonText textColor={textColor}>{text}</ButtonText>

      {!hideArrow && (
        <Arrow
          src={backgroundColor !== 'white' ? imageArrow : imageArrowBlue}
          srcSet={`${backgroundColor !== 'white' ? imageArrow2x : imageArrowBlue2x} 2x, ${
            backgroundColor !== 'white' ? imageArrow3x : imageArrowBlue3x
          } 3x`}
        />
      )}
    </ButtonContainer>
  );
};

export default MainButton;
