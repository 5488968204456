import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { deleteNotice } from '../../../redux/actions/NoticeActions';

class NoticeDetails extends Component {

    handleDelete = (noticeId) => {
        this.props.deleteNotice(noticeId)
        // TODO: Go back to notice board!
    }

    render() {
        const { notice } = this.props;
        if (notice) {
            return (
                <div>

                </div>
            )
        } else {
            return (
                <div>

                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const notices = state.firestore.data.notices;
    const notice = notices ? notices[id] : null
    return {
        notice: notice
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteNotice: (noticeId) => dispatch(deleteNotice(noticeId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{ collection: 'notices' }])
)(NoticeDetails)