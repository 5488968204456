import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from '../../values/Colors'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import TitleContainerWithWhite from '../../components/layouts/TitleContainerWithWhite';
import MainButton from '../../components/buttons/MainButton';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 415px){
    background-color: ${COLORS.primary}
  }

`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 144px;
  background: white;
  margin-top: 200px;
  padding: 100px 0px;
  border-radius: 16px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
    width: 90%;
    margin-top: 140px;
    border-radius: 10px;
    justify-content: center;
    
  }

  @media (max-width: 415px){
    width: 100%;
    margin-top: 72px;
    margin-bottom: 0px;
    border-radius: 0px;

  }
`;

const Title = styled.text`
  font-size: 40px;
  text-align: center;
  color: black;
  padding: 10px 10px 10px 10px;
  font-family: 'GodoB';

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
      font-size: 30px;
      line-height: 40px;
  }

  @media (max-width: 415px){

  }
  
`;

const SubTitle = styled.text`
  max-width: 600px;
  font-size: 18px;
  text-align: center;
  color: black;
  line-height: 26px;
  text-align: left;
  padding: 10px;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
      font-size: 15px;
      line-height: 28px;
      width: 80%;
  }

  @media (max-width: 415px){

  }

`;

const Logo = styled.img`
  height: auto;
  display: flex;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
      height: 140px;
  }

  @media (max-width: 415px){

  }
`;

const PointSection = styled.div`
  width: 100%;
  max-width: 800px;
  min-height: 200px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 50px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
  background: white;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
      width: 85%;
      margin: 10px 0px;
      border: 1.5px solid rgba(0,0,0,0.1);
      box-shadow: none;
  }

  @media (max-width: 415px){
    padding: 30px;
  }

`;

const PointTitle = styled.span`
  font-size: 24px;
  font-family: 'GodoB';
  text-align: left;
  line-height: 50px;
  margin-bottom: 10px;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
    font-size: 21px;
    line-height: 40px;
  }

  @media (max-width: 415px){

  }
`;

const Point = styled.span`
  text-align: left;
  font-size: 18px;
  font-family: 'Nanum Gothic', sans-serif;
  line-height: 30px;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
    font-size: 15px;
    line-height: 28px;
  }

  @media (max-width: 415px){

  }
`;

const RowContainer = styled.div`
  width: 800px;
  display: flex;
  flex-direction: row;
  margin: 80px 0px;


  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
    margin: 40px 0px;
    width: 100%;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 415px){

  }

`;

const PartnerContainer = styled.div`
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FixedImage = styled.img`
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
`;

const Overlay = styled.div`
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
`;

class LearningIntro extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { auth, surveys } = this.props;
    let surveyId = surveys && surveys[0].id
    return (
      <Container>
        <FixedImage src={require('../../res/images/bg_forest.png')} />
        <Overlay />
        <MainContainer>
          <PartnerContainer>
            <Logo
              style={{ marginBottom: '50px' }}
              src={require('../../res/images/img_learning.png')}
              srcSet={`${require('../../res/images/img_learning@2x.png')} 2x, ${require('../../res/images/img_learning@3x.png')} 3x`}
            />
            <Title>학습의 길</Title>
            <SubTitle style={{ maxWidth: '620px' }}>
              때로는 비슷해 보이기도 하지만 각각 다르고 매우 특별한 우리 아이들. 우리 아이의 적성과
              특성에 따라 제공되어야 할 가이드라인과 이어지는 성취는 달라질 수 있습니다. 학습의
              길에서 우리 아이의 학업 준비도와 성향을 체크하고 가장 적합한 가이드라인을 함께 찾아
              봅시다.
            </SubTitle>
          </PartnerContainer>

          <RowContainer>
            <PointSection>
              <PointTitle>학습에 대한 환경적 특성</PointTitle>
              <Point>
                학습에 대한 부모의 지원적 유형 특성을 살펴보고, 어떻게 자녀 학습에 영향을 주고 있는
                지 평가합니다.
              </Point>
            </PointSection>
            <PointSection>
              <PointTitle>학습에 대한 개인적 특성</PointTitle>
              <Point>학습에 필요한 개인의 능력인 행동 및 동기 조절 특성에 대해 평가합니다. </Point>
            </PointSection>
          </RowContainer>

          <ButtonContainer>
            <MainButton
              to='/trial/learning'
              hideArrow={true}
              textColor={"blue"}
              backgroundColor={"transparent"}
              style={{ width: "260px", border: "1px solid blue", margin: "10px" }}
              text="체험하기"
            />
            {/* {surveyId ? 
            <MainButton
              to={!auth.uid ? { pathname: '/login' } : { pathname: `/survey/${surveyId}` }}
              hideInMobile={true}
              hideArrow={true}
              style={{ width: "260px", margin: "10px" }}
              text="시작하기"
            />
            :
            <MainButton
              hideInMobile={true}
              hideArrow={true}
              style={{ width: "260px", margin: "10px" }}
              text="준비 중"
            />
            } */}
          </ButtonContainer>
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    surveys: state.firestore.ordered.packages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "packages", where: ['surveyId', '==', 'B'] }])
)(LearningIntro);

