import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { CSVLink } from 'react-csv';

const Container = styled.div``;

const ButtonContainer = styled.div``;

const ButtonText = styled.span``;

const ButtonImage = styled.img``;

class Questions extends Component {
  render() {
    const { sets } = this.props;
    const csvData2 = [
      { firstname: 'Ahmed', lastname: 'Tomi', email: 'ah@smthing.co.com' },
      { firstname: 'Raed', lastname: 'Labes', email: 'rl@smthing.co.com' },
      { firstname: 'Yezzi', lastname: 'Min l3b', email: 'ymin@cocococo.com' }
    ];
    console.log(sets);
    return (
      <Container>
        {sets &&
          sets.map((trial) => (
            <Link to={{ pathname: `/questions/${trial.trial}`, trial: JSON.stringify(trial) }}>
              <ButtonContainer>
                <ButtonImage />
                <ButtonText>{trial.name}</ButtonText>
              </ButtonContainer>
            </Link>
          ))}
        <CSVLink
          style={{
            border: '1px solid blue',
            padding: '20px'
          }}
          data={csvData2}
          filename="data.csv"
        >
          CSV 다운로드
        </CSVLink>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sets: state.firestore.ordered.trial_sets
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'trial_sets' }])
)(Questions);
