import React, { Component } from 'react';
import styled from 'styled-components';
import TitleContainer from '../components/layouts/TitleContainer';
import FOREST from '../values/pages/Forest';
import COLORS from '../values/Colors';
import PathToTree from '../components/layouts/PathToTree';
import PathToButton from '../components/layouts/PathToButton';
import Main from './Main';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px){
    padding: 60px 40px;
  }

  @media (max-width: 415px){
    padding: 60px 0px;
  }
`;

const Title = styled.span`
  max-width: 595px;
  font-size: 36px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : 'white')};
  font-family: 'GodoB';
  line-height: 50px;
  
  @media (max-width: 1000px){

  }

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 415px) {
    width: 100%;
    max-width: 80%;
    font-size: 24px;
    line-height: 40px;
  }
`;

const SubTitle = styled.span`
  font-size: 18px;
  max-width: 595px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : 'white')};
  font-family: 'Nanum Gothic', sans-serif;
  line-height: 30px;
  margin: 20px 0px 0px 0px;

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 15px;
    line-height: 28px;
    text-align: left;
  }
  @media (max-width: 415px) {
    max-width: 80%;
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    margin: 10px 0px 0px 0px;
  }
`;

const PathTitle = styled(Title)`
  font-size: 26px;
  line-height: 30px;

  @media (max-width: 415px){
      max-width: none;
  }
`;

const PathSubTitle = styled(SubTitle)`
  margin: 10px 0px 0px 0px;
  
  @media (max-width: 415px){
    max-width: none;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 100px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};

  @media (max-width: 415px){
    padding: 60px 0px;
  }
`;

const Section = styled.div`
  display: flex;
  max-width: 900px;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  justify-content: center;
  align-items: center;
  margin: 20px 0px;

  @media (max-width: 1000px){
    max-width: 800px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 415px){
    margin: 0px 0px;
  }
`;

const Image = styled.img`
  width: 340px;
  padding: 20px;
  border-radius: 25px;
  
  @media (max-width: 1000px){
    width: 300px;
  }

  @media (max-width: 768px) {
    width: 280px;
    padding: 0px;
  }
  @media (max-width: 415px) {
    width: 220px;
  }
`;

const LongImage = styled.img`
  width: 500px;
  margin-top: 40px;
  @media (max-width: 768px){
    padding: 30px;
  }

  @media (max-width: 415px){
    width: 80%;
    padding: 0px;
  }
`;

const PathImage = styled.img`
  width: 340px;
  height: 100%;
  border-radius: 10px;
  
  @media (max-width: 1000px){
    width: 300px;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 415px) {
    width: 80%;
    border-radius: 3px;
  }
`;

const PointContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;

  @media (max-width: 768px){
    padding: 20px 30px;
  }

  @media (max-width: 415px){
    padding: 16px 30px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;

  @media (max-width: 768px){
    padding: 20px 30px;
  }

  @media (max-width: 415px){
    width: 100%;
    padding: 16px 0px;
  }
`;

const PathIntroContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  padding: 20px 30px;

  @media (max-width: 768px){
    padding: 20px 10px;
    width: 80%;
    align-self: center;
  }
`;

const Number = styled.span`
  display: flex;
  width: 50px;
  height: 50px;
  font-size: 21px;
  border-radius: 50%;
  background-color: #3588df;
  color: white;
  font-family: 'GodoB';
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px){
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  @media (max-width: 768px){
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  @media (max-width: 415px){
    width: 36px;
    height: 36px;
    font-size: 14px;
    margin-right: 5px;
  }
`;

const Point = styled.span`
  display: flex;
  flex: 1;
  font-size: 24px;
  text-align: center;
  font-family: 'GodoB';
  line-height: 30px;
  margin: 0px 0px 0px 20px;
  color: black;
  font-weight: '800';
  text-align: left;

  @media (max-width: 1000px){
    font-size: 22px;

  }

  @media (max-width: 768px){
    font-size: 21px;
  }

  @media (max-width: 415px){
    font-size: 18px;
    margin: 0px 0px 0px 10px;
  }
`;

const Line = styled.div`
  display: none;
  
  @media (max-width: 768px){
    width: 80%;
    height: 1px;
    background: rgba(255,255,255,0.5);
    margin-top: 20px;
    margin-bottom: 60px;
    display: flex;
  }
`;

class Forest extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container>
        <TitleContainer bgImage={require('../res/images/bg_forest.png')}>
          <MainContainer>
            {FOREST.section1.title.split('\n').map((title) => (
              <Title>{title}</Title>
            ))}
            <SubTitle>{FOREST.section1.subtitle}</SubTitle>
          </MainContainer>
        </TitleContainer>
        <SectionContainer>
          <Section direction="row">
            <Image src={require('../res/images/img_rainbow_kid.png')} />
            <TextContainer>
              {FOREST.section2.title.split('\n').map((title) => (
                <Title style={{ color: 'black', textAlign: 'left' }}>{title}</Title>
              ))}
              <SubTitle style={{ color: 'black', textAlign: 'left' }}>
                {FOREST.section2.subtitle}
              </SubTitle>
            </TextContainer>
          </Section>
          <Section style={{ alignItems: 'flex-start', marginTop: '20px' }}>
            {FOREST.section2.points.map((point, index) => (
              <PointContainer style={{ flexDirection: 'row' }}>
                <Number>{index + 1}</Number>
                <Point>
                  {point}
                </Point>
              </PointContainer>
            ))}
          </Section>
          <LongImage
            src={require('../res/images/img_symbolic.png')}
          />
        </SectionContainer>

        <SectionContainer style={{ backgroundColor: `${COLORS.primary}` }}>
          <Section style={{ flexDirection: 'row' }}>
            <TextContainer style={{ alignItems: 'center' }}>
              {FOREST.section3.title.split('\n').map((title) => (
                <Title>{title}</Title>
              ))}
              <SubTitle style={{ textAlign: 'center' }}>{FOREST.section3.subtitle}</SubTitle>
            </TextContainer>
          </Section>
        </SectionContainer>

        <SectionContainer>
          <Section direction="row">
            <Image src={require('../res/images/img_brain_searching.png')} />
            <TextContainer>
              <Title style={{ color: 'black', textAlign: 'left' }}>{FOREST.section4.title}</Title>
              <SubTitle style={{ color: 'black', textAlign: 'left', fontWeight: '700' }}>
                {FOREST.section4.subtitle}
              </SubTitle>
              <SubTitle style={{ color: 'black', textAlign: 'left' }}>
                {FOREST.section4.paragraph}
              </SubTitle>
            </TextContainer>
          </Section>
        </SectionContainer>

        <SectionContainer style={{ backgroundColor: `${COLORS.primary}` }}>
          <Section style={{ flexDirection: 'column' }}>
            <TextContainer style={{ alignItems: 'center' }}>
              <Title style={{ marginBottom: "10px" }}>{FOREST.section5.title}</Title>
              <SubTitle style={{ textAlign: 'center' }}>{FOREST.section5.subtitle}</SubTitle>
            </TextContainer>

            {FOREST.section5.intro.map((intro, index) => (
              <Section direction="row">
                {index !== 0 && <Line />}
                <PathImage src={require(`../res/images/${intro.icon}.png`)} />
                <PathIntroContainer>
                  {intro.title.split('\n').map((title) => (
                    <PathTitle style={{ textAlign: 'left' }}>
                      {title}
                    </PathTitle>
                  ))}
                  <PathSubTitle style={{ textAlign: 'left' }}>
                    {intro.desc}
                  </PathSubTitle>
                </PathIntroContainer>
              </Section>
            ))}
          </Section>
        </SectionContainer>

        <PathToButton />
        <PathToTree />
      </Container>
    );
  }
}

export default Forest;
