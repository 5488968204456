import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 20px 0px;
`;

const Title = styled.span`
  color: black;
  margin: 10px;
  text-align: left;
  color: black;
  font-family: "GodoB";
  font-size: 22px;
  line-height: 36px;
`;

const Instruction = styled.span`
    color: black;
    margin: 10px 10px 20px 10px;
    font-size: 14px;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    line-height: 30px;
`;

const SurveySubSection = (props) => {
  return (
    <Container>
      <Title>{props.subSection.title}</Title>
      {props.subSection.instruction && <Instruction>{props.subSection.instruction}</Instruction>}
      {props.children}
    </Container>
  );
};

export default SurveySubSection;