import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
    width: 100%;
    display: flex;
    padding: 10px 20px;
    margin: 5px 0px;
    background-color: ${props => props.isSelected ? "rgba(0,0,0,0.05)" : "transparent"};
    justify-content: flex-start;
    border-radius: 10px;
    &:hover{
        background-color: rgba(0,0,0,0.05)
    }
`;

const IconContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 0px solid transparent;
    background-color: rgba(0,0,0,0.05);
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`;

const Title = styled.span`
    font-family: 'GodoB';
    font-size: 16px;
    align-self: center;
    margin: 0px 10px 0px 20px;
    color: black;
`;

const NotificationIcon = styled.div`
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
`;


const TabButton = (props) => (
    <Container
        to={props.to}
        isSelected={props.isSelected}
        onClick={props.onClick}
    >
        <IconContainer>
            <Icon src={props.src} />
        </IconContainer>
        <Title isSelected >{props.title}</Title>
        {props.isNew && <NotificationIcon />}
    </Container>
)

export default TabButton;