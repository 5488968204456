import React from 'react';
import styled from 'styled-components';
import ChoiceButton from '../../ChoiceButton';
import ChoiceInput from '../../ChoiceInput';

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-top: 10px;
  }
`;

const MultipleSelections = (props) => {
  const { options, onInput, value } = props;
  return <ChoicesContainer>
    {options.texts &&
      options.texts.map((optText, index) => {
        if (optText === '기타') {
          return <ChoiceInput
            key={index}
            radius={"5px"}
            isSelected={value && value.find(a => a.includes(optText))? true: false}
            text={optText}
            onSelected={selected => {
              console.log('selected', selected);
              const answer = value ? value : [];
              console.log('answer', answer)
              const indexFound = answer.findIndex(a => a.includes(optText))
              if(selected.checked){
                if(indexFound === -1){
                  answer.push(selected.text)
                }else {
                  answer[indexFound] = selected.text
                }
              }else {
                answer.splice(indexFound, 1)
              }
              return onInput({
                type: options.type,
                answer: answer
              })
            }}
          />
        }
        return (
          <ChoiceButton
            radius={"5px"}
            key={index}
            text={optText}
            isSelected={value && value.includes(optText)}
            onSelected={selected => {
              let answer = value ? value : [];
              const indexFound = answer.indexOf(selected.text)
              if(indexFound !== -1){
                answer.splice(indexFound, 1);
              } else {
                answer.push(selected.text)
              }
              return onInput(
                {
                  type: options.type,
                  answer: answer
                })
            }}
          />
        );
      })}
  </ChoicesContainer>
}

export default MultipleSelections