import { getFirebase } from "react-redux-firebase";

export const uploadPackage = (pack) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('packages').add({
            ...pack,
            modifiedAt: new Date()
        })
            .then(res => {
                dispatch({ type: 'UPLOAD_SUCCESS' });
            })
            .catch(err => {
                dispatch({ type: "UPLOAD_FAILED", err });
            })
    }
}

export const updatePackage = (doc, pack) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('packages')
            .doc(doc)
            .set(pack)
            .then(res => {
                dispatch({type: 'UPDATE_SUCCESS'});
            })
            .catch(err => {
                dispatch({type: 'UPDATE_FAILED', err});
            });
    }
}