export const MAIN = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/login';
export const ACCOUNT = '/account';
export const ABOUT = '/about';
export const CENTER = '/center';
export const FOREST = '/forest';
export const TRIAL = '/trial/:id';
export const COGNITION = '/forest/cognition';
export const LEARNING = '/forest/learning';
export const COMMUNICATION = '/forest/communication';
export const HEALING = '/forest/healing';
export const NEUROSCIENCE = '/forest/neuroscience';
export const RESULT = '/result/:id';
export const SUBMISSIONS = '/submissions';
export const QUESTIONS = '/questions';
export const ACTIVITIES = '/activities';
export const FAQ = '/faq';
export const TERMS = '/terms';
export const PRIVACY_POLICY = '/privacy_policy';
export const NOTICE_BOARD = '/notice';
export const NOTICE_DETAIL = '/notice/:id';
export const NOTICE_FORM = '/notice/form/:id';
export const SUBMISSION_BOARD = '/mybrainway/submission';
export const SUBMISSION_DETAIL = '/my/brainway/submission/:id';
export const USER_INFO = '/mybrainway/:id';
export const USERS = '/admin/users';
export const REPORT_BOARD ='/admin/reports';
export const SURVEY ='/survey/:id';
export const ADMIN_TOOLS = '/admin/tools';