import React from "react";
import styled from "styled-components";
import MainButton from '../../buttons/MainButton'

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 50px;
    margin: 5px;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background: white;
`;

const Title = styled.span`
  color: black;
  margin: 10px;
  text-align: left;
  color: black;
  font-family: "GodoB";
  font-size: 32px;
`;

const Instruction = styled.span`
    color: black;
    margin: 10px;
    font-size: 18px;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    line-height: 30px;
`;

const Line = styled.div`
  width: 100%;
  height: 4px;
  background-color: black;
  border-radius: 100px;
  margin: 10px;
  align-self: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const ThinLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0,0,0,0.1);
  border-radius: 100px;
  margin: 10px;
  align-self: center;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 10px 10px 10px;
`;

const SurveyTask = (props) => {
  const { task } = props;
  return (
    <Container>
      <Title>{task.title}</Title>
      {task.instruction && <Instruction>{task.instruction}</Instruction>}
      <Line />
      {props.children}
      <ThinLine/>
      <ButtonContainer>
        <MainButton
          onClick={() => props.onButtonPressed(-1)}
          hideInMobile={true}
          hideArrow={true}
          textColor={"#16569d"}
          backgroundColor={"transparent"}
          style={{ border: "2px solid #16569d", margin: "5px" }}
          text="이전"
        />
        <MainButton
          onClick={() => props.onButtonPressed(1)}
          hideInMobile={true}
          hideArrow={true}
          style={{ margin: "5px" }}
          text="다음"
        />
      </ButtonContainer>
    </Container>
  );
};

export default SurveyTask;
