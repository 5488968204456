import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    min-width: 300px;
    max-height: 700px;
    position: absolute;
    top: 45px;
    right: 0px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    overflow: auto;
`;

const DropdownFrame = (props) => {
    return <Container>
        {props.children}
    </Container>
}

export default DropdownFrame