import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    display: flex;
    border-radius: 3px 3px 0px 0px;
    flex-direction: row;
    background-color: lightgray;
    justify-content: space-around;
    border: 1px solid grey;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex: ${props => props.flex ? props.flex :  1};
    flex-direction: row;
    padding: 12px 0px;
`;

const Title = styled.span`
    font-family: 'GodoB';
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Line = styled.div`
    width: 1px;
    background-color: grey;
`;

const TableHeader = (props) => (
    <Container>
        {props.columns && props.columns.map((column, index) => (
            <ColumnContainer>
                {index > 0 && <Line/>}
                <Title>{column}</Title>
            </ColumnContainer>
        ))}
    </Container>
)

export default TableHeader;
