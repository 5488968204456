import React from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const regexPreSurvey = /[A-Z]_[0]/;
const regexSurvey = /[A-Z]_[1-9]/;
const regexSubSection = /[A-Z]_[0-9]_sub[0-9]{2}_[a-z]/;
const imgExcel = require('../../res/images/ic_excel.png')

const Container = styled.div`
    width: 38px;
    height: 38px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover{
        background-color: rgba(0,0,0,0.2)
    }
`;

const ExcelButton = styled.img`
    width: 22px;
    height: 22px;
    object-fit: contain;
`;

const getStatSheet = (stats) => {
    const data = []
    Object.keys(stats).map(category => {
        Object.keys(stats[category]).map(id => {
            const stat = stats[category][id];
            data.push({
                task: category,
                id: id,
                point: stat.point,
                total: stat.total,
                percentage: ((100 * stat.point) / stat.total).toFixed(2)
            })
        })
    })
    data.sort((a, b) => {
        let o1 = a.task.toUpperCase();
        let o2 = b.task.toUpperCase();

        let p1 = a.id.toUpperCase();
        let p2 = b.id.toUpperCase();

        if (o1 < o2) return -1;
        if (o1 > o2) return 1;
        if (p1 < p2) return -1;
        if (p1 > p2) return 1;
        return 0;
    })
    return data;
}

const getDataSheet = (surveyData, regex) => {
    const data = []
    Object.keys(surveyData).filter(task => task !== 'title').map(task => {
        if (regex.test(task)) {
            Object.keys(surveyData[task]).filter(section => section !== 'title').map(section => {
                Object.keys(surveyData[task][section]).filter(key => key !== 'title').map(key => {
                    if (regexSubSection.test(key)) {
                        const subSection = key;
                        Object.keys(surveyData[task][section][subSection]).map(question => {
                            const input = surveyData[task][section][subSection][question];
                            data.push({
                                taskId: task,
                                task: surveyData[task].title,
                                sectionId: section,
                                section: surveyData[task][section].title,
                                subSectionId: subSection,
                                subSection: surveyData[task][section][subSection].title,
                                questionId: question,
                                question: input.question,
                                answer: Array.isArray(input.answer) ? input.answer.toString() : input.answer,
                                point: input.point ? input.point : "-",
                                total: input.total ? input.total : "-"
                            })
                        })
                    } else {
                        const input = surveyData[task][section][key];
                        data.push({
                            taskId: task,
                            task: surveyData[task].title,
                            sectionId: section,
                            section: surveyData[task][section].title,
                            subSectionId: "-",
                            subSection: "-",
                            questionId: key,
                            question: input.question,
                            answer: Array.isArray(input.answer) ? input.answer.toString() : input.answer,
                            point: input.point ? input.point : "-",
                            total: input.total ? input.total : "-"
                        })
                    }
                })
            })
        }
    })
    data.sort((a, b) => {
        let o1 = a.sectionId.toUpperCase();
        let o2 = b.sectionId.toUpperCase();

        let p1 = a.questionId.toUpperCase();
        let p2 = b.questionId.toUpperCase();

        if (o1 < o2) return -1;
        if (o1 > o2) return 1;
        if (p1 < p2) return -1;
        if (p1 > p2) return 1;
        return 0;
    })
    return data;
}

const ExcelDownloadButton = (props) => {
    const { submission } = props;
    console.log(submission.userInfo)

    const components = []
    if (submission.userInfo.name) components.push(<ExcelColumn key={components.length} label="이름" value="name" />)
    if (submission.userInfo.childName) components.push(<ExcelColumn key={components.length} label="자녀이름" value="childName" />)
    if (submission.userInfo.age) components.push(<ExcelColumn key={components.length} label="나이" value="age" />)
    if (submission.userInfo.gender) components.push(<ExcelColumn key={components.length} label="성별" value="gender" />)
    if (submission.userInfo.school) components.push(<ExcelColumn key={components.length} label="학교" value="school" />)
    if (submission.userInfo.grade) components.push(<ExcelColumn key={components.length} label="학년" value="grade" />)
    if (submission.userInfo.occupation) components.push(<ExcelColumn key={components.length} label="직업" value="occupation" />)
    console.log(components.length);


    return <ExcelFile element={<Container> <ExcelButton src={imgExcel} /></Container>} filename={`BWR_${Moment(Date(submission.modifiedAt)).format('YYYYMMDD')}`}>
        <ExcelSheet data={[submission.userInfo]} name="인적사항">
            {components}
        </ExcelSheet>
        <ExcelSheet data={getStatSheet(submission.stats)} name={"통계"}>
            <ExcelColumn label="검사" value="task" />
            <ExcelColumn label="항목번호" value="id" />
            <ExcelColumn label="점수" value="point" />
            <ExcelColumn label="총점수" value="total" />
            <ExcelColumn label="퍼센트(%)" value="percentage" />
        </ExcelSheet>
        <ExcelSheet data={getDataSheet(submission.userInputs, regexPreSurvey)} name={"기본면담지"}>
            <ExcelColumn label="검사코드" value="taskId" />
            <ExcelColumn label="검사명" value="task" />
            <ExcelColumn label="대분류코드" value="sectionId" />
            <ExcelColumn label="대분류" value="section" />
            <ExcelColumn label="소분류코드" value="subSectionId" />
            <ExcelColumn label="소분류" value="subSection" />
            <ExcelColumn label="질문코드" value="questionId" />
            <ExcelColumn label="답변" value="answer" />
            <ExcelColumn label="점수" value="point" />
            <ExcelColumn label="총점수" value="total" />
        </ExcelSheet>
        <ExcelSheet data={getDataSheet(submission.userInputs, regexSurvey)} name={submission.survey}>
            <ExcelColumn label="검사코드" value="taskId" />
            <ExcelColumn label="검사명" value="task" />
            <ExcelColumn label="대분류코드" value="sectionId" />
            <ExcelColumn label="대분류" value="section" />
            <ExcelColumn label="소분류코드" value="subSectionId" />
            <ExcelColumn label="소분류" value="subSection" />
            <ExcelColumn label="질문코드" value="questionId" />
            <ExcelColumn label="답변" value="answer" />
            <ExcelColumn label="점수" value="point" />
            <ExcelColumn label="총점수" value="total" />
        </ExcelSheet>
    </ExcelFile>
}

export default ExcelDownloadButton;