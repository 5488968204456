import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
//import { useSelector } from 'react-redux';
//import { isLoaded } from 'react-redux-firebase';
import * as ROUTES from './values/Routes';
import Main from './pages/Main';
import About from './pages/About';
import Forest from './pages/Forest';
import Navbar from './components/navbar/Navbar';
import SideDrawer from './components/navbar/SideDrawer';
import Backdrop from './components/layouts/Backdrop';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Center from './pages/Center';
import Trial from './pages/trial/Trial';
import Result from './pages/trial/Result';
import Account from './pages/Account';
import Questions from './pages/admin/Questions';
import Submissions from './pages/admin/Submissions';
import Footer from './components/layouts/Footer';
import Faq from './pages/Faq';
import TopButton from './components/buttons/TopButton';
import CognitionIntro from './pages/intros/CognitionIntro';
import LearningIntro from './pages/intros/LearningIntro';
import HealingIntro from './pages/intros/HealingIntro';
import CommunicationIntro from './pages/intros/CommunicationIntro';
import NeuroscienceIntro from './pages/intros/NeuroscienceIntro';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import NoticeDetails from './pages/mypage/notice/NoticeDetails';
import NoticeForm from './pages/mypage/notice/NoticeForm';
import NoticeBoard from './pages/mypage/notice/NoticeBoard';
import MyPageMenu from './components/navbar/MyPageMenu';
import Users from './pages/mypage/users/Users';
import Survey from './components/layouts/survey/Survey';
import AdminTools from './pages/mypage/AdminTools';
import SubmissionBoard from './pages/mypage/submission/SubmissionBoard';

const App = () => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [transparentNavBar, setTransparentNavBar] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => transparentNavBar && setTransparentNavBar(!(window.pageYOffset > 72));
  const onDrawerClicked = () => setIsDrawerOpened((prev) => !prev);
  const onBackdropClicked = () => setIsDrawerOpened(false);
  const onNavButtonClicked = () => setIsDrawerOpened(false);

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar isTop={transparentNavBar} onDrawerClicked={onDrawerClicked} />
        <MyPageMenu />

        <SideDrawer
          onClick={onNavButtonClicked}
          show={isDrawerOpened}
          onClose={onBackdropClicked}
        />
        {isDrawerOpened && <Backdrop onClick={onBackdropClicked} />}
        <Switch>
          <Route path={ROUTES.MAIN} exact component={Main} />
          <Route path={ROUTES.ABOUT} component={About} />
          <Route path={ROUTES.ACCOUNT} component={Account} />
          <Route path={ROUTES.TRIAL} component={Trial} />
          <Route path={ROUTES.RESULT} component={Result} />
          <Route path={ROUTES.FOREST} exact component={Forest} />
          <Route path={ROUTES.CENTER} component={Center} />
          <Route path={ROUTES.QUESTIONS} component={Questions} />
          <Route path={ROUTES.SUBMISSIONS} component={Submissions} />
          <Route path={ROUTES.FAQ} component={Faq} />
          <Route path={ROUTES.COGNITION} component={CognitionIntro} />
          <Route path={ROUTES.LEARNING} component={LearningIntro} />
          <Route path={ROUTES.COMMUNICATION} component={CommunicationIntro} />
          <Route path={ROUTES.HEALING} component={HealingIntro} />
          <Route path={ROUTES.NEUROSCIENCE} component={NeuroscienceIntro} />
          <Route path={ROUTES.SIGN_UP} component={SignUp} />
          <Route path={ROUTES.SIGN_IN} component={Login} />
          <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route path={ROUTES.TERMS} component={TermsOfService} />
          <Route path={ROUTES.NOTICE_BOARD} exact component={NoticeBoard} />
          <Route path={ROUTES.NOTICE_DETAIL} component={NoticeDetails} />
          <Route path={ROUTES.NOTICE_FORM} component={NoticeForm} />
          <Route path={ROUTES.SUBMISSION_BOARD} component={SubmissionBoard} />
          <Route path={ROUTES.USERS} component={Users} />
          <Route path={ROUTES.SURVEY} component={Survey} />
          <Route path={ROUTES.ADMIN_TOOLS} component={AdminTools} />
        </Switch>
        <TopButton show={transparentNavBar} />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

// const AuthIsLoaded = ({ children }) => {
//   const auth = useSelector((state) => state.firebase.auth);
//   if (!isLoaded(auth)) {
//     return <div>splash screen...</div>;
//   }

//   return children;
// };

export default App;
