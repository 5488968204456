import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px){
    height: 700px;
  }

  @media (max-width: 768px) {
    height: 600px;
  }

`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

const BodyContainer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 100px 40px 100px 40px;

  @media (max-width: 1000px){
  
  }

  @media (max-width: 768px) {
    padding: 140px 40px 80px 40px;
  }

  @media (max-width: 415px) {
    
  }
`;

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 800px;
  object-fit: cover;
  z-index: -1;

  @media (max-width: 1000px){
    height: 700px;
  }

  @media (max-width: 768px) {
    height: 600px;
  }
`;

const VideoContainer = (props) => (
  <Container {...props}>
    <Video autoPlay loop muted>
      <source src={props.src} type="video/mp4" />
    </Video>
    <Overlay>
      <BodyContainer>{props.children}</BodyContainer>
    </Overlay>
  </Container>
);

export default VideoContainer;
