export const Cognition = {
  trial: 'cognition',
  title: '인지의 길',
  instruction:
    '정보와 자극의 홍수 속에서 우리는 어디에, 어떻게, 집중해야 할까요? 인지의 길에서는 ‘I’의 인지능력을 파악하는 것에서 더 나아가 인지능력을 어떻게 활용해야 할 지에 대한 전략을 분석하고 발전시키고자 합니다.',
  sections: [
    {
      id: 'COG1',
      title: '적성프로파일링',
      instruction: '자녀의 성향과 가장 가깝다고 여겨지는 항목에 표시해 주세요.',
      choices: {
        values: [0, 1, 2, 3, 4],
        texts: ['전혀 아니다', '약간 아니다', '보통이다', '약간 그렇다', '매우 그렇다']
      },
      questions: [
        {
          uid: 'MI1',
          category: '언어영역 선호',
          text: '새로운 이야기를 만들내거나 글 쓰기를 좋아한다'
        },
        {
          uid: 'MI2',
          category: '언어영역 선호',
          text: '책읽기를 좋아한다.'
        },
        {
          uid: 'MI3',
          category: '언어영역 선호',
          text: '국어나 외국어(영어, 일본어, 중국어 등) 공부를 좋아한다.'
        },
        {
          uid: 'MI4',
          category: '언어영역 효능',
          text: '친구들보다 말을 잘 하고 어휘력이 풍부하는 이야기를 자주 듣는다'
        },
        {
          uid: 'MI5',
          category: '언어영역 효능',
          text: '다른 사람들이 이야기할 때, 비논리적인 점을 잘 찾아내는 편이다.'
        },
        {
          uid: 'MI6',
          category: '언어영역 효능',
          text: '발표를 조리있게 잘 하는 편이다.'
        },
        {
          uid: 'MI7',
          category: '논리수학영역 선호',
          text: '과목 중에서 수학이나 과학 공부 하는 것이 재미있다.'
        },
        {
          uid: 'MI8',
          category: '논리수학영역 선호',
          text: '어떤 일의 원인이나 이유를 밝히는 것이 재미있다.'
        },
        {
          uid: 'MI9',
          category: '논리수학영역 선호',
          text: '과학에 관한 글을 읽는 것을 좋아한다.'
        },
        {
          uid: 'MI10',
          category: '논리수학영역 효능',
          text:
            '게임 중에서 숫자가 나와서 계산을 해야하는 상황이나 수학 관련 게임 등을 좋아하고 잘한다.'
        },
        {
          uid: 'MI11',
          category: '논리수학영역 효능',
          text: '주제에 맞게 의견을 말하고 토론을 잘 하는 편이다.'
        },
        {
          uid: 'MI12',
          category: '논리수학영역 효능',
          text: '어떤 일의 원인과 결과를 잘 이해하는 편이다.'
        },
        {
          uid: 'MI13',
          category: '자아영역 선호',
          text: '가끔씩은 혼자 있고 싶어 하고 조용한 시간이 필요해 보인다.'
        },
        {
          uid: 'MI14',
          category: '자아영역 선호',
          text: '하루를 돌아보며 앞으로의 생활을 계획하는 일을 좋아한다.'
        },
        {
          uid: 'MI15',
          category: '자아영역 선호',
          text: '어떤 일이든 결국 이뤄낼 수 있는 힘이 있다고 믿는다.'
        },
        {
          uid: 'MI16',
          category: '자아영역 효능',
          text: '자신에게 무슨 일이 일어났으며 어떠한 기분이었는지 잘 이야기할 수 있다.'
        },
        {
          uid: 'MI17',
          category: '자아영역 효능',
          text: '집이나 학교에서 스스로 해야할 일이 무엇인지 잘 안다.'
        },
        {
          uid: 'MI18',
          category: '자아영역 효능',
          text: '실패 혹은 실수했을 때, 다시 그런 일이 생기지 않도록 주의한다.'
        },
        {
          uid: 'MI19',
          category: '대인관계영역 선호',
          text: '어떤 일을 혼자서 하는 것보다는 다른 사람들과 어울려서 하는 것을 좋아한다.'
        },
        {
          uid: 'MI20',
          category: '대인관계영역 선호',
          text: '친구나 가족들의 고민거리를 들어주거나 해결하는 것을 좋아한다.'
        },
        {
          uid: 'MI21',
          category: '대인관계영역 선호',
          text: 'TV나 영화 속의 인물들이 보이는 기쁨이나 슬픔에 대해 쉽게 자주 공감한다.'
        },
        {
          uid: 'MI22',
          category: '대인관계영역 효능',
          text: '다른 사람들에게 쉽게 다가가고 잘 어울리는 편이다.'
        },
        {
          uid: 'MI23',
          category: '대인관계영역 효능',
          text: '다른 사람들로부터 다정하고 친절하다는 소리를 자주 듣는 편이다.'
        },
        {
          uid: 'MI24',
          category: '대인관계영역 효능',
          text: '비밀을 나눌 수 있는 친한 친구가 있다.'
        },
        {
          uid: 'MI25',
          category: '시공간영역 선호',
          text: '그림 그리기나 만들기를 좋아한다.'
        },
        {
          uid: 'MI26',
          category: '시공간영역 선호',
          text: '자기가 만들거나 그린 작품들을 전시하는 것을 좋아한다.'
        },
        {
          uid: 'MI27',
          category: '시공간영역 선호',
          text: '화가나 디자이너가 장래의 직업으로 잘 어울릴 것 같다.'
        },
        {
          uid: 'MI28',
          category: '시공간영역 효능',
          text: '블럭놀이나 퍼즐 등의 놀이를 좋아하고 잘 한다.'
        },
        {
          uid: 'MI29',
          category: '시공간영역 효능',
          text: '고장 난 기계나 물건을 잘 고친다.'
        },
        {
          uid: 'MI30',
          category: '시공간영역 효능',
          text: '낯선 곳에서도 길을 잘 찾아갈 수 있다.'
        },
        {
          uid: 'MI31',
          category: '예술영역 선호',
          text: '노래를 하거나 음악 듣는 것을 좋아한다.'
        },
        {
          uid: 'MI32',
          category: '예술영역 선호',
          text: '가수나 연주자가 장래 직업으로 잘 어울릴 것 같다.'
        },
        {
          uid: 'MI33',
          category: '예술영역 선호',
          text: '평소에 음악 듣는 것을 좋아하는 편이다.'
        },
        {
          uid: 'MI34',
          category: '예술영역 효능',
          text: '다룰 수 있는 악기가 한 두개 정도 있다.'
        },
        {
          uid: 'MI35',
          category: '예술영역 효능',
          text: '악보를 보면 그 곡의 멜로디를 어느 정도 알 수 있다.'
        },
        {
          uid: 'MI36',
          category: '예술영역 효능',
          text: '다른 사람과 노래할 때 화음을 잘 넣는다.'
        },
        {
          uid: 'MI37',
          category: '신체운동지능 선호',
          text: '인라인스케이트, 자전거 등 몸을 많이 움직이는 놀이를 좋아한다.'
        },
        {
          uid: 'MI38',
          category: '신체운동지능 선호',
          text: '운동선수가 장래 직업으로 잘 어울릴 것 같다.'
        },
        {
          uid: 'MI39',
          category: '신체운동지능 선호',
          text: '바깥에서 뛰어 노는 것을 좋아하는 편이다.'
        },
        {
          uid: 'MI40',
          category: '신체운동지능 효능',
          text: '운동을 잘한다는 말을 자주 듣는다.'
        },
        {
          uid: 'MI41',
          category: '신체운동지능 효능',
          text: '손 또는 발로 공을 잘 다룰 수 있다.'
        },
        {
          uid: 'MI42',
          category: '신체운동지능 효능',
          text: '쉬지 않고 오래 달릴 수 있다.'
        }
      ]
    },
    {
      id: 'COG2',
      title: '인지/학습 준비도',
      instruction:
        '(지난 6개월 동안) 자녀가 보이는 행동을 가장 잘 나타내고 있는 항목에 표시해 주세요.',
      choices: {
        values: [0, 1, 2, 3],
        texts: ['전혀 혹은 그렇지 않다', '때때로 그렇다', '자주 그렇다', '매우 자주 그렇다']
      },
      questions: [
        {
          uid: 'ADHD1',
          category: '주의집중력',
          text: '세부적인 면에 대해 꼼꼼하게 주의를 기울이지 못하거나 부주의한 실수를 한다.'
        },
        {
          uid: 'ADHD2',
          category: '주의집중력',
          text: '과제와 일을 체계적으로 하지 못한다.'
        },
        {
          uid: 'ADHD3',
          category: '주의집중력',
          text: '다른 사람과 마주보고 이야기할 때 경청하지 않는 것처럼 보인다.'
        },
        {
          uid: 'ADHD4',
          category: '주의집중력',
          text: '지시를 따르지 않고 일을 끝내지 못한다.'
        },
        {
          uid: 'ADHD5',
          category: '주의집중력',
          text: '손발을 가만히 두지 못하거나 의자에 앉아서도 몸을 꼼지락 거린다.'
        },
        {
          uid: 'ADHD6',
          category: '주의집중력',
          text: '교실이나 자리에 앉아 있어야 하는 장소에서 가만히 있지 못한다.'
        },
        {
          uid: 'ADHD7',
          category: '주의집중력',
          text: '여가 활동이나 재미있는 일에 얌전히 참여하기가 어렵다.'
        },
        {
          uid: 'ADHD8',
          category: '주의집중력',
          text: '질문이 끝나기도 전에 성급하게 대답한다.'
        }
      ]
    }
  ]
};

export const Learning = {
  trial: 'learning',
  title: '학습의 길',
  instruction:
    '학습의 길에서는 학부모와 자녀의 학습 준비도를 측정합니다. 학습의 길을 통해서 더 나은 학습 환경을 만들어 봅시다.',
  sections: [
    {
      id: 'LRN1',
      title: '학습에 대한 환경적 특성',
      instruction:
        '평소 자녀의 학습 가치관에 대한 자신의 모습과 가장 가깝다고 생각되는 정도에 표시해 주세요.',
      choices: {
        values: [0, 1, 2, 3, 4],
        texts: ['전혀 그렇지 않다', '그렇지 않다', '보통이다', '그런 편이다', '매우 그렇다']
      },
      questions: [
        {
          uid: 'PSB1',
          category: '자율',
          text: '나는 내 마음에 들지 않아도 일단은 자녀가 하고 싶은 자세로 공부하도록 한다.'
        },
        {
          uid: 'PSB2',
          category: '자율',
          text: '나는 공부에 관한 자녀의 의견을 존중한다.'
        },
        {
          uid: 'PSB3',
          category: '자율',
          text: '나는 내 마음에 들지 않아도 일단은 자녀가 하고 싶은 공부를 할 수 있게 한다.'
        },
        {
          uid: 'PSB4',
          category: '자율',
          text:
            '자녀가 공부에 관한 자신의 의견을 말하면 나의 마음에 들지 않아도 일단은 충분히 검토한다.'
        },
        {
          uid: 'PSB5',
          category: '자율',
          text: '나는 내 마음에 들지 않아도 일단은 자녀가 하고 싶은 장소에서 공부하게 한다.'
        },
        {
          uid: 'PSB6',
          category: '존중',
          text:
            '나는 자녀에게 공부하라고 할 때는 자녀가 공부하면서 얼마나 애쓰는지를 충분히 헤아려 준다.'
        },
        {
          uid: 'PSB7',
          category: '존중',
          text: '나는 내가 기대하는 만큼 공부하는 게 어려운 일이라는 것을 이해하지 못한다.',
          resversed: true
        },
        {
          uid: 'PSB8',
          category: '존중',
          text: '나는 특별한 설명을 하지 않고, 자녀가 원하는 공부를 하지 못하게 한다.',
          resversed: true
        },
        {
          uid: 'PSB9',
          category: '존중',
          text:
            '나는 자녀가 내가 원하는 만큼 공부하지 않을 때에도 꾸중하지 않고 공부하고 싶지 않은 자녀의 마음을 충분히 헤아려 준다.'
        },
        {
          uid: 'PSB10',
          category: '존중',
          text: '나는 자녀의 공부방법이 남에게 피해를 줘서는 안 된다고 말한다.'
        },
        {
          uid: 'PSB11',
          category: '공감',
          text:
            '나는 자녀의 성적이 나의 기대에 미치지 못했을 때에도 책임을 추궁하기보다 반복해서 틀리지 않도록 하는 것을 더 중요하게 생각한다.'
        },
        {
          uid: 'PSB12',
          category: '공감',
          text: '나는 자녀의 능력이나 상황을 충분히 고려하지 않고 높은 성과만을 기대한다.',
          resversed: true
        },
        {
          uid: 'PSB13',
          category: '공감',
          text:
            '시험성적이 나쁘면 공부를 하면서 자녀가 무엇을 어려워하는지를 알아내어 도와주려고 애쓴다.'
        },
        {
          uid: 'PSB14',
          category: '공감',
          text:
            '자녀가 공부하지 않으면, 나는 혼내기보다는 공부를 하지 않아서 생기는 결과를 함께 의논해 준다.',
          resversed: true
        },
        {
          uid: 'PSB15',
          category: '공감',
          text: '나는 자녀가 스스로 세운 공부목표를 존중해 준다.'
        },
        {
          uid: 'PSB16',
          category: '격려',
          text: '나는 자녀가 친구들과 경쟁하여 이길 것을 강조한다.',
          resversed: true
        },
        {
          uid: 'PSB17',
          category: '격려',
          text: '나는 공부에 대해 말할 때 “누구보다 잘해라.”라고 말한다. ',
          resversed: true
        },
        {
          uid: 'PSB18',
          category: '격려',
          text: '나는 자녀를 다른 가정의 자녀와 비교하지 않는다.'
        },
        {
          uid: 'PSB19',
          category: '격려',
          text: '나는 자녀를 형이나 동생과 비교한다.',
          resversed: true
        },
        {
          uid: 'PSB20',
          category: '격려',
          text: '나는 자녀의 성적을 친척들의 성적과 비교한다.',
          resversed: true
        },
        {
          uid: 'PSB21',
          category: '학습지원',
          text: '나는 공부 방법에 대한 정보를 모았다가 전해준다.'
        },
        {
          uid: 'PSB22',
          category: '학습지원',
          text: '나는 공부와 관련된 정보를 열심히 모은다.'
        },
        {
          uid: 'PSB23',
          category: '학습지원',
          text: '나는 공부에 관한 정보를 모았다가 자녀에게 전해준다.'
        },
        {
          uid: 'PSB24',
          category: '학습지원',
          text: '나는 공부에 도움이 되는 정보를 자녀에게 적극적으로 알려준다.'
        },
        {
          uid: 'PSB25',
          category: '학습지원',
          text: '나는 자녀의 공부에 도움이 되는 정보를 적극적으로 수집한다.'
        }
      ]
    },
    {
      id: 'LRN2',
      title: '학습에 대한 개인적 특성',
      instruction: '자녀의 생각과 가장 유사하게 여겨지는 항목에 표시해 주세요.',
      choices: {
        values: [0, 1, 2, 3],
        texts: ['전혀 그렇지 않다', '그렇지 않다', '그런 편이다', '매우 그렇다']
      },
      questions: [
        {
          uid: 'LH1',
          category: '가치',
          text: '학교 공부는 나에게 중요한 의미를 지닌다.'
        },
        {
          uid: 'LH2',
          category: '가치',
          text: '학교에서 배우는 내용들이 중요하다고 생각한다.'
        },
        {
          uid: 'LH3',
          category: '가치',
          text: '학교생활이 성장해 나가는 데 중요한 역할을 할 것이라고 생각한다.'
        },
        {
          uid: 'LH4',
          category: '가치',
          text: '학교생활이 미래에 상당히 중요한 역할을 할 것이다.'
        },
        {
          uid: 'LH5',
          category: '가치',
          text: '학교 공부는 미래의 직업을 선택하는 데 커다란 역할을 할 것이다.'
        },
        {
          uid: 'LH6',
          category: '가치',
          text: '학교에서 배우는 내용은 살아가는 데 유용할 것이다.'
        },
        {
          uid: 'LH7',
          category: '가치',
          text: '학교생활은 앞으로 사회생활을 하는 데 도움이 될 것이다.'
        },
        {
          uid: 'LH8',
          category: '통제',
          text: '공부가 지루하고 재미없더라도 끝까지 다한다.'
        },
        {
          uid: 'LH9',
          category: '통제',
          text: '하던 공부를 완전히 끝낼 때까지 공부에 집중한다.'
        },
        {
          uid: 'LH10',
          category: '통제',
          text: '공부가 지루해도 계획한 것은 마친다.'
        },
        {
          uid: 'LH11',
          category: '계획',
          text: '몇 시간 동안 얼마나 공부할 것인지 목표를 분명히 한 다음에 공부를 시작한다.'
        },
        {
          uid: 'LH12',
          category: '계획',
          text: '효율적으로 공부하기 위해 시간 계획을 세운다.'
        },
        {
          uid: 'LH13',
          category: '계획',
          text: '효과적으로 공부하기 위해 공부 시간을 확실히 정해둔다.'
        },
        {
          uid: 'LH14',
          category: '계획',
          text: '시험 전에 계획을 세우고 그에 따라 공부한다.'
        }
      ]
    }
  ]
};

export const Communication = {
  trial: 'communication',
  title: '소통의 길',
  instruction:
    '소통의 길에서는 우리 가족, 부모와 자녀, 부부 사이에서 자신의 감정과 상대방의 감정을 이해하고, 서로 맞추며 소통해나갈 수 있는 계기를 마련합니다.',
  sections: [
    {
      id: 'COM1',
      title: '사회성',
      instruction: '평소 자신의 생각에 가장 유사한 항목에 표시해 주세요.',
      choices: {
        values: [0, 1, 2, 3, 4],
        texts: ['전혀 아니다', '아니다', '보통이다', '그렇다', '매우 그렇다']
      },
      questions: [
        {
          uid: 'Soci1',
          category: '대인관계',
          text: '주변사람들과 이야기(대화)를 잘하는 편이다.'
        },
        {
          uid: 'Soci2',
          category: '대인관계',
          text: '윗사람에게 질문을 받으면 조리 있고 분명하게 의견을 말한다.'
        },
        {
          uid: 'Soci3',
          category: '대인관계',
          text: '학급에 보기 싫어하는 급우들이 많다.',
          resversed: true
        },
        {
          uid: 'Soci4',
          category: '대인관계',
          text: '자신의 생각을 타인에게 잘 전달한다.'
        },
        {
          uid: 'Soci5',
          category: '대인관계',
          text: '친구가 잘하는 것이 있으면 질투를 한다.',
          resversed: true
        },
        {
          uid: 'Soci6',
          category: '협력성',
          text: '선생님께서 시키는 일은 열심히 잘 한다.'
        },
        {
          uid: 'Soci7',
          category: '협력성',
          text: '친구가 어렵다면 바쁘더라도 기꺼이 도와주고 싶어 한다.'
        },
        {
          uid: 'Soci8',
          category: '협력성',
          text: '조별 학습 때에 조 친구들과 의논해서 하는 것이 좋다고 생각한다.'
        },
        {
          uid: 'Soci9',
          category: '협력성',
          text: '모르는 사람이 길을 물었을 때, 귀찮아서 모르는 척 하고 그냥 지나간적이 있다.',
          resversed: true
        },
        {
          uid: 'Soci10',
          category: '협력성',
          text: '숙제를 할 때 친구들과 같이 할 때가 많다.'
        },
        {
          uid: 'Soci11',
          category: '사교성',
          text: '처음 보는 사람과도 쉽게 대화한다.'
        },
        {
          uid: 'Soci12',
          category: '사교성',
          text: '친구들과 어울리는 것을 좋아한다.'
        },
        {
          uid: 'Soci13',
          category: '사교성',
          text: '새롭게 만난 친구들에게 잘 다가가는 편이다.'
        },
        {
          uid: 'Soci14',
          category: '사교성',
          text: '친구인데도 인사하기 어려워서 못 본체하고 그냥 지나간 경험이 있다.',
          resversed: true
        },
        {
          uid: 'Soci15',
          category: '사교성',
          text: '이웃에 사는 친구들을 만나면 먼저 아는 체를 하는 편이다.'
        }
      ]
    },
    {
      id: 'COM2',
      title: '부모 스트레스',
      instruction:
        '평소 부모로서 느끼는 정도와 가장 잘 일치하는 정도에 표시해 주세요. (여러 자녀일 경우) 가장 걱정하는 자녀를 생각하며 대답해 주시면 됩니다.',
      choices: {
        values: [1, 2, 3, 4, 5],
        texts: ['전혀 아니다', '약간 아니다', '보통이다', '약간 그렇다', '매우 그렇다']
      },
      questions: [
        {
          uid: 'PNSP1',
          category: '우울',
          text: '나는 출산 후 생각했던 것보다 더 힘들고 우울함을 느꼈다.'
        },
        {
          uid: 'PNSP2',
          category: '우울',
          text: '나는 아이에게 화를 내고 난 뒤 죄책감과 괴로움을 느낀다.'
        },
        {
          uid: 'PNSP3',
          category: '애착',
          text: '자녀가 어릴 때, 돌봐주고 안아주는 것에 대해 편안함을 느끼지 못했다. '
        },
        {
          uid: 'PNSP4',
          category: '역할제안',
          text:
            '내 삶은 대부분 자녀와 관련된 일들로 가득 차 있다. '
        },
        {
          uid: 'PNSP5',
          category: '유능감',
          text: '내가 예상했던 것 보다 부모가 되는 것은 힘든 일이다.'
        },
        {
          uid: 'PNSP6',
          category: '유능감',
          text: '나는 혼자서 결정을 내리는 것이 어렵다.'
        },
        {
          uid: 'PNSP7',
          category: '유능감',
          text: '나는 스스로 부족한 부모라고 생각한다.'
        },
        {
          uid: 'PNSP8',
          category: '고립',
          text: '아이를 가진 이후로 새로운 친구를 만나는 것이 어려워졌다.'
        },
        {
          uid: 'PNSP9',
          category: '배우자',
          text: '내 배우자는 자녀 양육에 관심이 없고 도와주지 않은 편이다.'
        },
        {
          uid: 'PNSP10',
          category: '건강',
          text: '나는 아이가 생긴 이후로 아픈 경우가 많아졌다.'
        },
        {
          uid: 'PNSC1',
          category: '적응',
          text: '다른 사람에게 자녀를 맡기는 것은 항상 어려운 일이다.'
        },
        {
          uid: 'PNSC2',
          category: '적응',
          text: '우리 아이는 새로운 상황이나 낯선 사람을 만날 때 어려워하고 불편해한다.'
        },
        {
          uid: 'PNSC3',
          category: '적응',
          text: '우리 아이는 한번 감정이 폭발하면 달래는 것이 어렵다.'
        },
        {
          uid: 'PNSC4',
          category: '수용',
          text: '우리 아이는 때때로 내가 생각한 것과 다른 것 같아 염려가 된다.'
        },
        {
          uid: 'PNSC5',
          category: '요구',
          text: '우리 아이는 다른 아이보다 양육하는 것이 매우 어렵다. '
        },
        {
          uid: 'PNSC6',
          category: '요구',
          text: '우리 아이는 항상 나와 떨어지지 않으려고 한다.'
        },
        {
          uid: 'PNSC7',
          category: '기분',
          text: '우리 아이는 다른 아이들보다 훨씬 더 많이 울고 보챈다.'
        },
        {
          uid: 'PNSC8',
          category: '주의산만',
          text: '우리 아이는 원하는 것이 있으면 그것을 끝까지 얻어내려 하며 통제하기가 어렵다.'
        },
        {
          uid: 'PNSC9',
          category: '주의산만',
          text: '우리 아이는 안절부절못하고 너무 활동적이어서 통제하는 것이 힘들다.'
        },
        {
          uid: 'PNSC10',
          category: '주의산만',
          text: '우리 아이는 다른 아이들에 비해 주의를 기울이고 집중하는 것을 어려워 한다.'
        }
      ]
    }
  ]
};

export const Healing = {
  trial: 'healing',
  title: '힐링의 길',
  totalNum: 10,
  instruction:
    '힐링의 길에서는 나의 감정, 나의 스트레스가 어디서 오는지 심도 있게 다룹니다. 체험을 통해 알게된 사실을 기반으로 좀 더 건강한 삶을 살 수 있도록 합니다.',
  sections: [
    {
      section: 'HLG1',
      title: '결혼 만족도',
      instruction: '결혼 생활과 관련하여 본인이 느끼는 정도를 선택해 주세요.',
      choices: {
        values: [1, 0],
        texts: ['예, 그렇습니다', '아니요, 그렇지 않습니다']
      },
      questions: [
        {
          uid: 'GDS1',
          category: '전반적 불만족 척도',
          text:
            '나는 나의 결혼생활이 비교적 행복하다고 느낀다.',
          reversed: true
        },
        {
          uid: 'GDS2',
          category: '전반적 불만족 척도',
          text: '결혼생활에 대해 좋다고 느꼈던 적이 없다.',
          resversed: true
        },
        {
          uid: 'GDS3',
          category: '전반적 불만족 척도',
          text: '다른 사람들의 결혼생활에 비해 우리 부부는 잘 지내지 못한다.'
        },
        {
          uid: 'GDS4',
          category: '전반적 불만족 척도',
          text: '때때로 나는 배우자와 떨어져 있고 싶다.'
        },
        {
          uid: 'GDS5',
          category: '전반적 불만족 척도',
          text: '나는 결혼생활에서 충족되지 못한 욕구들이 있다.'
        },
        {
          uid: 'GDS6',
          category: '전반적 불만족 척도',
          text: '내가 생각하기에 나의 결혼생활에는 심각한 문제들이 있다.'
        },
        {
          uid: 'GDS7',
          category: '전반적 불만족 척도',
          text: '나는 배우자와 함께 있을 때에도 종종 외롭다고 생각한다.'
        },
        {
          uid: 'GDS8',
          category: '전반적 불만족 척도',
          text: '나는 이혼을 해야 할지 고민 한적이 있다.'
        },
        {
          uid: 'GDS9',
          category: '전반적 불만족 척도',
          text: '우리는 별거 또는이혼을 할 위기에 처했던 적은 없다.',
          resversed: true
        },
        {
          uid: 'GDS10',
          category: '전반적 불만족 척도',
          text: '나의 결혼은 내가 생각했던 것에 비해 실망스럽다.'
        },
        {
          uid: 'GDS11',
          category: '전반적 불만족 척도',
          text: '우리는 자주 언쟁을 한다.'
        },
        {
          uid: 'GDS12',
          category: '전반적 불만족 척도',
          text: '나는 내 배우자와의 결혼에 대해 후회를 한다.'
        },
        {
          uid: 'GDS13',
          category: '전반적 불만족 척도',
          text: '나는 때때로 나의 결혼에 대해 희망이 없다고 느낀다.'
        },
        {
          uid: 'GDS14',
          category: '전반적 불만족 척도',
          text: '나는 다른 사람들보다 행복한 결혼생활을 하고 있다고 생각한다.',
          resversed: true
        },
        {
          uid: 'GDS15',
          category: '전반적 불만족 척도',
          text: '나는 나의 결혼생활의 대한 미래가 잘 그려지지 않아 중요한 계획을 세우는데 차질이 있다.'
        },
        {
          uid: 'GDS16',
          category: '전반적 불만족 척도',
          text: '나의 결혼생활은 비교적 좋은 점들이 있다.',
          resversed: true
        },
        {
          uid: 'GDS17',
          category: '전반적 불만족 척도',
          text: '나는 나의 결혼생활이 성공적이라고 생각한다.',
          resversed: true
        },
        {
          uid: 'GDS18',
          category: '전반적 불만족 척도',
          text: '나는 지금까지 결혼생활에 대해 행복하다고 생각해왔다.',
          resversed: true
        },
        {
          uid: 'GDS19',
          category: '전반적 불만족 척도',
          text: '나는 지금까지 나의 결혼생활에 대해 전반적으로 만족하는 편이다.',
          resversed: true
        },
        {
          uid: 'GDS20',
          category: '전반적 불만족 척도',
          text: '나는 우리 부부에게 전문가와의 상담이 필요하다고 느낀 적이 종종 있다.'
        },
        {
          uid: 'GDS21',
          category: '전반적 불만족 척도',
          text: '나는 나의 결혼생활이 다른 부부들 만큼 행복하다고 생각한다.',
          resversed: true
        },
        {
          uid: 'GDS22',
          category: '전반적 불만족 척도',
          text: '나는 나의 결혼생활을 통해 만족감을 느낀다.',
          resversed: true
        },
        {
          uid: 'DSC1',
          category: '자녀 불만족 척도',
          text: '나는 나의 아이들과 할 이야기가 없는 편이다.'
        },
        {
          uid: 'DSC2',
          category: '자녀 불만족 척도',
          text: '자녀를 갖는 것은 내가 기대한 만큼의 만족감을 주지는 못했다.'
        },
        {
          uid: 'DSC3',
          category: '자녀 불만족 척도',
          text: '우리 아이는 또래 아이들만큼 행복해보이지 않는다.'
        },
        {
          uid: 'DSC4',
          category: '자녀 불만족 척도',
          text: '우리 아이는 내 기분에 대해 관심이 없는 편이다.'
        },
        {
          uid: 'DSC5',
          category: '자녀 불만족 척도',
          text: '우리 아이는 나에게 자신의 이야기를 자주 하는 편이다.',
          resversed: true
        },
        {
          uid: 'DSC6',
          category: '자녀 불만족 척도',
          text: '나는 자주 자녀와 함께 집안일을 한다.',
          resversed: true
        },
        {
          uid: 'DSC7',
          category: '자녀 불만족 척도',
          text: '우리 아이는 자신이 해야 하는 집안일에 대해 성실하게 하는 편이다.',
          resversed: true
        },
        {
          uid: 'DSC8',
          category: '자녀 불만족 척도',
          text: '나는 자주 자녀들과 좋은 시간을 보낸다.',
          resversed: true
        },
        {
          uid: 'DSC9',
          category: '자녀 불만족 척도',
          text: '우리 아이들은 부모를 존중하는 태도가 부족하다.'
        },
        {
          uid: 'DSC10',
          category: '자녀 불만족 척도',
          text: '나의 가치관과 우리 아이의 가치관은 거의 비슷하다.',
          resversed: true
        },
        {
          uid: 'DSC11',
          category: '자녀 불만족 척도',
          text: '우리 아이는 자신의 인생에서 나를 중요한 사람으로 여긴다.',
          resversed: true
        },
        {
          uid: 'CCR1',
          category: '자녀 양육 갈등 척도',
          text: '우리 아이의 요구를 어떻게 할지에 대한 나의 의견과 자녀의 의견은 언제나 일치한다.',
          resversed: true
        },
        {
          uid: 'CCR2',
          category: '자녀 양육 갈등 척도',
          text: '나의 부부관계는 종종 자녀 문제로 인해 더 악화된다.'
        },
        {
          uid: 'CCR3',
          category: '자녀 양육 갈등 척도',
          text: '나와 우리 아이는 함께 얼마나 많은 시간을 보내야하는 지에 대한 의견이 일치한다. ',
          resversed: true
        },
        {
          uid: 'CCR4',
          category: '자녀 양육 갈등 척도',
          text: '나와 배우자는 자녀 양육에 대해 동등한 책임을 갖고 있지 않다.'
        },
        {
          uid: 'CCR5',
          category: '자녀 양육 갈등 척도',
          text: '내 배우자는 자녀들과 충분히 시간을 보내지 않는다.'
        },
        {
          uid: 'CCR6',
          category: '자녀 양육 갈등 척도',
          text: '자녀 문제로 싸우는 일은 거의 없다.',
          resversed: true
        },
        {
          uid: 'CCR7',
          category: '자녀 양육 갈등 척도',
          text: '나와 내 배우자는 아이들이 지켜야 할 규칙에 대해 함께 결정한다.',
          resversed: true
        },
        {
          uid: 'CCR8',
          category: '자녀 양육 갈등 척도',
          text: '자녀 문제로 인해 결혼 생활에 문제가 생긴적은 한 번도 없다.',
          resversed: true
        },
        {
          uid: 'CCR9',
          category: '자녀 양육 갈등 척도',
          text: '내 배우자는 자녀에게 애정표현을 충분히 하지 않는다.'
        },
        {
          uid: 'CCR10',
          category: '자녀 양육 갈등 척도',
          text: '우리 부부는 자녀 훈육에 대한 의견이 일관되어 있다.',
          resversed: true
        }
      ]
    },
    {
      section: 'HLG2',
      title: '자아존중감',
      instruction: '평소 자신의 생각에 가까운 항목에 표시해 주세요.',
      choices: {
        values: [0, 1, 2, 3, 4],
        texts: ['전혀 아니다', '아니다', '보통이다', '그렇다', '매우 그렇다']
      },
      questions: [
        {
          uid: 'SE1',
          category: '자아존중감',
          text: '나는 내가 가치 있는 사람이라고 생각한다.'
        },
        {
          uid: 'SE2',
          category: '자아존중감',
          text: '나는 내가 장점이 많다고 생각한다.'
        },
        {
          uid: 'SE3',
          category: '자아존중감',
          text: '나는 다른 사람들만큼 일을 잘 할 수 있다.'
        },
        {
          uid: 'SE4',
          category: '자아존중감',
          text: '나는 긍정적인 태도를 가지고 있다.'
        },
        {
          uid: 'SE5',
          category: '자아존중감',
          text: '나는 나 스스로에 대해 대체로 만족하고 있다.'
        },
        {
          uid: 'SE6',
          category: '자아존중감',
          text: '나는 자랑할 것이 별로 없다.',
          resversed: true
        },
        {
          uid: 'SE7',
          category: '자아존중감',
          text: '나는 대체적으로 실패한 사람이라고 생각하는 경향이 있다.',
          resversed: true
        },
        {
          uid: 'SE8',
          category: '자아존중감',
          text: '나는 내 자신을 사랑하거나 존중하지 않는다.',
          resversed: true
        },
        {
          uid: 'SE9',
          category: '자아존중감',
          text: '나는 가끔 내가 쓸모없는 사람 같다.',
          resversed: true
        },
        {
          uid: 'SE10',
          category: '자아존중감',
          text: '나는 때때로 내가 나쁜 사람인거 같다.',
          resversed: true
        }
      ]
    },
    {
      section: 'HLG3',
      title: '스트레스 지수',
      instruction:
        '최근 한 달 동안의 감정과 생각에 대한 질문입니다. 각 질문에 대해 얼마나 자주 느끼거나 생각했는지를 표시해 주세요.',
      choices: {
        values: [0, 1, 2, 3, 4],
        texts: [
          '전혀 혹은 그렇지 않다',
          '때때로 그렇다',
          '보통이다',
          '자주 그렇다',
          '매우 자주 그렇다'
        ]
      },
      questions: [
        {
          uid: 'PS1',
          category: '스트레스',
          text: '지난 한 달 동안, 예상치 못한 일이 생겨서 기분 나빠진 적이 얼마나 있었나요?'
        },
        {
          uid: 'PS2',
          category: '스트레스',
          text: '지난 한 달 동안, 중요한 일들을 통제할 수 없다고 느낌 적은 얼마나 있었나요?'
        },
        {
          uid: 'PS3',
          category: '스트레스',
          text: '지난 한 달 동안, 초조하거나 스트레스가 쌓인다고 느낀 적은 얼마나 있었나요?'
        },
        {
          uid: 'PS4',
          category: '스트레스',
          text: '지난 한 달 동안, 짜증나고 성가신 일들을 성공적으로 처리한 적이 얼마나 있었나요?'
        },
        {
          uid: 'PS5',
          category: '스트레스',
          text:
            '지난 한 달 동안, 생활 속에서 일어난 중요한 변화들을 효과적으로 대처한 적이 얼마나 있었나요?'
        },
        {
          uid: 'PS6',
          category: '스트레스',
          text:
            '지난 한 달 동안, 개인적인 문제를 처리하는 능력에 대해 자신감을 느낀 적은 얼마나 있었나요?'
        },
        {
          uid: 'PS7',
          category: '스트레스',
          text: '지난 한 달 동안, 자신의 뜻대로 일이 진행된다고 느낀 적은 얼마나 있었나요?'
        },
        {
          uid: 'PS8',
          category: '스트레스',
          text: '지난 한 달 동안, 매사를 잘 컨트롤하고 있다고 느낀 적은 얼마나 있었나요?'
        },
        {
          uid: 'PS9',
          category: '스트레스',
          text:
            '지난 한 달 동안, 당신이 통제할 수 없는 범위에서 발생한 일 때문에 화가난 적이 얼마나 있었나요?'
        },
        {
          uid: 'PS10',
          category: '스트레스',
          text:
            '지난 한 달 동안, 어려운 일이 너무 많이 쌓여서 극복할 수 없다고 느낀적이 얼마나 있었나요?'
        }
      ]
    }
  ]
};
