import React from 'react';
import styled from 'styled-components';
import TextInput from '../../TextInput';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const Text = styled.span`
    font-size: 20px;
    font-family: 'GodoB';
`;

const Reason = styled.span`
    font-size: 20px;

    font-family: 'GodoB';

`;


const FillBlankWithReason = (props) => (
    <Container>
        <RowContainer>
            <Text>{`${props.num}. ${props.prefix}`}</Text>
            <TextInput
                width={'300px'}
                id={props.key}
                type='text'
                defaultValue={props.answer}
                required={true}
                onChange={(e) => props.onChange({
                    prefix: props.prefix,
                    answer: e.target.value,
                    suffix: props.suffix,
                    reason: props.reason,
                })}
            />
            <Text>{props.suffix}</Text>
        </RowContainer>
        <RowContainer style={{marginLeft: '20px'}}>
            <Reason>( 왜냐하면</Reason>
            <TextInput
                width={'300px'}
                type='text'
                defaultValue={props.reason}
                required={true}
                onChange={(e) => props.onChange({
                    prefix: props.prefix,
                    answer: props.answer,
                    suffix: props.suffix,
                    reason: `왜냐하면 ${e.target.value} 때문이다`,
                })}
            />
            <Reason>때문이다 )</Reason>
        </RowContainer>
    </Container>
)

export default FillBlankWithReason;