import React from 'react';
import styled from 'styled-components';
import SnsButton from '../buttons/SnsButton';
import COLORS from '../../values/Colors';

import logoBrainway from '../../res/images/brainway_logo_white.png';
import logoBrainway2x from '../../res/images/brainway_logo_white@2x.png';
import logoBrainway3x from '../../res/images/brainway_logo_white@3x.png';
import logoFacebook from '../../res/images/facebook_logo.png';
import logoFacebook2x from '../../res/images/facebook_logo@2x.png';
import logoFacebook3x from '../../res/images/facebook_logo@3x.png';
import logoInstagram from '../../res/images/instagram_logo_white.png';
import logoInstagram2x from '../../res/images/instagram_logo_white@2x.png';
import logoInstagram3x from '../../res/images/instagram_logo_white@3x.png';
import { Link, useLocation } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  background: ${COLORS.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px 60px 0px;
`;

const TopContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px 0px 30px 0px;
  @media (max-width: 415px) {
    flex-direction: column;
    padding: 20px 0px 20px 0px;
  }
`;

const BottomContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Logo = styled.img`
  height: 70px;
  object-fit: contain;
  @media (max-width: 415px) {
    height: 50px;
  }
`;

const SnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  @media (max-width: 415px) {
    flex: 1;
    align-self: flex-start;
    margin-top: 10px;
  }
`;

const Line = styled.div`
  width: 80%;
  height: 1px;
  background: #ffffff;
`;

const FooterText = styled.span`
  color: white;
  font-size: 16px;
  text-align: left;
  align-self: flex-start;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};

  @media (max-width: 415px) {
    font-size: ${(props) => (props.mobileFontSize ? props.mobileFontSize : '12px')};
    padding: 0px;
  }
`;

const FooterButton = styled.button`
  flex: 1;
  cursor: pointer;
  color: white;
  background-color: #16569d;
  padding: 0px;
  margin-right: 10px;
  border-width: 0px;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`;

const Footer = (props) => {
  const location = useLocation();
  const pathname = location.pathname;

  if(pathname.includes('mybrainway') || pathname.includes('admin')){
    return <div></div>
  }

  return <Container>
    <TopContainer>
      <LogoContainer>
        <Logo src={logoBrainway} srcSet={`${logoBrainway2x} 2x, ${logoBrainway3x} 3x`} />
      </LogoContainer>
      <SnsContainer>
        <SnsButton
          src={logoFacebook}
          srcSet={`${logoFacebook2x} 2x, ${logoFacebook3x} 3x`}
          backgroundColor="white"
          onClick={() => {
            window.open('https://facebook.com/brainway.kr');
          }}
        />
        <SnsButton
          src={logoInstagram}
          srcSet={`${logoInstagram2x} 2x, ${logoInstagram3x} 3x`}
          backgroundColor="transparent"
          onClick={() => {
            window.open('https://www.instagram.com/_brain_way_/');
           }}
        />
      </SnsContainer>
    </TopContainer>
    <Line />
    <BottomContainer>
      <FooterText margin="30px 0px 10px 0px" fontSize="20px" mobileFontSize="16px">
        Brainway
      </FooterText>
      <FooterText margin="5px 0px 10px 0px">사업자 번호 / 205-52-00394</FooterText>
      <FooterText margin="5px 0px 30px 0px">
        주소 / 제주특별자치도 제주시 승천로57, 3층 브레인웨이
      </FooterText>

      <div>
        <Link to={'/privacy_policy'}>
          <FooterButton>개인정보처리방침</FooterButton>
        </Link>

        <Link to={'/terms'}>
          <FooterButton>이용약관</FooterButton>
        </Link>
      </div>

      <FooterText style={{ letterSpacing: '3px', margin: '20px 0px 20px 0px', fontSize: '12px' }}>
        COPYRIGHT 2020 BRAINWAY. CO., LTD. ALL RIGHTS RESERVED.
      </FooterText>
    </BottomContainer>
  </Container>
};

export default Footer;
