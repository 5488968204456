import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 30px;
  margin: 5px;
`;


const Title = styled.span`
  color: black;
  margin: 10px;
  text-align: left;
  color: black;
  font-family: "GodoB";
  font-size: 24px;
  line-height: 36px;
`;

const Instruction = styled.span`
    color: black;
    margin: 10px 10px 20px 10px;
    font-size: 16px;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    line-height: 30px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(0,0,0,0.1);
  border-radius: 100px;
  margin: 10px;
  align-self: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const SurveySection = (props) => {
  return (
    <Container>
      <Title>{props.section.title}</Title>
      {props.section.instruction && <Instruction>{props.section.instruction}</Instruction>}
      <Line/>
      {props.children}
    </Container>
  );
};

export default SurveySection;
