export const Healing = {
    surveyId: "D",
    title: "힐링의 길",
    instruction: "",
    tasks: [
        {
            taskId: "D_0",
            title: "기본 면담지",
            instruction: '문항을 읽고 자녀(나)와 유사한 정도를 항목에 표시해 주세요.',
            sections: [
                {
                    sectionId: "D_0_sub01",
                    title: "주호소영역 1",
                    questions: [
                        {
                            questionId: "D_0_001",
                            question: "귀하께서 요즘 어떤 부분에 대해 스트레스를 느끼시나요?",
                            instruction: "해당항목 네모 칸에 체크 (복수응답 가능)",
                            options: {
                                texts: [
                                    '부정적 정서(우울, 불안, 스트레스)',
                                    '자녀와의 관계',
                                    '부부 관계',
                                    '신체증상 및 수면(건강)',
                                    '기타'
                                ],
                                type: "multiple_selection"
                            }
                        },
                    ]
                },
                {
                    sectionId: "D_0_sub01_a",
                    title: "주호소영역 1-1",
                    questions: [
                        {
                            questionId: "D_0_002",
                            question: "걱정되거나 염려되는 부분이 평균적으로 얼마나 지속되었나요?",
                            options: {
                                value: [0, 1, 2],
                                texts: [
                                    '1-3주 정도',
                                    '1-3개월 정도',
                                    '4개월 이상'
                                ],
                                type: "multiple_choice"
                            }
                        },
                    ]
                },
                {
                    sectionId: "D_0_sub01_b",
                    title: "주호소영역 1-2",
                    questions: [
                        {
                            questionId: "D_0_003",
                            question: '이러한 부분이 귀하에게 미치는 부정적인 영향은 어느 정도인가요?',
                            options: {
                                images: [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                ],
                                texts: [
                                    "10%",
                                    "30%",
                                    "50%",
                                    "70%",
                                    "100%"
                                ],
                                additional: [
                                    "약간 신경이 쓰인다.",
                                    "",
                                    "대체로 신경 쓰인다.",
                                    "",
                                    "매우 많이 신경 쓰인다.\n(잠을 잘 못잔다, 친구관계가 안좋아 졌다, 가족관계가 안좋아졌다, 식욕이 줄었다, 학교생활을 힘들어 한다 등)"
                                ],
                            }
                        },
                    ]
                },
                {
                    sectionId: "D_0_sub01_c",
                    title: "주호소영역 1-3",
                    questions: [
                        {
                            questionId: "D_0_004",
                            question: '검사를 신청하게 된 이유는 무엇인가요?',
                            options: {
                                type: 'short_answer'
                            }
                        },
                    ]
                },
                {
                    sectionId: "D_0_sub02",
                    title: "나에 대해 알아보기",
                    instruction: "아래에 적혀있는 문장을 보면 빈칸이 있습니다. 각 문장을 읽으면서 제일 먼저 떠오르는 생각을 빈칸에 적어주세요. 시간제한은 없으나 될 수 있는 대로 빨리 작성해 주세요. 만약 문장을 완성할 수 없으면 표시를 해두었다가 나중에 완성하도록 해주세요.",
                    questions:[
                        {
                            questionId: "D_0_005",
                            options: {
                                prefix: "나는",
                                suffix: "할 때 화가 난다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "D_0_006",
                            options: {
                                prefix: "나는",
                                suffix: "에 대해서 걱정을 한다.",
                                type: "fill_in_blank",
                            }
                    
                        },
                        {
                            questionId: "D_0_007",
                            options: {
                                prefix: "나의 좋은 점은",
                                suffix: "이다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "D_0_008",
                            options: {
                                prefix: "내가 생각하기에는 나는",
                                suffix: "점들을 좀 고쳐야 할 것 같다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "D_0_009",
                            options: {
                                prefix: "결혼 생활에 대해서는 나는",
                                suffix: "하다고 생각한다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "D_0_010",
                            options: {
                                prefix: "내가 정말 행복하려면",
                                suffix: "해야 한다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "D_0_011",
                            options: {
                                prefix: "나를 괴롭히는 것은",
                                suffix: "이다.",
                                type: "fill_in_blank",
                            }
                        },
                        {
                            questionId: "D_0_012",
                            options: {
                                prefix: "내가 가장 좋아하는 사람은",
                                suffix: "이다.",
                                type: "fill_in_blank_with_reason",
                            }
                        },
                        {
                            questionId: "D_0_013",
                            options: {
                                prefix: "내가 가장 싫어하는 사람은",
                                suffix: "이다.",
                                type: "fill_in_blank_with_reason",
                            }
                        },
                        {
                            questionId: "D_0_014",
                            options: {
                                prefix: "나는",
                                suffix: "한 부모라고 생각한다.",
                                type: "fill_in_blank",
                            }
                        },
                    ]
                },
            ]
        },
        {
            taskId: "D_1",
            title: "가족 지지력",
            instruction: '다음 문항들은 평소 가족들과의 생활에서 느끼고 있는 점들에 관한 것입니다. 가장 가깝다고 생각되어지는 곳에 표시해 주시기 바랍니다.',
            sections: [
                {
                    sectionId: "D_1_sub01",
                    title: "적응력",
                    instruction: "",
                    questions: [
                        {
                            questionId: "D_1_001",
                            question: "우리 가족은 서로 도움을 청한다",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_003",
                            question: "우리 가족은 각자의 친구들에 대해 인정해 준다",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_005",
                            question: "우리 가족은 우리 가족끼리만 일(여행,외식, 집안문제결정 등)을 한다. ",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_007",
                            question: "우리 가족은 가족 이외의 다른 사람보다 우리 가족 구성원에게 친근감을 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_009",
                            question: "우리 가족은 여가 시간을 가족구성원들과 함께 보내는 것을 좋아한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_011",
                            question: "우리 가족은 서로 매우 친근감을 느낀다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_013",
                            question: "우리 가족은 함께 할 활동(명절, 제사, 생일)이 있을 때 모두 참여한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_015",
                            question: "우리 가족은 가족 구성원이 함께 할 수 있는 일(취미, 오락)을 쉽게 생각해 낸다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_017",
                            question: "우리 가족은 각자의 일을 결정할 때 집안 식구들과 상의한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_019",
                            question: "우리집에서는 가족이 함께 지낸다는 것이 매우 중요하다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "D_1_sub02",
                    title: "결속력",
                    instruction: "",
                    questions: [
                        {
                            questionId: "D_1_002",
                            question: "우리 가족은 문제를 해결할 때 자녀의 의견을 존중한다",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_004",
                            question: "우리 가족의 자녀들은 집안에서 지켜야 할 규율에 대해 의견을 말할 수 있다",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_006",
                            question: "우리 가족은 상황에 따라 식구 모두가 가장(지도자)의 역할을 할 수 있다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_008",
                            question: "우리 가족은 문제를 해결하고자 할 때 여러 가지 방법을 함께 생각해 본다. ",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_010",
                            question: "우리집에서 잘못한 일이 생겼을 때 부모와 자녀가 함께 모여 벌칙에 대해 토론한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_012",
                            question: "우리 가족은 자녀들도 의사 결정을 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_014",
                            question: "우리 집에서는 정해 놓은 규칙이 변하기도 한다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_016",
                            question: "우리 가족은 집안일에 대해 교대로 책임을 맡는다. ",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_018",
                            question: "우리 가족 중에서는 누가 가장인지 분간하기 어렵다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_1_020",
                            question: "우리집에서는 누가 집안 일을 하는지 알기 어렵다.",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '조금 그렇지 않다', '조금 그렇다', '약간 그렇다', '아주 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
            ]
        },
        {
            taskId: "D_2",
            title: "삶의 질",
            instruction: '문항을 읽고 귀하께서 평소 일상생활에서 느끼는 만족도에 가깝다고 생각되는 곳에 표시해 주시기 바랍니다.',
            sections: [
                {
                    sectionId: "D_2_sub01",
                    title: "일반",
                    instruction: "",
                    questions: [
                        {
                            questionId: "D_2_001",
                            question: "당신은 당신의 삶의 질을 어떻게 평가하겠습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_2_002",
                            question: "당신은 당신의 건강상태에 대해 얼마나 만족하고 있습니까?",
                            options: {
                                values: [1, 2, 3, 4, 5],
                                texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ],
                },
                {
                    sectionId: "D_2_sub02",
                    title: "건강",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "D_2_sub02_a",
                            title: "고통(통증)/불편",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_003",
                                    question: "당신은(신체적) 통증으로 인해 당신이 해야 할 일들을 어느 정도 방해 받는다고 느낍니까?",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub02_b",
                            title: "약물(치료) 의존성",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_004",
                                    question: "당신은 일상생활을 잘 하기 위해 얼마나 치료가 필요합니까?",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub02_c",
                            title: "에너지/피로",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_010",
                                    question: "당신은 일상생활을 위한 에너지를 충분히 가지고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub02_d",
                            title: "이동(Mobility)",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_015",
                                    question: "당신은 얼마나 잘 돌아다닐 수 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub02_e",
                            title: "수면/휴식",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_016",
                                    question: "당신은 당신의 수면(잘 자는 것)에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub02_f",
                            title: "운동성/활동성 - 거동능력",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_017",
                                    question: "당신은 일상생활의 활동을 수행하는 당신의 능력에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub02_g",
                            title: "작업능력/업무능력",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_018",
                                    question: "당신은 당신의 일할 수 있는 능력에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                    ],
                },
                {
                    sectionId: "D_2_sub03",
                    title: "사회적관계",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "D_2_sub03_a",
                            title: "대인관계",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_020",
                                    question: "당신은 당신의 개인적인 대인관계에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub03_b",
                            title: "성생활",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_021",
                                    question: "당신은 당신의 성생활에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub03_c",
                            title: "사회적 지지(지원)",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_022",
                                    question: "당신은 당신의 친구로부터 받고 있는 도움에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                    ],
                },
                {
                    sectionId: "D_2_sub04",
                    title: "심리",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "D_2_sub04_a",
                            title: "긍정적인 느낌",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_005",
                                    question: "당신은 인생을 얼마나 즐기십니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub04_b",
                            title: "종교/영성/개인적 신념",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_006",
                                    question: "당신은 당신의 삶이 어느 정도 의미 있다고 느낍니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub04_c",
                            title: "사고, 학습, 기억, 집중력",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_007",
                                    question: "당신은 얼마나 잘 정신을 집중할 수 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub04_d",
                            title: "신체상 및 외모",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_011",
                                    question: "당신의 신체적 외모에 만족합니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub04_e",
                            title: "자기 효능감",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_019",
                                    question: "당신은 당신 스스로에게 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub04_f",
                            title: "부정적인 느낌",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_026",
                                    question: "당신은 침울한 기분, 절망, 불안, 우울감과 같은 부정적인 감정을 얼마나 자주 느낍니까?",
                                    options: {
                                        values: [5, 4, 3, 2, 1],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                    ],
                },
                {
                    sectionId: "D_2_sub05",
                    title: "환경",
                    instruction: "",
                    subSections: [
                        {
                            subSectionId: "D_2_sub05_a",
                            title: "신체적 편안함과 안전",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_008",
                                    question: "당신은 일상생활에서 얼마나 안전하다고 느낍니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub05_b",
                            title: "물리적 환경",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_009",
                                    question: "당신은 얼마나 건강에 좋은 주거 환경에 살고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub05_c",
                            title: "자산",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_012",
                                    question: "당신은 당신의 필요를 만족시킬 수 있는 충분한 돈을 가지고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub05_d",
                            title: "",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_013",
                                    question: "당신은 매일 매일의 삶에서 당신이 필요로 하는 정보를 얼마나 쉽게 구할 수 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub05_e",
                            title: "정보/기술(Skill)를 습득할 수 있는 환경",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_014",
                                    question: "당신은 제거(여가)활동을 위한 기회를 어느 정도 가지고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub05_f",
                            title: "주거환경",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_023",
                                    question: "당신은 당신이 살고 있는 장소의 상태에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub05_g",
                            title: "보건/복지",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_024",
                                    question: "당신은 의료서비스를 쉽게 받을 수 있다는 점에 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                        {
                            subSectionId: "D_2_sub05_h",
                            title: "공공교통수단",
                            instruction: "",
                            questions: [
                                {
                                    questionId: "D_2_025",
                                    question: "당신은 당신이 사용하는 교통수단에 대해 얼마나 만족하고 있습니까?",
                                    options: {
                                        values: [1, 2, 3, 4, 5],
                                        texts: ['전혀 그렇지 않다', '거의 그렇지 않다', '그렇다', '거의 그렇다', '매우 그렇다'],
                                        type: "multiple_choice"
                                    }
                                },
                            ]
                        },
                    ],
                },
            ]
        },
        {
            taskId: "D_3",
            title: "우울 지수",
            instruction: '문항을 읽고 귀하께서 평소 일상생활에서 느끼는 감정, 행동에 가깝다고 생각되는 곳에 표시해주세요.',
            sections: [
                {
                    sectionId: "D_3_sub01",
                    title: "우울 지수",
                    instruction: "",
                    questions: [
                        {
                            questionId: "D_3_001",
                            question: "나는 의욕이 없고 우울하거나 슬프다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_002",
                            question: "나는 하루중 아침에 가장 기분이 좋다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_003",
                            question: "나는 갑자기 울고 싶다거나 자주 눈물이 난다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_004",
                            question: "나는 밤에 잠을 설치거나 아침에 일찍 깬다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_005",
                            question: "나는 전과 다름없이 식욕이 있다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_006",
                            question: "나는 이성과 이야기 하고, 함께 있기를 좋아 한다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_007",
                            question: "나는 체중이 준 것 같다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_008",
                            question: "나는 변비가 있다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_009",
                            question: "나는 심장이 두근거린다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_010",
                            question: "나는 별 이유없이 몸이 나른하고 피곤하다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_011",
                            question: "내 머리는 한결같이 맑다",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_012",
                            question: "나는 어떤 일이던지 전처럼 쉽게 처리한다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_013",
                            question: "나는 안절부절해서 진정할 수가 없다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_014",
                            question: "나의 장래는 희망적이라고 생각한다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_015",
                            question: "나는 전보다 더 신경질(짜증)이 난다",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_016",
                            question: "나는 매사에 결단력이 있다고 생각한다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_017",
                            question: "나는 사회에 유익하고 필요한 사람이라고 생각한다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_018",
                            question: "나는 삶의 의의를 느낀다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_019",
                            question: "내가 죽어야 남들이 편할것 같다.",
                            options: {
                                values: [1, 2, 3, 4],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                        {
                            questionId: "D_3_020",
                            question: "나는 전과 다름없이 만족하게 일한다.",
                            options: {
                                values: [4, 3, 2, 1],
                                texts: ['아니다', '자주 그렇다', '때로 그렇다', '항상 그렇다'],
                                type: "multiple_choice"
                            }
                        },
                    ]
                }
            ]
        },
    ]
}