import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  
  @media (max-width: 768px) {
    padding: 0px 10px;
  }

  @media (max-width: 415px){
    padding: 0px 0px;
  }
`;

const Clip = styled.img`
  width: 300px;
  position: absolute;
  
  @media (max-width: 768px) {
    width: 150px;
  }

  @media (max-width: 415px){
    display: none;
  }
`;

const Board = styled.div`
  width: 100%;
  height: 100%;
  margin: 70px;
  background-color: #b28661;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 30px;
  
  @media (max-width: 768px) {
    margin: 35px;
    padding: 20px;
  }

  @media (max-width: 415px){
    margin: 0px;
    border-radius: 0px;
    background-color: white;
    padding: 14px;
  }
`;

const Paper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 40px;
  
  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 30px;
  }
`;

const Clipboard = (props) => (
  <Container>
    <Clip src={require('../../res/images/clip.png')} />
    <Board {...props}>
      <Paper>{props.children}</Paper>
    </Board>
  </Container>
);

export default Clipboard;
