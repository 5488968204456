const MAIN = {
  section1: {
    title: "우리 'I'의 뇌인지발달과\n심리성장 컨설팅의 길 Brainway",
    subtitle:
      '우리 자녀의 뇌인지발달 성향과 적성을 파악하여, 심리적 발달과 균형을 이루는 개개인 맞춤형의 전문적, 과학적인 브레인 코칭과 진로적성 컨설팅을 제시해 드립니다. 브레인웨이의 전문가 그룹과 함께 우리 자녀에게 가장 적합하고 행복한 미래의 길, 그리고 부모의 행복한 길도 함께 찾아주세요.'
  },
  section2: {
    title:
      '브레인웨이는 우리 ‘I’인 자녀의 뇌인지발달 및 심리성향에 대해 다양한 차원을 통해 종합적으로 진단합니다.',
    subtitle:
      '성장의 숲과 함께하는 자기탐색의 시간, 브레인웨이의 ‘성장의 숲’에서는 우리 ‘I’ 성장을 위해 신경심리, 뇌 과학 전문가들이  함께 합니다.',
    paths: [
      {
        image: 'img_cognition',
        name: '인지의 길',
        desc:
          '신경 심리 분석을 바탕으로, 개인의 인지적 특성 파악, 강점 및 약점을 기반으로 한 적성을 탐색합니다.'
      },
      {
        image: 'img_learning',
        name: '학습의 길',
        desc:
          '자녀의 학업 준비도와 성향을 탐색하고 개인에 맞는 올바른 학습 습관과 환경을 제안 드립니다.'
      },
      {
        image: 'img_communication',
        name: '소통의 길',
        desc:
          '부모와 자녀의 성향, 그리고 관계적인 특성을 파악하여 건강한 부모-자녀 관계를 위한 효율적인 맞춤형 소통법을 제안 드립니다.'
      },
      {
        image: 'img_healing',
        name: '힐링의 길',
        desc:
          '개인과 가족의 정신건강에 대한 종합적인 분석 및 제안을 통해 ‘나’와 ‘우리’를 더 알아가는 시간을 제공합니다.'
      },
      {
        image: 'img_neuroscience',
        name: '뉴로사이언스 랩',
        desc:
          '다양한 뇌과학적 방법을 통해 인지기능별 뇌활성화/뇌연결망에 대한 분석을 진행하고, 뇌인지발달 특성과 성향에 대해 파악합니다. '
      }
    ]
  },
  section3: {
    title: '당신 (우리 ‘I’) 만을 위한 브레인웨이의 ‘개인 맞춤’ 보고서',
    subtitle:
      '성장의 숲 검사 결과는 객관적 평가 지표에 의한 결과를 기반으로, 개개인에 대한 임상신경심리전문가의 분석과 해석에 의한 종합적인 결과로 제시해드립니다. 브레인웨이 결과보고서는 평가 및 측정 결과에 기반하여 개개인의 특성에 대한 가장 전문적이고 과학적이면서도 동시에 가장 고유하고 특별한 개인 맞춤형 결과를 제공해 드릴 것입니다. ',
    samples: [
      'img_sample_report_1',
      'img_sample_report_2',
      'img_sample_report_3',
      'img_sample_report_4'
    ]
  },
  section4: {
    title: '브레인웨이는 뇌 과학과 임상심리 분야 최고의 전문가들과 함께 하고 있습니다.',
    subtitle:
      '임상심리, 신경심리, 뇌과학을 기반으로 한 임상 및 연구 경험이 풍부한 전문 연구진들이 검증한 전문적인 분석결과를 제시합니다. 그 동안 잘 모르고 있었던 우리 자녀의 뇌인지성향을 파악하고 학습과 진로적성을 찾는 최선의 전문적인 컨설팅을 제공합니다.',
    logos: [
      'logo_yonsei',
      'logo_gangnam_severance',
      'logo_aju',
      'logo_slam_lab',
      'logo_obelab',
      'logo_imedisync',
      'logo_readerseye'
    ]
  },
  section5: {
    title: '브레인웨이 센터에 직접 방문하시면\n더 많은 과학적인 서비스를 경험하실 수 있습니다.',
    subtitle: 'Coming Soon'
  },
  section6: {
    title: '최근 활동'
  }
};

export default MAIN;
