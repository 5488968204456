import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB445brvHmfGVV_PSrr0KaTD9_-KRuw6tw',
  authDomain: 'brainway-website.firebaseapp.com',
  databaseURL: 'https://brainway-website.firebaseio.com',
  projectId: 'brainway-website',
  storageBucket: 'brainway-website.appspot.com',
  messagingSenderId: '129515194857',
  appId: '1:129515194857:web:e34bb46c61cd7eaa6e6378',
  measurementId: 'G-GJ3JVM6D88'
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();


export default firebase