import React from 'react';
import styled from 'styled-components';

const Container = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid black;
  flex-direction: row;
`;

const Text = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  size: 12px;
  color: black;
  text-align: center;
  align-items: center;
  margin: 10px;
  justify-content: center;
`;

const UserInputItem = (props) => {
  return (
    <Container>
      <Text>{props.index}</Text>
      <Text>{props.section}</Text>
      <Text>{props.input.category}</Text>
      <Text>{props.input.question}</Text>
      <Text>{props.input.answer}</Text>
      <Text>{props.input.choice}</Text>
    </Container>
  );
};

export default UserInputItem;
