import React, { Component, useRef } from 'react';
import styled from 'styled-components';
import SurveySection from '../../components/layouts/SurveySection';
import MainButton from '../../components/buttons/MainButton';
import { Cognition, Healing, Communication, Learning } from '../../values/Questions';
import { save, submit } from '../../redux/actions/TrialActions';
import { connect } from 'react-redux';
import * as PATHWAY from '../../values/Pathways';
import { getResult } from '../../utils/ResultUtils';
import TitleContainer from '../../components/layouts/TitleContainer';
import { Redirect, Prompt } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css";
import COLORS from '../../values/Colors';


const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (max-width: 768px) {
    background-color: lightgrey;
    padding: 0px 0px 10px 0px;
  }
  
  @media (max-width: 415px) {
    background-color: lightgrey;
    padding: 0px 0px 10px 0px;
  }
`;

const AbsoluteContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 40px;
    right: 40px;
`;

const ProgressContainer = styled.div`
  width: 100px;
  height: 100px;
  background: white;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.3);
  padding: 5px;
  border-radius: 100%;
`;

const ProgressChildContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
`;

const ProgressText = styled.span`
  font-size: 24px;
  font-family: 'GodoB';
  color: ${COLORS.primary};
`;

const ProgressOutOfText = styled.span`
  font-size: 12px;
  font-family: 'Nanum Gothic', sans-serif;
  color: grey;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;

  @media (max-width: 768px){
    display: none;
  }

  @media (max-width: 415px){
    display: none
  }
`;

const Title = styled.text`
  font-size: 40px;
  text-align: center;
  color: white;
  font-family: 'GodoB';
  line-height: 55px;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px){
    font-size: 30px;
    line-height: 60px;
  }

  @media (max-width: 415px){
    font-size: 24px;
    line-height: 40px;
  }

`;

const SubTitle = styled.text`
  max-width: 550px;
  font-size: 18px;
  text-align: center;
  color: white;
  line-height: 32px;
  font-family: 'Nanum Gothic', sans-serif;

  @media (max-width: 1000px){

  }

  @media (max-width: 768px) {
    max-width: 500px;
    font-size: 15px;
    line-height: 28px;
    text-align: left;
  }
  @media (max-width: 415px) {
    max-width: 80%;
    font-size: 14px;
    line-height: 28px;
    text-align: left;
  }
`;

class Trial extends Component {
  state = {
    form: {
      trial: this.props.match.params.id,
      userInputs: {}
    },
    numOfAnswers: 0
  };
  submitButton = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.survey) {
      let numOfQuestions = 0;
      this.props.survey.sections.map(section => {
        return numOfQuestions += section.questions.length;
      })
      this.setState({ numOfQuestions })
    }
  }

  handleSelected = (section, uid, userInput) => {

    let sum = 1;
    if (this.state.form.userInputs[section]) {
      if (this.state.form.userInputs[section][uid]) {
        sum = 0;
      }
    }

    this.setState({
      form: {
        ...this.state.form,
        userInputs: {
          ...this.state.form.userInputs,
          [section]: {
            ...this.state.form.userInputs[section],
            [uid]: userInput
          }
        }
      },
      numOfAnswers: this.state.numOfAnswers + sum
    });
  };

  handleSubmit = () => {
    const { form } = this.state;
    const { survey } = this.props;

    const userInputs = form.userInputs;
    let stats = {};
    Object.keys(userInputs).forEach((section) => {
      Object.keys(userInputs[section]).forEach((uid) => {
        const selected = userInputs[section][uid];
        stats = {
          ...stats,
          [section]: !stats[section]
            ? {
              [selected.category]: {
                totalScore: selected.choice,
                questions: [uid],
                maxScore: selected.maxScore
              }
            }
            : {
              ...stats[section],
              [selected.category]: !stats[section][selected.category]
                ? {
                  totalScore: selected.choice,
                  questions: [uid],
                  maxScore: selected.maxScore
                }
                : {
                  ...stats[section][selected.category],
                  totalScore: stats[section][selected.category].totalScore + selected.choice,
                  questions: stats[section][selected.category].questions.concat(uid),
                  maxScore: stats[section][selected.category].maxScore + selected.maxScore
                }
            }
        };
      });
    });

    const result = getResult(
      {
        trial: form.trial,
        title: survey.title
      },
      stats
    );

    const trial = {
      ...form,
      stats: { ...stats },
      report: result
    };

    this.props.submit(trial);
  };

  render() {
    console.log(this.state)
    const { form, numOfAnswers, numOfQuestions } = this.state;
    const { survey, submissionId } = this.props

    if (submissionId) {
      return <Redirect to={`/result/${submissionId}`} />
    }

    return (
      <Container>
        <Prompt
          when={true}
          message={(location, action) => {
            if (!location.pathname.startsWith(`/trial/${survey.trial}`)) {
              return numOfAnswers < numOfQuestions
                ? `정말 ${survey.title} 체험을 그만 두실건가요?\n입력하신 내용은 모두 삭제되니 참고해주세요.`
                : `이제 제출만 하면 되는데!! 정말 체험을 그만 두실건가요?`
            }
            return true;
          }}
        />
        <TitleContainer
          bgImage={require('../../res/images/bg_faq.png')}
        >
          <Title>{survey.title}</Title>
          <SubTitle>{survey.instruction}</SubTitle>
        </TitleContainer>
        {survey.sections &&
          survey.sections.map((section, index) => (
            <SurveySection
              key={index}
              selected={form.userInputs && form.userInputs[section.title]}
              section={section}
              onSelected={this.handleSelected}
            />
          ))}
       <ButtonContainer>
          <MainButton
            ref={this.submitButton}
            disable={numOfAnswers < numOfQuestions}
            onClick={this.handleSubmit}
            text="제출하기" />
        </ButtonContainer>
        <AbsoluteContainer>
          <ProgressContainer>
            <CircularProgressbarWithChildren
              value={(100 * numOfAnswers) / numOfQuestions}
              styles={
                buildStyles({
                  pathColor: COLORS.primary
                })
              }
            >
              {(numOfAnswers < numOfQuestions)
                ? <ProgressChildContainer>
                  <ProgressText>{numOfAnswers}</ProgressText>
                  <ProgressOutOfText style={{ margin: '0px 0px 0px 2px' }}>/</ProgressOutOfText>
                  <ProgressOutOfText>{numOfQuestions}</ProgressOutOfText>
                </ProgressChildContainer>
                : <ProgressChildContainer style={{background: COLORS.primary}} onClick={this.handleSubmit}>
                  <ProgressText style={{fontSize: '22px', color: 'white'}}>제출</ProgressText>
                </ProgressChildContainer>
              }
            </CircularProgressbarWithChildren>
          </ProgressContainer>
        </AbsoluteContainer>
      </Container>
    );
  }
}

const getSurvey = (survey) => {
  switch (survey) {
    case PATHWAY.COGNITION:
      return Cognition;
    case PATHWAY.HEALING:
      return Healing;
    case PATHWAY.LEARNING:
      return Learning;
    case PATHWAY.COMMUNICATION:
      return Communication;
    default:
      return '';
  }
};

const mapStateToProps = (state, ownProps) => {
  const surveyName = ownProps.match.params.id
  return {
    survey: getSurvey(surveyName),
    submissionId: state.trial.submissionId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (trial) => dispatch(submit(trial))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trial);
