import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: white;
    justify-content: space-around;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    cursor: pointer;

    &:hover{
        background-color: grey
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex: ${props => props.flex ? props.flex :  1};
    flex-direction: row;
    padding: 12px 0px;
`;

const Title = styled.span`
    font-family: 'GodoB';
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;


const TableItem = (props) => (
    <Container>
        {props.children && props.children.map((child, index) => (
            <ColumnContainer>
                {child}
            </ColumnContainer>
        ))}
    </Container>
)

export default TableItem;