import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../values/Colors';
import { XYPlot, XAxis, YAxis, MarkSeries, LabelSeries, VerticalGridLines, HorizontalGridLines } from 'react-vis';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;
  @media (max-width: 415px){
    padding: 20px 10px 60px 10px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
`;

const Point = styled.span`
  max-width: 500px;
  font-size: 18px;
  font-family: 'Nanum Gothic', sans-serif;
  color: black;
  text-align: left;
  line-height: 30px;

  @media (max-width: 415px){
    line-height: 24px;
    font-size: 14px;
  }
`;


const getWidthHeight = (device) => {
  switch (device) {
    case "pc":
      return {
        width: 600,
        height: 400,
        sizeRange: [50, 80],
        fontSize: '14px'
      }
    case "tablet":
      return {
        width: 380,
        height: 300,
        sizeRange: [30, 60],
        fontSize: '12px'
      }
    case "phone":
      return {
        width: 300,
        height: 200,
        sizeRange: [10, 40],
        fontSize: '12px'
      }
    default:
      return {
        width: 0,
        height: 0
      }
  }
}

const radarColors = ['#8499D8', '#AE84D8', '#D884B0'];

const LabelSeriesResult = ({ result, device }) => {
  const dimension = getWidthHeight(device)
  return <Container>
    <XYPlot
      colorRange={radarColors}
      colorScale="category"
      yDomain={[0, 100]}
      xDomain={[0, 8]}
      width={dimension.width}
      height={dimension.height}
      margin={{
        top: 50,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <VerticalGridLines/>
      <HorizontalGridLines/>
      <XAxis
        attr="x"
        attrAxis="y"
        orientation="bottom"
        tickTotal={0}
        tickSize={0}
        tickSizeInner={0}
        tickSizeOuter={0}
        style={{
          line: { stroke: 'lightgray' }
        }}
      />
      <YAxis
        attr="y"
        attrAxis="x"
        orientation="left"
        style={{
          line: { stroke: 'lightgray' }
        }}
        tickTotal={0}
        tickSize={0}
        tickSizeInner={0}
        tickSizeOuter={0}
      />
      <MarkSeries
        animation
        opacity={0.5}
        sizeRange={dimension.sizeRange}
        data={result.data} />

      <LabelSeries
        animation
        allowOffsetToBeReversed
        data={result.data}
        labelAnchorX="middle"
        labelAnchorY="hanging"
        rotation={20}
        style={{fontSize: dimension.fontSize, fontWeight: 800}}
      />
      <VerticalGridLines />
      <HorizontalGridLines />
    </XYPlot>
    <RowContainer>
      <Point>{result.statements}</Point>
    </RowContainer>
  </Container>
};

export default LabelSeriesResult;
