import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import UserInputList from '../components/layouts/temp/UserInputList';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

class MyPage extends Component {
  csvData = [];

  state = {
    userInputs: {}
  };

  componentDidMount() {
    const userInputs = JSON.parse(this.props.location.trial).userInputs;
    Object.keys(userInputs)
      .sort()
      .forEach((section) => {
        Object.keys(userInputs[section])
          .sort()
          .forEach((uid) => {
            this.csvData.push({
              uid: uid,
              section: section,
              category: userInputs[section][uid].category,
              question: userInputs[section][uid].question,
              answer: userInputs[section][uid].answer,
              choice: userInputs[section][uid].choice
            });
          });
      });

    this.setState({ userInputs });
  }

  render() {
    return (
      <Container>
        {this.state.userInputs &&
          Object.keys(this.state.userInputs)
            .sort()
            .map((section) => {
              return <UserInputList section={section} list={this.state.userInputs[section]} />;
            })}

        <CSVLink
          style={{
            border: '1px solid blue',
            padding: '20px',
            marginTop: '30px'
          }}
          data={this.csvData}
          filename={`trial_${JSON.parse(this.props.location.trial).trial}_${moment().format(
            'YYYY-MM-DD'
          )}.csv`}
        >
          CSV 다운로드
        </CSVLink>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPage);
