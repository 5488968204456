import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

const Container = styled.div``;

class Submissions extends Component {
  render() {
    return <Container></Container>;
  }
}

const mapStateToProps = (state) => {
  return {
    trials: state.firestore.ordered.trials
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'trials', orderBy: ['submittedAt', 'desc'] }])
)(Submissions);
