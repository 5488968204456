export const createNotice = (notice) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('notices').add({
            ...notice,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_NOTICE', notice })
        }).catch(err => {
            dispatch({ type: 'CREATE_NOTICE_ERROR', err })
        })
    }
}

export const deleteNotice = (noticeId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.delete(
            {
                collection: 'notices', doc: noticeId
            }
        ).then(() => {
            dispatch({ type: 'DELETE_NOTICE', noticeId })
        }).catch(err => {
            dispatch({ type: 'DELETE_NOTICE_ERROR', err })
        })
    }
}