const initState = {

}

const NoticeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_NOTICE':
            console.log('created notice', action.notice);
            return state;
        case 'CREATE_NOTICE_ERROR':
            console.log('create project error', action.err);
            return state;
        case 'DELETE_NOTICE':
            console.log('delete notice');
            return state;
        case 'DELETE_NOTICE_ERROR':
            console.log('delete notice error', action.err);
            return state;
        default:
            return state;
    }
}

export default NoticeReducer